import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardActions, IconButton, Typography, Avatar, InputBase, Divider, List, ListItem, ListItemAvatar, ListItemText, Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplyIcon from '@mui/icons-material/Reply';
import CommentIcon from '@mui/icons-material/Comment';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Post = ({ posts, onLike, onShare, setPosts, onPostClick }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const [activeReply, setActiveReply] = useState(null);
  const [inputText, setInputText] = useState('');
  const [showComments, setShowComments] = useState({});
  const [showReplies, setShowReplies] = useState({}); // State to manage visibility of replies

  const toggleRepliesVisibility = (commentId) => {
    setShowReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle visibility for the specific comment
    }));
  };

  const loggedInUser = JSON.parse(localStorage.getItem('user'));

  // Function to delete a comment
  const handleDeleteComment = async (postId, commentId) => {
    try {
      await axios.delete(`https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      // Update the state to remove the deleted comment
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
              ...post,
              comments: post.comments.filter((comment) => comment._id !== commentId),
            }
            : post
        )
      );
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/users/${userId}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/posts/user/${userId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });

        // Log the posts data to ensure sorting
        console.log(response.data); // Check if they are sorted in descending order by date

        // Set `isLiked` based on whether `loggedInUser.id` is in `likes` array
        const updatedPosts = response.data.map((post) => ({
          ...post,
          isLiked: post.likes.includes(loggedInUser.id),
        }));
        setPosts(updatedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [userId]);

  const handleLike = async (postId) => {
    try {
      // Get userId from localStorage
      const userId = JSON.parse(localStorage.getItem('user')).id;

      const response = await axios.put(
        `https://thecameratales.com/api/admin/posts/${postId}/like`,
        { userId }, // Include userId in the request body
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        }
      );

      // Update posts with the response data
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? { ...response.data, isLiked: response.data.likes.includes(userId) }
            : post
        )
      );
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleShare = (postId) => {
    const shareLink = `https://thecameratales.com/sharedpost/${postId}`;

    if (navigator.share) {
      navigator.share({
        title: 'Check out this post on The Camera Tales!',
        text: 'Have a look at this post I found:',
        url: shareLink,
      })
        .catch((err) => console.error('Error sharing:', err));
    } else {
      navigator.clipboard.writeText(shareLink)
        .then(() => {
          alert('Post link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
        });
    }
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://thecameratales.com/api/admin/posts/${postId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleComment = async (postId) => {
    // Check if the user is logged in by verifying the token in local storage
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to comment on posts.');
      return;
    }

    try {
      // Get userId from localStorage
      const userId = JSON.parse(localStorage.getItem('user')).id;

      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comment`,
        { text: inputText, userId }, // Include userId in the request body
        {
          headers: {
            'x-auth-token': token,
          }
        }
      );

      // Update posts with the new comment data
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? response.data : post
        )
      );

      // Clear the comment input
      setInputText('');
    } catch (error) {
      console.error('Error commenting on post:', error);
    }
  };



  const handleReply = async (postId, commentId) => {
    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/reply`,
        { text: inputText },
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          }
        }
      );
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? response.data : post
        )
      );
      setInputText('');
      setActiveReply(null);
    } catch (error) {
      console.error('Error replying to comment:', error);
    }
  };

  const handleLikeComment = async (postId, commentId, liked) => {
    try {
      const url = `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/${liked ? 'unlike' : 'like'}`;
      const response = await axios.put(url, null, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? response.data : post
        )
      );
    } catch (error) {
      console.error('Error liking/unliking comment:', error);
    }
  };

  const toggleCommentsVisibility = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const cancelReply = () => {
    setActiveReply(null);
    setInputText('');
  };

  return (
    <Box sx={{ mt: 5, backgroundColor: "rgba(0,0,0,0.9)", minHeight: "100vh" }}>
      {posts.map((post, index) => (
        <Card key={post._id || index} sx={{ mb: 3, background: "linear-gradient(to right, #15171a, #15171a, #15171a)" }}>
          <CardContent onClick={() => onPostClick(post)}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                src={user?.profilePicture ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${user.profilePicture}` : ''}
                alt="User"
                sx={{ width: 30, height: 30, mr: 2 }}
              />
              <Typography variant="h6" sx={{ color: "#D3D3D3" }}>
                {user ? `${user.firstName} ${user.lastName}` : 'Unknown'}
              </Typography>
            </Box>
            {post.mediaType === 'image' ? (
              <img
                src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
                alt={post.caption}
                style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
              />
            ) : (
              <video
                src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
                controls
                controlsList="nodownload"
                style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
              />
            )}
            <Typography variant="body2" sx={{ mb: 1, color: "#D3D3D3" }}>{post.caption}</Typography>
            <Typography variant="body2" color="#D3D3D3">{post.likes?.length || 0} likes</Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton onClick={() => handleLike(post._id)} aria-label="like">
              <FavoriteIcon sx={{ color: post.likes?.includes(loggedInUser?.id) ? 'red' : 'white' }} />
            </IconButton>
            <IconButton onClick={() => handleShare(post._id)} aria-label="share">
              <ShareIcon sx={{ color: 'white !important' }} />
            </IconButton>
            {loggedInUser && loggedInUser.id === post.createdBy?._id && (
              <IconButton onClick={() => handleDelete(post._id)} aria-label="delete">
                <DeleteIcon sx={{ color: '#D3D3D3' }} />
              </IconButton>
            )}
            <IconButton onClick={() => toggleCommentsVisibility(post._id)} aria-label="comments">
              <CommentIcon sx={{ color: '#D3D3D3' }} />
              <Typography sx={{ color: '#D3D3D3', ml: 1 }}>{post.comments?.length || 0}</Typography>
            </IconButton>
          </CardActions>
          {showComments[post._id] && (
            <Box sx={{ px: 2, pb: 2 }}>
              <Divider />
              <List sx={{ maxHeight: 200, overflow: 'auto', paddingTop: 0 }}>
                {(post.comments || [])
                  .sort((a, b) => {
                    // Primary: Sort by date (oldest to newest)
                    const dateComparison = new Date(a.date) - new Date(b.date);
                    if (dateComparison !== 0) return dateComparison;

                    // Secondary: If dates are equal, prioritize comments by the logged-in user
                    if (a.user?._id === loggedInUser?.id) return -1;
                    if (b.user?._id === loggedInUser?.id) return 1;

                    // Tertiary: Fallback to `_id` for consistent sorting
                    return a._id.localeCompare(b._id);
                  })
                  .map((comment, index) => (
                    <React.Fragment key={index}>
                      {/* Comment Section */}
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          backgroundColor: '#2c2f33',
                          borderRadius: 2,
                          mb: 1,
                          p: 2,
                          position: 'relative',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar alt={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`} src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${comment.user?.profilePicture || ''}`} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`}
                          secondary={comment.text}
                          primaryTypographyProps={{ sx: { color: 'white' } }}
                          secondaryTypographyProps={{ sx: { color: '#A9A9A9' } }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton onClick={() => handleLikeComment(post._id, comment._id, comment.liked)}>
                            <ThumbUpIcon sx={{ color: comment.liked ? 'blue' : '#D3D3D3' }} />
                          </IconButton>
                          <Typography sx={{ ml: 1, color: '#A9A9A9' }}>
                            {comment.likes?.length || 0} likes
                          </Typography>
                          <IconButton onClick={() => setActiveReply(comment)} sx={{ ml: 2 }}>
                            <ReplyIcon sx={{ color: '#D3D3D3' }} />
                          </IconButton>
                          {loggedInUser && (loggedInUser.id === comment.user?._id || loggedInUser.id === post.createdBy?._id) && (
                            <IconButton onClick={() => handleDeleteComment(post._id, comment._id)} sx={{ ml: 2 }}>
                              <DeleteIcon sx={{ color: '#D3D3D3' }} />
                            </IconButton>
                          )}
                        </Box>
                      </ListItem>
                      <Box sx={{ ml: 0 }}>
                        {/* View Replies Button */}
                        {comment.replies?.length > 0 && (
                          <Button onClick={() => toggleRepliesVisibility(comment._id)} sx={{ textTransform: 'none', color: '#A9A9A9' }}>
                            {showReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
                          </Button>
                        )}

                        {/* Replies Section */}
                        {showReplies[comment._id] && (
                          <List sx={{ mt: 1 }}>
                            {
                              comment.replies && comment.replies.length > 0 && (
                                <Box sx={{ pl: 0, mt: 2, backgroundColor: '#1c1f22', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                  {comment.replies.map((reply, replyIndex) => (
                                    <ListItem
                                      key={replyIndex}
                                      alignItems="flex-start"
                                      sx={{
                                        backgroundColor: '#3b3f44',
                                        borderRadius: 2,
                                        mb: 1,
                                        p: 1,
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                      }}
                                    >
                                      <ListItemAvatar>
                                        <Avatar alt={`${reply.user?.firstName || 'Unknown'} ${reply.user?.lastName || ''}`} src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${reply.user?.profilePicture || ''}`} />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={`${reply.user?.firstName || 'Unknown'} ${reply.user?.lastName || ''}`}
                                        secondary={reply.text}
                                        primaryTypographyProps={{ sx: { color: 'white', fontSize: '0.9rem' } }}
                                        secondaryTypographyProps={{ sx: { color: '#A9A9A9', fontSize: '0.85rem' } }}
                                      />
                                    </ListItem>
                                  ))}
                                </Box>
                              )}
                          </List>
                        )}
                      </Box>
                    </React.Fragment>
                  ))}
              </List>
              <InputBase
                placeholder={activeReply ? `Replying to @${activeReply.user.firstName} ${activeReply.user.lastName}` : 'Add a comment'}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && inputText.trim()) {
                    if (activeReply) {
                      handleReply(post._id, activeReply._id);
                    } else {
                      handleComment(post._id);
                    }
                  }
                }}
                sx={{ width: '100%', mb: 1, bgcolor: '#f0f0f0', borderRadius: 1, pl: 1 }}
              />
              {activeReply && (
                <Button variant="outlined" color="secondary" onClick={cancelReply}>
                  Cancel Reply
                </Button>
              )}
            </Box>
          )}

        </Card>
      ))}
    </Box>
  );
};

export default Post;
