import axios from "axios";
import queryString from "query-string";

const baseURL = "https://thecameratales.com/api/";

const customClient = axios.create({
  baseURL,
});

const fetchData = async () => {
  try {
    const response = await customClient.get("/your-endpoint-here");
    // Handle response data
  } catch (error) {
    // Handle error
  }
};

const publicClient = axios.create({
  baseURL,
  paramsSerializer: {
    encode: params => queryString.stringify(params)
  }
});

publicClient.interceptors.request.use(async config => {
  return {
    ...config,
    headers: {
      "Content-Type": "application/json"
    }
  };
});

publicClient.interceptors.response.use((response) => {
  if (response && response.data) return response.data;
  return response;
}, (err) => {
  throw err.response.data;
});

export default publicClient;