// ForgotPassword.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1); // 1: Request OTP, 2: Verify OTP
    const [loading, setLoading] = useState(false); // To show loading state
    const navigate = useNavigate(); // Initialize navigate

    const requestOtp = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://thecameratales.com/api/users/send-forget-password-otp', { email });
            alert(response.data.message);
            setStep(2); // Move to OTP verification step
        } catch (error) {
            alert(error.response?.data?.message || 'Error sending OTP');
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://thecameratales.com/api/users/verify-reset-password-otp', {
                email,
                otp,
                newPassword,
            });
            alert(response.data.message);
            // Optionally redirect to login page or clear form
            navigate('/login');
        } catch (error) {
            alert(error.response?.data?.message || 'Error resetting password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <h2>{step === 1 ? 'Request OTP' : 'Verify OTP'}</h2>
            {step === 1 ? (
                <div style={styles.form}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        style={styles.input}
                        required
                    />
                    <button onClick={requestOtp} disabled={loading} style={styles.button}>
                        {loading ? 'Sending...' : 'Send OTP'}
                    </button>
                </div>
            ) : (
                <div style={styles.form}>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        style={styles.input}
                        required
                    />
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password"
                        style={styles.input}
                        required
                    />
                    <button onClick={resetPassword} disabled={loading} style={styles.button}>
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </div>
            )}
        </div>
    );
};

// Inline styles for the component
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
    },
    input: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ddd',
    },
    button: {
        padding: '10px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
};

export default ForgotPassword;
