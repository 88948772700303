import { Box, Typography, useMediaQuery } from "@mui/material";

const CompactCircularRate = ({ value }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px) and (max-height: 858px)");
  const circleSize = isSmallScreen ? 20 : 40; // Adjust size based on screen size
  const strokeWidth = 3; // Thin stroke
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value * 10 / 100) * circumference;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: circleSize,
        height: circleSize,
        backgroundColor: "#1c1c1c", // Subtle background color
        borderRadius: "50%",
      }}
    >
      <svg width={circleSize} height={circleSize}>
        <defs>
          <linearGradient id="compactGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#4CAF50" />
            <stop offset="100%" stopColor="#8BC34A" />
          </linearGradient>
        </defs>
        {/* Background Circle */}
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="none"
          stroke="#2d2d2d" // Darker background stroke
          strokeWidth={strokeWidth}
        />
        {/* Progress Circle */}
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="none"
          stroke="url(#compactGradient)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2})`}
        />
      </svg>
      {/* Centered Text */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontWeight="600"
          sx={{
            color: "#fff",
            fontSize: isSmallScreen ? "0.5rem" : "0.8rem", // Adjust font size
          }}
        >
          {Math.floor(value * 10) / 10}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompactCircularRate;
