import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Button, Typography, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tmdbConfigs from "../../api/configs/tmdb.configs";
import { routesGen } from "../../routes/routes";

const MediaItemTwo = ({ media, mediaType }) => {
  const [posterPath, setPosterPath] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const newPosterPath =
      media.poster_path ||
      media.backdrop_path ||
      media.mediaPoster ||
      media.profile_path;

    setPosterPath(tmdbConfigs.posterPath(newPosterPath));

    // Preload the image
    const preloadImage = new Image();
    preloadImage.src = tmdbConfigs.posterPath(newPosterPath);

    // Add preload link to the head of the document
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = preloadImage.src;
    link.as = "image";
    document.head.appendChild(link);

    preloadImage.onload = () => {
      // Image has been loaded, apply the imageLoaded state
      setImageLoaded(true);
    };

    return () => {
      // Cleanup by removing the preload link
      document.head.removeChild(link);
    };
  }, [media, mediaType]);

  return (
    <Link
      to={
        mediaType !== "people"
          ? routesGen.mediaDetail(mediaType, media.mediaId || media._id || media.id)
          : routesGen.person(media._id || media.id)
      }
      style={{ textDecoration: "none" }}
    >
      <Box
        sx={{
          position: "relative",
          borderRadius: "12px",
          overflow: "hidden",
          border: "0.1px solid white",
          boxShadow: 2,
          backgroundColor: "background.paper",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(1.03)",
            boxShadow: 4,
          },
        }}
      >
        {/* Poster image container */}
        {!imageLoaded && (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        )}
        <Box
          component="img"
          src={posterPath}
          alt={media.title || media.name}
          sx={{
            width: "100%",
            height: "auto",
            objectFit: "contain", // Ensures the image does not get cropped
            backgroundColor: "rgba(0,0,0,0.1)",
            opacity: imageLoaded ? 1 : 0, // Fade in image once loaded
            transition: "opacity 0.3s ease",
          }}
        />
      </Box>
    </Link>
  );
};

export default MediaItemTwo;
