import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SwipeableViews from 'react-swipeable-views';

const CustomPhotoCollage = ({ photos }) => {
  const [open, setOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  // Filter photos to skip videos
  const filteredPhotos = photos.filter(photo => !photo.source || !photo.source.includes('.mp4')); // Adjust the logic for other video types if necessary

  const handleClickOpen = (index) => {
    console.log(`Opening photo at index: ${index}`); // Log when a photo is clicked
    setSelectedPhotoIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    console.log(`Closing photo viewer, resetting index to 0`); // Log when closing the viewer
    setOpen(false);
    setSelectedPhotoIndex(0);
  };

  const handleNext = () => {
    console.log(`Next photo clicked, current index: ${selectedPhotoIndex}`); // Log when moving to the next photo
    setSelectedPhotoIndex((prevIndex) =>
      prevIndex === filteredPhotos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleBack = () => {
    console.log(`Back photo clicked, current index: ${selectedPhotoIndex}`); // Log when moving to the previous photo
    setSelectedPhotoIndex((prevIndex) =>
      prevIndex === 0 ? filteredPhotos.length - 1 : prevIndex - 1
    );
  };

  if (!filteredPhotos || filteredPhotos.length === 0) {
    console.log('No photos available'); // Log when no photos are available
    return null;
  }

  console.log('Rendering photo collage with photos:', filteredPhotos); // Log to check the photos being rendered

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' },
          gap: 1,
        }}
      >
        {filteredPhotos.slice(0, 1).map((photo, index) => (
          <Box
            key={index}
            sx={{
              gridColumn: 'span 4',
              height: { xs: '200px', sm: '150px' },
              overflow: 'hidden',
              cursor: 'pointer',
              '&:hover img': { transform: 'scale(1.1)' },
              transition: 'transform 0.3s ease',
            }}
            onClick={() => handleClickOpen(index)}
          >
            <img
              src={photo.source}
              alt={`photo-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        ))}
        {filteredPhotos.slice(1, 4).map((photo, index) => (
          <Box
            key={index}
            sx={{
              height: { xs: '120px', sm: '100px' },
              overflow: 'hidden',
              cursor: 'pointer',
              '&:hover img': { transform: 'scale(1.1)' },
              transition: 'transform 0.3s ease',
            }}
            onClick={() => handleClickOpen(index + 1)}
          >
            <img
              src={photo.source}
              alt={`photo-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        ))}
        {filteredPhotos.length > 5 && (
          <Box
            sx={{
              position: 'relative',
              height: '100px',
              overflow: 'hidden',
              cursor: 'pointer',
              '&:hover img': { transform: 'scale(1.1)' },
              transition: 'transform 0.3s ease',
            }}
            onClick={() => handleClickOpen(5)}
          >
            <img
              src={filteredPhotos[5].source}
              alt="more-photos"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              +{filteredPhotos.length - 5}
            </Box>
          </Box>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        sx={{
          overflow: 'hidden',
          borderRadius: 2,
          boxShadow: 5,
          backdropFilter: 'blur(5px)',
        }}
      >
        <DialogTitle sx={{ position: 'relative', padding: '10px 16px' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="back"
            sx={{ position: 'absolute', left: 0 }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ overflow: 'hidden', padding: 0 }}>
          <SwipeableViews
            index={selectedPhotoIndex}
            onChangeIndex={(index) => setSelectedPhotoIndex(index)}
            enableMouseEvents
            style={{ overflow: 'hidden' }}
          >
            {filteredPhotos.map((photo, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: {
                    xs: '40vh', // Reduced height for small screens
                    sm: '70vh', // Medium screens
                    md: '80vh', // Larger screens
                  },
                  backgroundColor: 'white',
                }}
              >
                <img
                  src={photo.source}
                  alt={`photo-${index}`}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            ))}
          </SwipeableViews>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomPhotoCollage;
