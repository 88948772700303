import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ProfilePage from './Components/ProfilePage';
import Post from './Component-4/Post';
import AdPage from './AdComponent/AdPage';
import { Box, Grid, Button, Modal, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import PostForm from './PostForm';
// import VideoUploadForm from './VideoUploadForm';
// import UserSizzlers from './userSizzlers';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import RelatedArtists from './AdComponent/RelatedArtists';
const localUserId = JSON.parse(localStorage.getItem('user'))?.id;


function MainPage() {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [selectedPost, setSelectedPost] = useState(null); // State to store selected post
  const { userId } = useParams();
  const navigate = useNavigate(); // Use navigate for routing
  const [uploadProgress, setUploadProgress] = useState(0); // Upload progress state

  const token = localStorage.getItem('token');
  axios.defaults.headers.common['x-auth-token'] = token;
  axios.defaults.baseURL = 'https://thecameratales.com/api';




  useEffect(() => {
    const fetchUserData = async () => {
      if (!localUserId || !token) {
        return;
      }
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/users/${userId}`, {
          headers: {
            'x-auth-token': token
          }
        });
        setUser(response.data);

        fetchUserPosts(userId);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchUserPosts = async (userId) => {
      if (!token) return;
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/posts/user/${userId}`, {
          headers: {
            'x-auth-token': token
          }
        });

        // Sort the posts by date in descending order (newest first)
        const sortedPosts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Set sorted posts
        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching user posts:', error);
      }
    };

    fetchUserData();
  }, [token, userId]);



  // const handleVideoUpload = (newVideo) => {
  //   setPosts([newVideo, ...posts]);
  // };

  const handleProfilePictureUpload = async () => {
    if (selectedFile && token) {
      const formData = new FormData();
      formData.append('profilePicture', selectedFile);
      try {
        const response = await axios.put('https://thecameratales.com/api/admin/superuser/profile-picture', formData, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          }
        });
        setUser({ ...user, profilePicture: response.data.profilePicture });
      } catch (error) {
        console.error('Error updating profile picture:', error);
      }
    } else {
      console.error('No file selected');
    }
  };

  const handleCreatePost = async (formData) => {
    try {
      const response = await axios.post('https://thecameratales.com/api/admin/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentage); // Update progress state
        },
      });

      setPosts([response.data, ...posts]); // Add the new post to the top
      setUploadProgress(0); // Reset progress bar after upload

      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      return response.data; // Indicate success
    } catch (error) {
      console.error('Error creating post:', error);
      throw error; // Pass error back to handleSubmit for feedback
    }
  };


  const handleLike = async (postId) => {
    if (!token) return;
    try {
      const response = await axios.put(`https://thecameratales.com/api/admin/posts/${postId}/like`, null, {
        headers: {
          'x-auth-token': token
        }
      });
      setPosts(posts.map(post => post._id === postId ? response.data : post));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleComment = async (postId, text) => {
    if (!token) return;
    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comment`,
        { text },
        {
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        }
      );

      // Update the specific post with the new comment
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? response.data : post
        )
      );
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleShare = async (postId) => {
    if (!token) return;
    try {
      const response = await axios.put(`https://thecameratales.com/api/admin/posts/${postId}/share`, null, {
        headers: {
          'x-auth-token': token
        }
      });
      setPosts(posts.map(post => post._id === postId ? response.data : post));
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  const loggedInUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  // Handler for navigating to the analytics page
  const handleNavigateToAnalytics = () => {
    navigate(`/analytics/${userId}`);
  };

  const handleNavigateToWebsite = () => {
    navigate('/cmshome'); // Redirect to /cmshome using React Router's navigate function
  };

  const handleNavigateToSettings = () => {
    navigate('/profilesettings'); // Redirect to /cmshome using React Router's navigate function
  };


  const openPostModal = (post) => {
    setSelectedPost(post);
    setOpenModal(true);
  };

  const closePostModal = () => {
    setOpenModal(false);
    setSelectedPost(null);
  };

  // Render the login button if localUserId or token is missing in localStorage
  if (!localUserId || !token) {
    return (
      <div style={styles.centeredContainer}>
        <p style={{ color: 'white' }}>Please log in to view the profile.</p>
        <button onClick={() => navigate('/login')} style={styles.loginButton}>
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <Box
      sx={{
        marginTop: '7.5vh',
        backgroundColor: 'rgba(0,0,0,0.3)',
        '@media (max-width: 600px)': {
          marginTop: '9vh',
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ProfilePage
            loggedInUser={loggedInUser}
            onUpdateProfilePicture={handleProfilePictureUpload}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ maxHeight: '100vh', overflowY: 'auto', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 2, boxShadow: 2, overflowY: "auto", WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}>
            <PostForm
              loggedInUser={loggedInUser}
              user={user}
              onCreatePost={handleCreatePost}
              uploadProgress={uploadProgress}
            />
            {/* <VideoUploadForm
              onVideoUpload={handleVideoUpload}
              user={user}
              loggedInUser={loggedInUser}
            /> */}
            <div className="post-container" style={{ maxHeight: "100vh", backgroundColor: "rgba(0,0,0,0.9)" }}>
              {/* <div style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "black" }}>
                <UserSizzlers userId={userId} />
              </div> */}
              <Post
                posts={posts}
                onLike={handleLike}
                onComment={handleComment}
                onShare={handleShare}
                setPosts={setPosts}
                user={user}
                onPostClick={openPostModal}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <AdPage />
          <RelatedArtists />
          {loggedInUser && loggedInUser.role === "super-user" && loggedInUser.id === userId && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToAnalytics}
              fullWidth
              sx={{ mt: 2 }}
            >
              Go to Analytics
            </Button>
          )}

          {loggedInUser && loggedInUser.role === "super-user" && loggedInUser.id === userId && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToWebsite}
              fullWidth
              sx={{ mt: 2 }}
            >
              Channel Management
            </Button>
          )}

          {loggedInUser && loggedInUser.role === "super-user" && loggedInUser.id === userId && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToSettings}
              fullWidth
              sx={{ mt: 2 }}
            >
              Edit Profile
            </Button>
          )}
        </Grid>
      </Grid>


      {/* Modal for displaying post details */}
      <Modal open={openModal} onClose={closePostModal}>
        <Box
          sx={{
            position: 'relative',
            width: { xs: '90%', sm: 500 },
            margin: 'auto',
            marginTop: '10vh',
            padding: { xs: 2, sm: 3 },
            backgroundColor: 'white',
            borderRadius: 2,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <IconButton
            onClick={closePostModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.500',
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          {console.log('Selected Post:', selectedPost)}
          {selectedPost && (
            <>
              <Typography variant="h6">{selectedPost.caption}</Typography>
              <Typography variant="body2" color="textSecondary">
                Posted by: {selectedPost.createdBy?.firstName} {selectedPost.createdBy?.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Date: {new Date(selectedPost.date).toLocaleDateString()}
              </Typography>
              {selectedPost.mediaType === 'image' ? (
                <img
                  src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${selectedPost.mediaPath}`}
                  alt={selectedPost.caption}
                  style={{ width: '100%', borderRadius: 4, marginTop: 16 }}
                />
              ) : (
                <video
                  src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${selectedPost.mediaPath}`}
                  controls
                  style={{ width: '100%', borderRadius: 4, marginTop: 16 }}
                />
              )}
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                {selectedPost.caption}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                {selectedPost.likes.length} likes
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default MainPage;

const styles = {
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full viewport height to center vertically
    textAlign: 'center',
  },
  loginButton: {
    marginTop: '1em',
    padding: '0.5em 1em',
    fontSize: '1em',
    cursor: 'pointer',
  },
};
