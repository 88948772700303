import React, { useState } from 'react';
import { Modal, TextField, Button, Grid, MenuItem, Typography, Box, FormControl, InputLabel, Select, Tabs, Tab, Drawer, useMediaQuery } from '@mui/material';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import CmsNavbar from './CmsNavbar'; // Import the Navbar component
import { useNavigate } from 'react-router-dom'; // UseNavigate from React Router v6
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CMSSuperUserForm = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate(); // Updated to use navigate instead of history
    const [selectedDate, setSelectedDate] = useState(null);
    const [moviesAsLead, setMoviesAsLead] = useState([{ title: '', role1: '', link: '', production: '', thumbnail: '' }]);

    const isSmallScreen = useMediaQuery('(max-width: 900px)');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: JSON.parse(localStorage.getItem('user'))?.email || '',
            password: '',
            biography: '',
            birthDate: null,
            nationality: '',
            gender: '',
            role: 'usertoapprove',
            knownFor: [''],
            contactInfo: {
                phone: '',
                address: {
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            },
            categories: [],
            moviesAsLead: [],
            musicAlbums: [{ title: '', link: '', role1: '', thumbnail: '' }],
            documentaries: [{ title: '', platform: '', role1: '', thumbnail: '' }],
            customSections: [],
        },
        onSubmit: async (values) => {
            values.role = values.role || 'super-user';
            console.log('Form values:', values); // Log form values on submit

            try {
                // Send form data to the server
                const response = await axios.post('https://thecameratales.com/api/admin/createsuperuser/request', values, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Handle success response
                console.log('Submit clicked');
                console.log('Server response:', response.data);

                // Show modal for 3 seconds and then log out
                setModalOpen(true);

                setTimeout(() => {
                    // Clear user data and navigate to login page or homepage
                    localStorage.removeItem('user'); // Remove user from localStorage
                    setModalOpen(false);
                    navigate('/login'); // Redirect to login page using navigate
                }, 3000); // Wait for 3 seconds before logging out

            } catch (error) {
                // Check if there's a response with a validation error message
                if (error.response && error.response.data && error.response.data.message) {
                    // Show validation error as a toast
                    toast.error(error.response.data.message);
                } else {
                    // Show a general error if something else went wrong
                    toast.error('An unexpected error occurred.');
                }
            }
        },
    });


    // Log formik errors and touched fields
    console.log('Formik errors:', formik.errors);
    console.log('Formik touched fields:', formik.touched);


    // Movies As Lead handlers
    const handleMovieChange = (index, field, value) => {
        const updatedMovies = [...moviesAsLead];
        updatedMovies[index][field] = value;
        setMoviesAsLead(updatedMovies);
        formik.setFieldValue('moviesAsLead', updatedMovies);
    };


    return (
        <>
            {/* <CmsNavbar /> */}
            <Box sx={{ display: 'flex', mt: '8vh' }}>

                <Box sx={{
                    padding: '20px',
                    background: 'linear-gradient(135deg, #2e3b4e, #1d2736)', // Dark gradient from deep blue to dark grey
                    color: 'white', // White text for contrast
                    fontWeight: 'bolder',
                    marginTop: '0vh',
                    // marginRight: '3vw',
                    borderRadius: '8px', // Slightly rounded corners
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)', // Slightly stronger shadow for more depth
                    width: '100%',
                    border: '1px solid #34495e', // Light border for the editor feel
                    backdropFilter: 'blur(5px)', // Slight blur effect for background
                }}>
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: '20px',
                            fontWeight: 'bolder',
                            fontSize: {
                                xs: '1.5rem', // Smaller font size for extra-small screens
                                sm: '2rem',   // Adjust font size for small screens
                                md: '2.5rem', // Medium screens and above
                                lg: '3rem',   // Large screens
                            },
                        }}
                    >
                        Register As Artist / Production House
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} sx={{
                            minHeight: '100vh',
                            minWidth: isSmallScreen ? '100vw' : '0', // Set minWidth to 100vw for small screens, otherwise 0
                        }}
                        >
                            {/* First Name */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    fullWidth
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    sx={{
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'cornflowerblue', // blue color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            color: '#333', // Dark text color
                                            backgroundColor: '#fafafa', // Light background
                                            borderRadius: '8px', // Rounded corners
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border initially
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2', // Blue border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'cornflowerblue', // Pink border on focus
                                                borderWidth: '2px', // Thicker border on focus
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: '#d32f2f', // Red for helper text (error)
                                        },
                                    }}
                                />
                            </Grid>


                            {/* Last Name */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    fullWidth
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    sx={{
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'cornflowerblue', // blue color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            color: '#333', // Dark text color
                                            backgroundColor: '#fafafa', // Light background
                                            borderRadius: '8px', // Rounded corners
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border initially
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2', // Blue border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'cornflowerblue', // Pink border on focus
                                                borderWidth: '2px', // Thicker border on focus
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: '#d32f2f', // Red for helper text (error)
                                        },
                                    }}
                                />
                            </Grid>

                            {/* Categories */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Select Your Role/Roles</InputLabel>
                                    <Select
                                        multiple
                                        name="categories"
                                        value={formik.values.categories}
                                        onChange={(event) => formik.setFieldValue('categories', event.target.value)}
                                        renderValue={(selected) => (
                                            <div>
                                                {selected.join(', ')}
                                            </div>
                                        )}
                                        error={formik.touched.categories && Boolean(formik.errors.categories)}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 200, // Set the fixed height for the dropdown
                                                    overflowY: 'auto', // Allow vertical scrolling if content overflows
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                color: 'black', // White text color for selected values
                                                backgroundColor: 'white', // Background color of the select box
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: 'white', // Background color for the input field
                                            },
                                        }}
                                    >
                                        <MenuItem value="Actor">Actor</MenuItem>
                                        <MenuItem value="Actress">Actress</MenuItem>
                                        <MenuItem value="Producer">Producer</MenuItem>
                                        <MenuItem value="Co-Producer">Co-Producer</MenuItem>
                                        <MenuItem value="Execuitive Producer">Execuitive Producer</MenuItem>
                                        <MenuItem value="Director">Director</MenuItem>
                                        <MenuItem value="Assistant Director">Assistant Director</MenuItem>
                                        <MenuItem value="Associate Director">Associate Director</MenuItem>
                                        <MenuItem value="Action Director">Action Director</MenuItem>
                                        <MenuItem value="Lyrics Writer">Lyrics Writer</MenuItem>
                                        <MenuItem value="Writer">Writer</MenuItem>
                                        <MenuItem value="Cinematographer">Cinematographer</MenuItem>
                                        <MenuItem value="Singer">Singer</MenuItem>
                                        <MenuItem value="Musicians">Musicians</MenuItem>
                                        <MenuItem value="Music Director">Music Director</MenuItem>
                                        <MenuItem value="Music Composer">Music Composer</MenuItem>
                                        <MenuItem value="Editor/Colouriest">Editor/Colouriest</MenuItem>
                                        <MenuItem value="Executive Producer">Executive Producer</MenuItem>
                                        <MenuItem value="Line Producer">Line Producer</MenuItem>
                                        <MenuItem value="Production Manager">Production Manager</MenuItem>
                                        <MenuItem value="Make Up">Make Up</MenuItem>
                                        <MenuItem value="VFX Artist">VFX Artist</MenuItem>
                                        <MenuItem value="Art Director">Art Director</MenuItem>
                                        <MenuItem value="Costume Designer">Costume Designer</MenuItem>
                                        <MenuItem value="Production Houses">Production Houses</MenuItem>
                                        <MenuItem value="Editing Studio">Editing Studio</MenuItem>
                                        <MenuItem value="Music Studio">Music Studio</MenuItem>
                                        <MenuItem value="Dubbing Studio">Dubbing Studio</MenuItem>
                                        <MenuItem value="VFX Studio">VFX Studio</MenuItem>
                                        <MenuItem value="Stunt Man">Stunt Man</MenuItem>
                                        <MenuItem value="Spot Dada">Spot Dada</MenuItem>
                                        <MenuItem value="Lightman">Lightman</MenuItem>
                                    </Select>
                                    {formik.touched.categories && formik.errors.categories && (
                                        <Typography color="error" variant="body2">{formik.errors.categories}</Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    sx={{
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'cornflowerblue', // blue color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            color: '#333', // Dark text color
                                            backgroundColor: '#fafafa', // Light background
                                            borderRadius: '8px', // Rounded corners
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border initially
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2', // Blue border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'cornflowerblue', // Pink border on focus
                                                borderWidth: '2px', // Thicker border on focus
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: '#d32f2f', // Red for helper text (error)
                                        },
                                    }}
                                />
                            </Grid>

                            {/* Nationality */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Nationality"
                                    name="nationality"
                                    fullWidth
                                    value={formik.values.nationality}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'cornflowerblue', // blue color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            color: '#333', // Dark text color
                                            backgroundColor: '#fafafa', // Light background
                                            borderRadius: '8px', // Rounded corners
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border initially
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2', // Blue border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'cornflowerblue', // Pink border on focus
                                                borderWidth: '2px', // Thicker border on focus
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: '#d32f2f', // Red for helper text (error)
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Gender"
                                    name="gender"
                                    select
                                    fullWidth
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            color: 'black', // Text color for selected value
                                            backgroundColor: 'white', // Background color of the select box
                                        },
                                        '& .MuiInputBase-root': {
                                            backgroundColor: 'white', // Background color for the input field
                                        },
                                    }}
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "1vh", textAlign: 'left' }}>
                                <Typography variant="h6">Date of Birth:</Typography>
                                {/* <p>(yyyy-mm-dd)</p> */}
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        formik.setFieldValue('birthDate', date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="yyyy-mm-dd" // Distinct placeholder text
                                    customInput={
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                style: {
                                                    borderRadius: '2px', // Rounded corners
                                                    backgroundColor: 'white', // Light cream background
                                                    // padding: '2px 5px', // Padding for the input
                                                    color: '#333333', // Dark text color
                                                    fontWeight: '500', // Medium font weight
                                                },
                                            }}
                                            placeholder="yyyy-mm-dd" // Placeholder text style
                                            sx={{
                                                width: '100%',
                                                maxWidth: '400px', // Adjust the width of the date picker
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '16px', // Pill-shaped corners
                                                    backgroundColor: '#ffffff', // White input background
                                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
                                                    '&:hover': {
                                                        backgroundColor: '#f9f9f9', // Slightly lighter on hover
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    textAlign: 'left', // Center the text for the placeholder
                                                    color: '#1976d2', // Blue input text
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for error messages
                                                },
                                            }}
                                            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                                            helperText={formik.touched.birthDate && formik.errors.birthDate}
                                        />
                                    }
                                    calendarClassName="custom-calendar" // Custom calendar class
                                />
                            </Grid>


                            {/* Biography */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Biography"
                                    name="biography"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    value={formik.values.biography}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'cornflowerblue', // blue color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            color: '#333', // Dark text color
                                            backgroundColor: '#fafafa', // Light background
                                            borderRadius: '8px', // Rounded corners
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border initially
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2', // Blue border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'cornflowerblue', // Pink border on focus
                                                borderWidth: '2px', // Thicker border on focus
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: '#d32f2f', // Red for helper text (error)
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Known For</Typography>
                                {formik.values.knownFor.map((item, index) => (
                                    <Box key={index} sx={{ marginBottom: '16px' }}>
                                        <TextField
                                            label="Known For"
                                            name={`knownFor[${index}]`}
                                            fullWidth
                                            value={item}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue', // blue color on focus
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333', // Dark text color
                                                    backgroundColor: '#fafafa', // Light background
                                                    borderRadius: '8px', // Rounded corners
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                                    '& fieldset': {
                                                        borderColor: 'transparent', // No border initially
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2', // Blue border on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue', // Pink border on focus
                                                        borderWidth: '2px', // Thicker border on focus
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for helper text (error)
                                                },
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Contact Info</Typography>

                                <Grid container spacing={2}>
                                    {/* Phone */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Phone"
                                            name="contactInfo.phone"
                                            fullWidth
                                            value={formik.values.contactInfo.phone}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* Street */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Street"
                                            name="contactInfo.address.street"
                                            fullWidth
                                            value={formik.values.contactInfo.address.street}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* City */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="City"
                                            name="contactInfo.address.city"
                                            fullWidth
                                            value={formik.values.contactInfo.address.city}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* State */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="State"
                                            name="contactInfo.address.state"
                                            fullWidth
                                            value={formik.values.contactInfo.address.state}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* ZIP */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="ZIP"
                                            name="contactInfo.address.zip"
                                            fullWidth
                                            value={formik.values.contactInfo.address.zip}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* Country */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Country"
                                            name="contactInfo.address.country"
                                            fullWidth
                                            value={formik.values.contactInfo.address.country}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333',
                                                    backgroundColor: '#fafafa',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Work Link (for verification)</Typography>
                                {moviesAsLead.map((movie, index) => (
                                    <Box key={index} sx={{ marginBottom: '16px' }}>
                                        <TextField
                                            label="Title"
                                            name={`moviesAsLead[${index}].title`}
                                            fullWidth
                                            value={movie.title}
                                            onChange={(e) => handleMovieChange(index, 'title', e.target.value)}
                                            sx={{
                                                marginBottom: '16px',
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue', // blue color on focus
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333', // Dark text color
                                                    backgroundColor: '#fafafa', // Light background
                                                    borderRadius: '8px', // Rounded corners
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                                    '& fieldset': {
                                                        borderColor: 'transparent', // No border initially
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2', // Blue border on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue', // Pink border on focus
                                                        borderWidth: '2px', // Thicker border on focus
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for helper text (error)
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Role"
                                            name={`moviesAsLead[${index}].role1`}
                                            fullWidth
                                            value={movie.role1}
                                            onChange={(e) => handleMovieChange(index, 'role1', e.target.value)}
                                            sx={{
                                                marginBottom: '16px',
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue', // blue color on focus
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333', // Dark text color
                                                    backgroundColor: '#fafafa', // Light background
                                                    borderRadius: '8px', // Rounded corners
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                                    '& fieldset': {
                                                        borderColor: 'transparent', // No border initially
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2', // Blue border on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue', // Pink border on focus
                                                        borderWidth: '2px', // Thicker border on focus
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for helper text (error)
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Link"
                                            name={`moviesAsLead[${index}].link`}
                                            fullWidth
                                            value={movie.link}
                                            onChange={(e) => handleMovieChange(index, 'link', e.target.value)}
                                            sx={{
                                                marginBottom: '16px',
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue', // blue color on focus
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333', // Dark text color
                                                    backgroundColor: '#fafafa', // Light background
                                                    borderRadius: '8px', // Rounded corners
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                                    '& fieldset': {
                                                        borderColor: 'transparent', // No border initially
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2', // Blue border on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue', // Pink border on focus
                                                        borderWidth: '2px', // Thicker border on focus
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for helper text (error)
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Production"
                                            name={`moviesAsLead[${index}].production`}
                                            fullWidth
                                            value={movie.production}
                                            onChange={(e) => handleMovieChange(index, 'production', e.target.value)}
                                            sx={{
                                                marginBottom: '16px',
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'cornflowerblue', // blue color on focus
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    color: '#333', // Dark text color
                                                    backgroundColor: '#fafafa', // Light background
                                                    borderRadius: '8px', // Rounded corners
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                                    '& fieldset': {
                                                        borderColor: 'transparent', // No border initially
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1976d2', // Blue border on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'cornflowerblue', // Pink border on focus
                                                        borderWidth: '2px', // Thicker border on focus
                                                    },
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    color: '#d32f2f', // Red for helper text (error)
                                                },
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        marginTop: '5vh',
                                        backgroundColor: '#ffffff',
                                        color: '#333333', // Dark grey text for subtle contrast
                                        fontWeight: '600', // Slightly bold font
                                        padding: '12px 24px', // Larger padding for better prominence
                                        border: '2px solid #e0e0e0', // Light grey border
                                        borderRadius: '20px', // Pill-shaped button
                                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)', // Softer shadow
                                        textTransform: 'uppercase', // Uppercase text for a modern feel
                                        letterSpacing: '0.5px', // Slightly spaced letters
                                        transition: 'all 0.3s ease-in-out', // Smooth transitions for hover effects
                                        '&:hover': {
                                            backgroundColor: '#f9f9f9', // Lighter grey on hover
                                            borderColor: '#1976d2', // Blue border on hover
                                            color: '#1976d2', // Blue text on hover
                                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // More prominent shadow on hover
                                        },
                                        '&:active': {
                                            transform: 'scale(0.98)', // Slight scale down for click effect
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Softer shadow when active
                                        },
                                    }}
                                >
                                    Submit
                                </Button>


                            </Grid>
                        </Grid>
                        {/* Modal to thank the user */}
                        <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    borderRadius: '10px',
                                    boxShadow: 24,
                                    p: 4,
                                    textAlign: 'center'
                                }}
                            >
                                <Typography id="modal-title" variant="h6" component="h2" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                                    Thank You!
                                </Typography>
                                <Typography id="modal-description" sx={{ mt: 2, color: 'text.secondary' }}>
                                    Thank You! Information Submitted Successfully!  Please log back in again!  Auto Logging out....
                                </Typography>
                            </Box>
                        </Modal>

                    </form>
                </Box>
            </Box >
        </>
    );
};

export default CMSSuperUserForm;
