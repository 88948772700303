import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import shaka from 'shaka-player/dist/shaka-player.ui';
import 'shaka-player/dist/controls.css'; // Import Shaka Player UI CSS
import { io } from 'socket.io-client';
import { FaThumbsUp, FaComment, FaShare, FaEye } from 'react-icons/fa';
import publicClient from '../api/client/public.client';
import tmdbConfigs from '../api/configs/tmdb.configs';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import EpisodesSlider from './EpisodeSlider';
import { VideoContext } from '../Context/VideoContext';
import { Container } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";


SwiperCore.use([Navigation]);

const socket = io('https://thecameratales.com', {
    reconnection: true
});

const styles = {
    container: {
        display: 'flex',
        gap: '20px',
        padding: '10px',
        marginLeft: '10px',
        marginTop: '80px',
        // marginLeft: '3vw',
        flexDirection: 'row',
        width: '100vw'
    },
    videoContainer: {
        flex: 3,
    },
    relatedMovies: {
        flex: 1,
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        overflowY: 'auto',
        marginRight: '4vw',
        height: '85vh',
        color: 'white',
    },
    relatedMoviesHidden: {
        display: 'none',
        padding: '0',
        margin: '0',
    },
    loginText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        color: 'red',
        fontSize: '24px',
        fontWeight: 'bold',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '10px',
        borderRadius: '10px',
        zIndex: 10,
    },
    loginText2: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        color: 'red',
        fontSize: '24px',
        fontWeight: 'bold',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '10px',
        borderRadius: '10px',
        zIndex: 9,
    },
    customButton: {
        color: 'grey',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '18px',
        padding: '5px',
        fontWeight: 'bolder',
        fontStyle: 'italic'
    },
};

const mediaQuery = `
  @media (max-width: 768px) {
    .related-movies {
      display: none;
    }
  }
`;

const PlayVideo = () => {
    const { selectedVideo } = useContext(VideoContext);
    const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
    const [genres, setGenres] = useState([]);
    const [relatedMovies, setRelatedMovies] = useState([]);
    const { mediaId, mediaType } = useParams(); // Assuming mediaType is obtained from URL params
    // const { mediaId } = useParams();
    const [videoUrls, setVideoUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [showAds, setShowAds] = useState(false); // State to control ad display
    const [videoLikes, setVideoLikes] = useState(0);
    const [title, setTitle] = useState('');
    const [overview, setOverview] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showFullOverview, setShowFullOverview] = useState(false); // State for toggling overview
    const [episodes, setEpisodes] = useState([]);
    const [selectedEpisodeUrl, setSelectedEpisodeUrl] = useState(null); // State for selected episode URL
    const [continueWatchingTime, setContinueWatchingTime] = useState(null); // State for Continue Watching
    const [currentAdIndex, setCurrentAdIndex] = useState(0); // State to track current ad
    const [ads, setAds] = useState([]); // State to store fetched ads
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [videoViews, setVideoViews] = useState(0);

    const isSmallScreen = useMediaQuery("(max-width: 600px) and (max-height: 858px)");





    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const uiRef = useRef(null);
    const adInterval = useRef(null); // Reference to store ad interval
    const commentsRef = useRef(null);


    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    const getUserId = () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        return storedUser ? storedUser.id : null; // Extract only the ID
    };

    const isSubscriptionActive = () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.subscription) {
            const { status, startDate, endDate } = storedUser.subscription;
            const currentDate = new Date();

            return (
                status === 'active' &&
                new Date(startDate) <= currentDate &&
                currentDate <= new Date(endDate)
            );
        }
        return false;
    };

    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch(error => {
                console.error('Error attempting to play:', error);
            });
        }
    };

    const location = useLocation();  // This is where hooks should be called

    // Rename mediaType to avoid conflict
    const extractedMediaType = location.pathname.split('/')[1];  // Extract 'webseries' or 'movie'
    const extractedMediaId = location.pathname.split('/')[2];    // Extract mediaId from URL

    const sendProgress = async () => {
        if (videoRef.current) {
            const currentTime = Number(videoRef.current.currentTime);
            console.log('Sending progress:', currentTime);

            try {
                const token = localStorage.getItem('token');
                const userId = getUserId();

                if (!userId || !token || !extractedMediaType || !mediaId) return;

                // Dynamically construct the API URL based on the extractedMediaType
                const apiUrl = `${apiBaseURL}/api/${extractedMediaType}/progress`;

                await axios.put(apiUrl, {
                    userId,
                    mediaId: extractedMediaId,
                    mediaType: extractedMediaType,  // Send mediaType using extracted variable
                    progress: currentTime,
                }, {
                    headers: { 'x-auth-token': token }
                });
            } catch (err) {
                console.error('Error updating progress:', err.response ? err.response.data : err.message);
            }
        }
    };

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (videoRef.current) {
                const currentTime = videoRef.current.currentTime;
                console.log('Current time:', currentTime); // Debug log
                sendProgress(currentTime);
            }
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
            return () => {
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [mediaId, user]);


    useEffect(() => {
        const fetchProgress = async () => {
            if (!isAuthenticated) return; // Exit if not authenticated

            try {
                const token = localStorage.getItem('token');
                const userId = getUserId();
                const response = await axios.get(`${apiBaseURL}/api/${extractedMediaType}/progress/${mediaId}`, {
                    params: { userId },
                    headers: { 'x-auth-token': token }
                });
                const { progress } = response.data;

                setProgress(progress || 0);

                if (videoRef.current) {
                    videoRef.current.currentTime = progress || 0;
                }
            } catch (err) {
                console.error('Error fetching progress:', err.message);
            }
        };

        fetchProgress();
    }, [mediaId, isAuthenticated]);


    useEffect(() => {
        if (selectedVideo) {
            setCurrentVideoUrl(selectedVideo.video_urls.master);
        }
    }, [selectedVideo]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsAuthenticated(!!token);

        if (token) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            setUser(storedUser);
        }

        socket.on('connect', () => {
            console.log('Socket connected:', socket.id);
        });

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        socket.on('new-comment', (comment) => {
            setComments((prevComments) => [...prevComments, comment]);
        });

        socket.on('update-comment', (updatedComment) => {
            setComments((prevComments) => prevComments.map(comment =>
                comment._id === updatedComment._id ? updatedComment : comment
            ));
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('new-comment');
            socket.off('update-comment');
        };
    }, []);

    // useEffect(() => {
    //     const fetchAds = async () => {
    //         try {
    //             const response = await fetch(`${apiBaseURL}/api/movie/6699ff6fd39bca55ec005e51`);
    //             const data = await response.json();
    //             console.log('Fetched data:', data);
    //             setAds(data.ads);
    //         } catch (error) {
    //             console.error('Error fetching ads:', error);
    //         }
    //     };

    //     fetchAds();
    // }, []);

    const token = localStorage.getItem('token');


    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/${mediaType}/video/${mediaId}`, {
                    headers: {
                        'x-auth-token': token,  // Send the token in the header
                    }
                });
                setVideoUrls(response.data.urls);
                setCurrentVideoUrl(response.data.urls.master); // Set the default video URL
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        const fetchMovieDetails = async () => {
            try {
                const response = await publicClient.get(`${apiBaseURL}/api/${mediaType}/detail/${mediaId}`);
                setTitle(response.title);
                setOverview(response.overview);
                setGenres(response.genre);
                setVideoViews(response.views);
                fetchRelatedMovies(response.genre);
            } catch (err) {
                console.error('Error fetching movie details:', err.message);
            }
        };

        // const fetchEpisodes = async () => {
        //     try {
        //         const response = await axios.get(`${apiBaseURL}/api/movie/${mediaId}/episodes`);
        //         console.log('Episodes response:', response.data);
        //         setEpisodes(response.data);
        //         setSelectedEpisodeUrl(`https://thecameratales.com/${response.data[0].video_urls.master}`); // Set the first episode URL by default
        //     } catch (error) {
        //         console.error('Error fetching episodes:', error.message);
        //     }
        // };

        // Fetch episodes when component loads
        // useEffect(() => {
        const fetchEpisodes = async () => {
            try {
                // Extract the type from the current URL path
                const pathParts = window.location.pathname.split('/');
                const type = pathParts[1]; // Assumes type is the first segment after the root
                const mediaId = pathParts[2]; // Assumes mediaId is the next segment in the path

                const response = await axios.get(`${apiBaseURL}/api/${type}/${mediaId}/episodes`);
                setEpisodes(response.data);

                // Check if there is a stored URL, otherwise default to the first episode
                const savedEpisodeUrl = localStorage.getItem('selectedEpisodeUrl');
                if (savedEpisodeUrl) {
                    setSelectedEpisodeUrl(savedEpisodeUrl);
                } else if (response.data[0]?.video_urls?.master) {
                    setSelectedEpisodeUrl(`https://thecameratales.com/${response.data[0].video_urls.master}`);
                } else {
                    console.warn('No video URL found for the first episode.');
                }
            } catch (error) {
                console.error('Error fetching episodes:', error.message);
            }
        };

        //     fetchEpisodes();
        // }, [mediaId, apiBaseURL]);

        const fetchComments = async () => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/comments/${mediaId}`);
                setComments(response.data);
            } catch (err) {
                console.error('Error fetching comments:', err.message);
            }

            fetchVideoLikes();
        };

       
    

        const fetchVideoLikes = async () => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/${mediaType}/${mediaId}/like`);
                setVideoLikes(response.data.likes);
            } catch (err) {
                console.error('Error fetching video likes:', err.message);
            }
        };

        const fetchRelatedMovies = async (genres) => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/${mediaType}/by-genre?genres=${genres.join(',')}`);
                setRelatedMovies(response.data.slice(0, 5)); // Limit to 2 related movies
            } catch (err) {
                console.error('Error fetching related movies:', err.message);
            }
        };
        const fetchInitialVideoLikes = async () => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/${mediaType}/${mediaId}/like`);
                setVideoLikes(response.data.likes);
            } catch (err) {
                console.error('Error fetching initial video likes:', err.message);
            }
        };

        if (selectedVideo) {
            const { _id: mediaId, mediaType } = selectedVideo;
            fetchVideoDetails(mediaId, mediaType);
            fetchMovieDetails(mediaId, mediaType);
            fetchComments(mediaId);
            fetchEpisodes(mediaId);
        }


        fetchInitialVideoLikes();
        fetchVideoDetails();
        fetchMovieDetails();
        fetchComments();
        fetchEpisodes();
    }, [mediaId], [selectedVideo]);

    // const controls = uiRef.current.getControls();

    useEffect(() => {
        const initPlayer = async () => {
            if (videoUrls.master && videoRef.current && isAuthenticated) {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }

                const video = videoRef.current;
                playerRef.current = new shaka.Player(video);
                uiRef.current = new shaka.ui.Overlay(playerRef.current, video.parentElement, video);

                playerRef.current.addEventListener('error', onErrorEvent);
                const controls = uiRef.current.getControls();
                uiRef.current.getControls().addEventListener('error', onErrorEvent);

                const loadVideo = async () => {
                    try {
                        await playerRef.current.load(videoUrls.master);
                        console.log('Video loaded');
                        startAdInterval(); // Start ads when video loads
                        console.log('The video has now been loaded!');
                        video.currentTime = progress; // Resume from saved progress
                        video.play(); // Ensure the video plays
                    } catch (error) {
                        onErrorEvent(error);
                    }
                };

                const checkMediaSourceState = () => {
                    const mediaSource = videoRef.current && videoRef.current.srcObject;
                    if (mediaSource && mediaSource.readyState !== 'open') {
                        console.error('MediaSource is not in the open state');
                    }
                };

                // Check MediaSource state before loading video
                checkMediaSourceState();
                loadVideo();

                // Add custom resolution buttons
                const localization = controls.getLocalization();
                localization.insert('en', [
                    { id: '480p', text: '480p' },
                    { id: '360p', text: '360p' },
                    { id: '720p', text: '720p' },
                ]);

                const resolutionButtons = [
                    // Add your resolution buttons here
                ];

                resolutionButtons.forEach(button => {
                    const buttonElement = document.createElement('button');
                    buttonElement.innerText = button.label;
                    buttonElement.className = 'shaka-res-button';
                    buttonElement.onclick = () => handleManualResolutionChange(button.height);
                    controls.getControlsContainer().appendChild(buttonElement);
                });

                video.addEventListener('timeupdate', sendProgress);

                return () => {
                    if (uiRef.current) uiRef.current.destroy();
                    if (playerRef.current) playerRef.current.destroy();
                    if (video) video.removeEventListener('timeupdate', sendProgress);
                };

            }
        };
        initPlayer();
    }, [videoUrls.master, isAuthenticated, progress, continueWatchingTime]);

    const startAdInterval = () => {
        if (ads.length === 0) {
            console.error('No ads available to display');
            return;
        }

        adInterval.current = setInterval(() => {
            setShowAds(true);
            if (videoRef.current) {
                videoRef.current.pause(); // Pause the video when ad starts
            }
            setTimeout(() => {
                setShowAds(false);
                if (videoRef.current) {
                    videoRef.current.play(); // Resume the video when ad ends
                }
                setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length); // Cycle through ads
            }, 5000); // Display ad for 5 seconds
        }, 30000); // Show ads every 30 seconds
    };

    useEffect(() => {
        if (Array.isArray(ads) && ads.length > 0) {
            startAdInterval();
        }
        return () => {
            if (adInterval.current) {
                clearInterval(adInterval.current);
            }
        };
    }, [ads]);





    useEffect(() => {
        if (selectedEpisodeUrl && videoRef.current) {
            playerRef.current = new shaka.Player(videoRef.current);
            uiRef.current = new shaka.ui.Overlay(playerRef.current, videoRef.current.parentElement, videoRef.current);

            playerRef.current.load(selectedEpisodeUrl).then(() => console.log('The video has now been loaded!')).catch(onError);
        }

        return () => {
            if (uiRef.current) uiRef.current.destroy();
            if (playerRef.current) playerRef.current.destroy();
        };
    }, [selectedEpisodeUrl]);

    function onErrorEvent(event) {
        onError(event.detail);
    }

    function onError(error) {
        console.error('Error code', error.code, 'object', error);
    }

    const handleManualResolutionChange = (resolution) => {
        if (playerRef.current) {
            const tracks = playerRef.current.getVariantTracks();
            const selectedTrack = tracks.find(track => track.height === parseInt(resolution));
            if (selectedTrack) {
                playerRef.current.selectVariantTrack(selectedTrack, /* clearBuffer */ true);
                playerRef.current.configure({
                    abr: {
                        enabled: false
                    }
                });
            }
        }
    };
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!newComment.trim()) return;

        try {
            const response = await axios.post(`${apiBaseURL}/api/comments/${mediaId}`, {
                text: newComment,
                user: user ? user.firstName : 'Anonymous',
            });
            socket.emit('new-comment', response.data);
            setNewComment('');
        } catch (err) {
            console.error('Error posting comment:', err.message);
        }
    };

    const handleLike = async (commentId) => {
        try {
            const response = await axios.post(`${apiBaseURL}/api/comments/${mediaId}/${commentId}/like`);
            socket.emit('update-comment', response.data);
        } catch (err) {
            console.error('Error liking comment:', err.message);
        }
    };

    // const videoBaseUrl = `${apiBaseURL}/`;

    // const handleEpisodeClick = (episodeUrl) => {
    //     // Ensure the URL is fully qualified
    //     const normalizedUrl = episodeUrl.replace(/\\/g, '/');
    //     const fullUrl = `${videoBaseUrl}${normalizedUrl}`;
    //     setSelectedEpisodeUrl(fullUrl); // Update the selected episode URL
    // };

    // Save the selected episode URL to localStorage and trigger a refresh
    const handleEpisodeClick = (url) => {
        setSelectedEpisodeUrl(`https://thecameratales.com/${url}`);
        localStorage.setItem('selectedEpisodeUrl', `https://thecameratales.com/${url}`);

        // Optionally force a page reload to load the new episode (if necessary)
        window.location.reload();
    };


    const handleVideoLike = async () => {
        try {
            // Retrieve the user object from localStorage and parse it
            const user = JSON.parse(localStorage.getItem('user'));

            if (!user || !user.id) {
                console.error('User is not logged in or user ID is missing');
                return;
            }

            // Extract the user ID
            const userId = user.id;

            // Make the POST request with the userId in the body
            const response = await axios.post(`${apiBaseURL}/api/${mediaType}/${mediaId}/like`, {
                userId: userId,
            });

            // Update the video likes state
            setVideoLikes(response.data.likes);
        } catch (err) {
            console.error('Error liking video:', err.message);
        }
    };


    const handleScrollToComments = () => {
        if (commentsRef.current) {
            commentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleShare = async () => {
        const shareData = {
            title,
            text: overview,
            url: window.location.href
        };
        if (navigator.share) {
            try {
                await navigator.share(shareData);
                console.log('Video shared successfully');
            } catch (err) {
                console.error('Error sharing video:', err);
            }
        } else {
            try {
                await navigator.clipboard.writeText(shareData.url);
                alert('Link copied to clipboard');
            } catch (err) {
                console.error('Failed to copy link:', err);
            }
        }
    };

    const handleContinueWatching = async () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            setContinueWatchingTime(currentTime);
            try {
                await sendProgress(currentTime);
                alert('Progress saved!');
                window.location.reload(); // Reload the page to reflect the saved progress
            } catch (error) {
                console.error('Error saving progress:', error.message);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const toggleOverview = () => {
        setShowFullOverview(!showFullOverview);
    };

    const shortenedOverview = overview.length > 150 ? overview.slice(0, 150) + '...' : overview;

    const handleWatchNow = (mediaType, mediaId) => {
        const watchNowUrl = `/${mediaType}/${mediaId}/video`;
    
        // Save the constructed URL to localStorage
        localStorage.setItem('watchNowUrl', watchNowUrl);
    
        // Optionally log the navigation URL for debugging
        console.log('Navigating to:', watchNowUrl);
    
        // Navigate to the constructed URL
        window.location.href = watchNowUrl;
    };
    



    return (
        <>
            <style>
                {mediaQuery}
            </style>
            <div style={styles.container}>
                <div className="video-container" style={styles.videoContainer}>
                    <div style={{
                        width: isSmallScreen ? '97vw' : '100%', // Set width to 100vw on small screens
                        // padding: isSmallScreen ? '10px' : '0',
                        // height: '100%',
                    }}>
                        {videoUrls ? (
                            isAuthenticated ? (
                                // isSubscriptionActive() ? (
                                <>
                                    <div
                                        className="shaka-video-container"
                                        style={{ width: '100%', height: '100%' }}
                                    >
                                        <video
                                            ref={videoRef}
                                            className="shaka-video"
                                            width="100%"
                                            controls={false}
                                            // autoPlay
                                            onCanPlay={handlePlayVideo}
                                            onPlay={handlePlayVideo}
                                        />
                                        <div className="shaka-overlay" />
                                    </div>
                                    {error && <p>Error loading video: {error}</p>}
                                </>
                                // ) : (
                                // <h1 style={{ color: 'white', fontStyle: "italic" }}>Your subscription is not active. Please renew your subscription to watch the video.</h1>
                                // )
                            ) : (
                                <>
                                    {/* Full-screen overlay with login button */}
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 9999,
                                            animation: 'fadeIn 1s', // Animation for fade-in effect
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: 'white',
                                                fontSize: '1.5rem',
                                                padding: '20px',
                                                borderRadius: '10px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Slightly darker background
                                            }}
                                        >
                                            <p style={{ marginBottom: '20px' }}>
                                                Please log in to play the video.
                                            </p>
                                            <Link to="/login">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    sx={{
                                                        fontSize: '1.2rem',
                                                        padding: '12px 24px',
                                                        textTransform: 'none',
                                                        backgroundColor: '#1976d2',
                                                        '&:hover': { backgroundColor: '#1565c0' },
                                                    }}
                                                >
                                                    Log In
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <p>No video found for this ID</p>
                        )}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {/* {isAuthenticated && isSubscriptionActive() && ( */}
                        {isAuthenticated && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderRadius: '50px',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        marginRight: '10px',
                                    }}
                                    onClick={handleVideoLike}
                                >
                                    <FaThumbsUp style={{ color: "#D3D3D3" }} />
                                    <span style={{ marginLeft: '5px' }}>
                                        <b style={{ color: "#D3D3D3" }}>{videoLikes}</b>
                                    </span>
                                </button>
                                <button
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderRadius: '50px',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        marginRight: '10px',
                                    }}
                                    onClick={handleScrollToComments}
                                >
                                    <FaComment style={{ color: "#D3D3D3" }} />
                                    <span style={{ marginLeft: '5px' }}>
                                        <b style={{ color: "#D3D3D3" }}>{comments.length}</b>
                                    </span>
                                </button>
                                <FaEye style={{ color: "#D3D3D3" }} />
                                <span style={{ margin: '5px' }}>
                                    <b style={{ color: "#D3D3D3" }}>{videoViews}</b>
                                </span>
                                <button
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderRadius: '50px',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                    }}
                                    onClick={handleShare}
                                >
                                    <FaShare style={{ color: "#D3D3D3" }} />
                                </button>
                            </div>
                        )}
                    </div>
                    {/* </Container> */}
                    <div
                        style={{
                            color: "white",
                            display: "flex",
                            flexDirection: "column", // Column layout for better alignment
                            justifyContent: "center",
                            alignItems: "flex-start", // Align to the left
                            marginBottom: "20px",
                            padding: "20px",
                            borderRadius: "10px",
                            background: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow
                            width: "100%",
                        }}
                    >
                        <h1
                            style={{
                                color: "#FFD700", // Golden text color
                                fontSize: "2vw",
                                marginBottom: "10px",
                                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)", // Text shadow for depth
                            }}
                        >
                            {title}
                        </h1>
                        <p
                            style={{
                                color: "#D3D3D3", // Light gray for readability
                                fontWeight: "300",
                                fontSize: "1.8vw",
                                lineHeight: "1.8em",
                                marginBottom: "10px",
                            }}
                        >
                            {showFullOverview ? overview : shortenedOverview}
                            {overview.length > 150 && (
                                <span
                                    style={{
                                        color: "white", // Golden color for highlight
                                        fontWeight: "bold",
                                        fontSize: "1.2vw",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        textDecoration: "underline", // Indicates interactivity
                                        transition: "color 0.3s ease",
                                    }}
                                    onClick={toggleOverview}
                                    onMouseOver={(e) => (e.target.style.color = "#FF4500")} // Change color on hover
                                    onMouseOut={(e) => (e.target.style.color = "white")}
                                >
                                    {showFullOverview ? "Show Less" : "Read More"}
                                </span>
                            )}

                        </p>
                    </div>

                    <div style={{ width: "70vw" }}>
                        <EpisodesSlider episodes={episodes} onEpisodeClick={handleEpisodeClick} />
                    </div>

                    {isAuthenticated && (
                        <div ref={commentsRef} style={{ marginBottom: '20px' }}>
                            <h3 style={{ color: "#D3D3D3" }}>Comments ({comments.length})</h3>
                            <ul
                                style={{
                                    listStyleType: 'none',
                                    padding: '0',
                                    marginBottom: '20px',
                                }}
                            >
                                {comments.map((comment) => (
                                    <li
                                        key={comment._id}
                                        style={{
                                            marginBottom: '10px',
                                            padding: '10px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>
                                            <strong>{comment.user}:</strong> {comment.text}
                                        </div>
                                        <button
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '5px',
                                                borderRadius: '50px',
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                            }}
                                            onClick={() => handleLike(comment._id)}
                                        >
                                            <FaThumbsUp />
                                            <span style={{ marginLeft: '5px' }}>{comment.likes}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <form
                                onSubmit={handleCommentSubmit}
                                style={{
                                    display: "flex", // Align children in a row
                                    alignItems: "center", // Vertically align input and button
                                    gap: "10px", // Adds spacing between input and button
                                }}
                            >
                                <input
                                    type="text"
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    placeholder="Add a comment..."
                                    style={{
                                        flex: "1", // Makes input take up remaining space
                                        padding: "10px",
                                        height: "40px", // Ensure consistent height
                                        border: "1px solid #ccc", // Add border for clarity
                                        borderRadius: "4px", // Slightly rounded corners
                                    }}
                                />
                                <button
                                    type="submit"
                                    style={{
                                        background: "linear-gradient(to right, #780206, #061161)",
                                        color: "white",
                                        padding: "10px 20px", // Add consistent padding
                                        border: "none", // Remove default border
                                        borderRadius: "4px", // Match input box style
                                        height: "40px", // Match input height
                                        cursor: "pointer", // Indicate interactivity
                                    }}
                                >
                                    Submit
                                </button>
                            </form>

                        </div>
                    )}
                </div>
                <div
                    className="related-movies"
                    style={{
                        ...styles.relatedMovies, // Preserve existing styles
                        maxWidth: '20vw', // Set a smaller width
                    }}
                >
                    <h3>Related Movies</h3>
                    <div style={{ height: '100vh' }}>
                        <Swiper
                            direction="vertical"
                            spaceBetween={0}
                            slidesPerView={'auto'}
                            navigation
                        >
                            {relatedMovies.map((movie) => (
                                <SwiperSlide key={movie._id}>
                                    <img
                                        src={tmdbConfigs.posterPath(movie.poster_path)}
                                        alt={movie.title}
                                        style={{
                                            width: '100%',
                                            borderRadius: '10px',
                                            maxHeight: '400px',
                                            objectFit: 'contain',
                                        }}
                                        onError={(e) => {
                                            console.error('Error loading image:', e.target.src);
                                            e.target.src = 'https://via.placeholder.com/150';
                                        }}
                                        // onClick={() => handleWatchNow(movie.type, movie._id)}
                                    />
                                    <h4 style={{ marginTop: '5px', textAlign: 'center' }}>
                                        {movie.title}
                                    </h4>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

            </div>
        </>
    );
};


export default PlayVideo;