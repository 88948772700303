import axios from 'axios';

const baseUrl = 'https://thecameratales.com/api/movie/viewed-media';

export const getViewedMedia = async (userId) => {
  try {
    if (!userId) throw new Error("User ID and token are required");
    const response = await axios.get(baseUrl, {
    //   headers: {
    //     'x-auth-token': token,
    //   },
      params: {
        userId,
      },
    });
    return response.data.viewedMedia;
  } catch (error) {
    console.error('Error fetching viewed media:', error);
    throw error;
  }
};