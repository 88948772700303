import { Box, Card, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { routesGen } from "../../routes/routes";
import { useState, useEffect } from 'react';
import tmdbConfigs from "../../api/configs/tmdb.configs";

const MediaItem = ({ media, mediaType, isMediaGrid }) => {
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [posterPath, setPosterPath] = useState("");
  const [hoverImage, setHoverImage] = useState("");
  const [videoUrl, setVideoUrl] = useState(""); // New state for video URL
  const [releaseDate, setReleaseDate] = useState(null);
  const [rate, setRate] = useState(null);
  const [genres, setGenres] = useState([]);
  const [overview, setOverview] = useState("");
  const [showVideo, setShowVideo] = useState(false); // State to manage video visibility
  const [videoTimeout, setVideoTimeout] = useState(null); // State to manage timeout ID

  useEffect(() => {
    // Set title
    setTitle(media.title || media.name || media.mediaTitle);

    // Determine poster path and hover image
    const posterPathURL = tmdbConfigs.posterPath(
      media.poster_path || media.backdrop_path || media.mediaPoster || media.profile_path || ''
    );
    const hoverImageURL = tmdbConfigs.posterPath(
      media.backdrop_path || media.poster_path || ''
    );

    setPosterPath(posterPathURL);
    setHoverImage(hoverImageURL);

    // Preload poster image
    const preloadImage = new Image();
    preloadImage.src = posterPathURL;

    // Set video URL
    setVideoUrl(
      media.videoThumbnailUrl
        ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${media.videoThumbnailUrl}`
        : "https://raw.githubusercontent.com/DeshmukhSahil/Githubvideo/main/placeholderv.mp4"
    );

    // Set other media data
    setReleaseDate(
      mediaType === tmdbConfigs.mediaType.movie
        ? media.release_date?.split("-")[0]
        : media.first_air_date?.split("-")[0]
    );

    setRate(media.vote_average || media.mediaRate);
    setGenres(Array.isArray(media.genre) ? media.genre.map(genre => (typeof genre === 'string' ? genre : genre.name)) : []);
    setOverview(media.overview || "No overview available.");
  }, [media, mediaType]);

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => setShowVideo(true), 3000);
    setVideoTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(videoTimeout);
    setShowVideo(false);
  };

  return (
    <Link
      to={mediaType !== "people" ? routesGen.mediaDetail(mediaType, media.mediaId || media._id) : routesGen.person(media._id)}
      style={{ textDecoration: 'none', }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          overflow: 'hidden',
          backgroundColor: 'transparent',
          transition: 'transform 0.3s ease',
          '&:hover': {
            backgroundColor: 'black', // Black background on hover
            boxShadow: 10,
            transform: 'translateY(-3vh)',
            [theme.breakpoints.down('md')]: {
              transform: 'none',
              boxShadow: 'none',
            },
            '.cardMedia': {
              opacity: showVideo ? 0 : 0,
            },
            '.hoverMedia': {
              opacity: showVideo ? 0 : 1,
            },
            '.videoPlayer': {
              opacity: showVideo ? 1 : 0,
              transition: 'opacity 0.3s ease',
            },
            '.cardContent': {
              opacity: 1,
            }
          },
          [theme.breakpoints.down('md')]: {
            '&:hover': {
              transform: 'none',
              boxShadow: 'none',
            },
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            position: 'relative',
            paddingTop: '20vh',
            paddingBottom: '100%',
            overflow: 'hidden',
            borderRadius: 3,
            [theme.breakpoints.down('sm')]: {
              paddingTop: '0',
            },
          }}
        >
          {/* Image Container with BorderRadius */}
          <Box
            sx={{
              position: 'absolute',
              padding: 0,
              top: 50,
              left: 0,
              right: 0,
              bottom: 50,
              overflow: 'hidden',  // Clipping the image content
              borderRadius: '20px',  // Apply borderRadius here to the image container
              // border:'2px solid red'
            }}
          >
            <CardMedia
              component="img"
              image={posterPath}
              alt={title}
              className="cardMedia"
              loading="eager" // Set to eager for immediate loading
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transform: 'translateY(2vh)',
                transition: 'transform 5.1s ease, opacity 0.3s ease',
                borderRadius: 'inherit',
                [theme.breakpoints.down('md')]: {
                  transform: 'none',
                  transition: 'none',
                },
              }}
            />

          </Box>
          <CardMedia
            component="img"
            image={hoverImage}
            alt={title}
            className="hoverMedia"
            loading="lazy"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              maxHeight: '26vh',
              opacity: 0,
              transition: 'opacity 0.3s ease',
              // borderRadius: 'inherit',
            }}
          />
          {showVideo && videoUrl && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 2,
                width: '100%',
                height: '27vh',
                overflow: 'hidden',
                zIndex: 2,
                // borderRadius: 'inherit',
              }}
              className="videoPlayer"
            >
              <video
                src={videoUrl}
                autoPlay
                muted
                style={{
                  width: '99%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: 'inherit',
                }}
                controls={false} // This hides all controls, including the download button
              />
              {/* Extra Space for Overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  background: 'linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(128, 128, 128, 0.5), rgba(0, 0, 0, 0.9),  rgba(0, 0, 0, 0.9))', // Grey to black gradient
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between', // Space between logo and text
                  padding: '8px 16px', // Add padding for better spacing
                  zIndex: 3,
                  borderRadius: '0 0 12px 12px', // Rounds the bottom corners
                }}
              >
                {/* Profile Picture */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px', // Space between profile picture and text
                  }}
                >
                  <Box
                    component="img"
                    src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${media.uploadedBy.profilePicture}`}
                    alt={`${media.uploadedBy.firstName} ${media.uploadedBy.lastName}`}
                    sx={{
                      width: '25px',
                      height: '25px',
                      borderRadius: '50%', // Circular profile picture
                      objectFit: 'cover',
                      border: '2px solid white', // White border around the profile picture
                    }}
                  />
                  {/* Text */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontStyle: 'italic',
                      fontSize: '12px',
                      display: 'flex',
                      flexDirection: 'column', // Stack content vertically
                    }}
                  >
                    <span>
                      {media.uploadedBy.firstName} {media.uploadedBy.lastName}
                    </span>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'silver', // Silver color for "Production House"
                        fontSize: '10px',
                        marginTop: '0px', // Small space between the lines
                      }}
                    >
                      Production House
                    </Typography>
                  </Typography>

                </Box>
              </Box>

            </Box>
          )}
        </Box>
        <CardContent
          className="cardContent"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 1,
            background: 'rgba(0, 0, 0, 1)',
            color: 'white',
            opacity: 0,
            height: isMediaGrid ? '60%' : '40%',
            transition: 'opacity 0.3s ease',
            // [theme.breakpoints.down('md')]: {
            //   display: 'none',
            // },
          }}
        >
          <Box mt={0}>
            <button
              style={{
                backgroundImage: "linear-gradient(to right, #780206, #061161)",
                color: "white",
                padding: '2px',
                borderRadius: "5px",
                border: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                marginBottom: '7px',
                minWidth: '100%',  /// Initially width 65%
                zIndex: 20,
                maxWidth: '150px',
                [theme.breakpoints.down('sm')]: {
                  padding: '6px 12px',
                  fontSize: '12px',
                },
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#005BB5'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#0073E6'}
            >
              Watch Now
            </button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {releaseDate} - {genres.join(', ')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 2 }}
            >
              ⭐️ {rate}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              maxHeight: '12em',
              overflow: 'hidden',
              fontSize: '0.8rem',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 7,
              WebkitBoxOrient: 'vertical',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
              },
            }}
          >
            {overview}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default MediaItem;
