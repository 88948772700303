import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Card, CardContent, CardActions, IconButton, Typography, Avatar, InputBase, Divider, List, ListItem, ListItemAvatar, ListItemText, Button, Paper, Chip
} from '@mui/material';
import { Favorite, Share, Delete, Reply, Comment } from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Postcommunity = ({ posts, setPosts }) => {
  const [inputText, setInputText] = useState('');
  const [showComments, setShowComments] = useState({});
  const [activeReply, setActiveReply] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [commentLimit, setCommentLimit] = useState(2); // Limit to 2 comments
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [showReplies, setShowReplies] = useState({}); // Track visible replies for comments
  const replyInputRefs = useRef({}); // Store refs for reply inputs
  const [mentionSuggestions, setMentionSuggestions] = useState([]); // Suggested usernames
  const [allUsernames, setAllUsernames] = useState([]); // List of all usernames
  const [mentionStart, setMentionStart] = useState(-1); // Position of the '@' in the input


  // Create a reference for the comment section
  // const commentSectionRef = useRef(null);

  // Initialize filteredPosts when posts are received or updated
  useEffect(() => {
    setFilteredPosts(posts);
  }, [posts]);

  useEffect(() => {
    if (posts?.length > 0) {
      const usernames = new Set(); // To avoid duplicates
      posts.forEach((post) => {
        post.comments.forEach((comment) => {
          usernames.add(comment.user?.firstName + ' ' + comment.user?.lastName);
          comment.replies?.forEach((reply) => {
            usernames.add(reply.user?.firstName + ' ' + reply.user?.lastName);
          });
        });
      });
      setAllUsernames([...usernames]);
    }
  }, [posts]);


  const toggleRepliesVisibility = (commentId) => {
    setShowReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleLike = async (postId) => {
    try {
      const response = await axios.put(
        `https://thecameratales.com/api/admin/posts/${postId}/like`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update both posts and filteredPosts with the updated post
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleCommentLike = async (postId, commentId) => {
    try {
      const response = await axios.put(
        `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/like`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update both posts and filteredPosts with the updated post
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post)));
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleShare = (postId) => {
    const shareableUrl = `https://thecameratales.com/sharedpost/${postId}`;

    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator.share({
        title: 'Check out this post!',
        url: shareableUrl,
      })
        .then(() => console.log('Post shared successfully!'))
        .catch((error) => console.error('Error sharing post:', error));
    } else {
      // Fallback to copying the URL to the clipboard
      navigator.clipboard.writeText(shareableUrl)
        .then(() => {
          alert('Post link copied to clipboard!');
        })
        .catch((error) => {
          console.error('Error copying link:', error);
        });
    }
  };

  const highlightMentions = (text) => {
    const mentionRegex = /@(\w+)/g; // Matches mentions starting with '@'
    const parts = text.split(mentionRegex);

    return parts.map((part, index) => {
      if (mentionRegex.test(`@${part}`)) {
        return (
          <span key={index} style={{ color: 'blue' }}>
            @{part}
          </span>
        );
      }
      return part;
    });
  };


  const handleInputChange = (event, isReply = false, commentId = null) => {
    const value = event.target.value;
    const cursorPosition = event.target.selectionStart;

    // Detect '@' and start filtering usernames
    if (value[cursorPosition - 1] === '@') {
      setMentionStart(cursorPosition - 1); // Save the position of '@'
    } else if (mentionStart >= 0) {
      const mentionText = value.substring(mentionStart + 1, cursorPosition).toLowerCase();

      // Stop suggestions if the user deletes '@'
      if (!mentionText) {
        setMentionSuggestions([]);
      } else {
        const suggestions = allUsernames.filter((username) =>
          username.toLowerCase().startsWith(mentionText)
        );
        setMentionSuggestions(suggestions);
      }
    }

    // Clear suggestions if '@' is removed
    if (!value.includes('@')) {
      setMentionStart(-1);
      setMentionSuggestions([]);
    }

    if (isReply) {
      setReplyText(value); // Update reply text
    } else {
      setInputText(value); // Update comment text
    }
  };


  const handleMentionSelect = (username, isReply = false) => {
    const currentText = isReply ? replyText : inputText;
    const mentionText = currentText.substring(0, mentionStart) + `@${username} `;
    isReply ? setReplyText(mentionText) : setInputText(mentionText);

    // Reset suggestions
    setMentionStart(-1);
    setMentionSuggestions([]);
  };



  const toggleCommentsVisibility = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
    // Reset comment limit when toggling comments
    if (!showComments[postId]) setCommentLimit(2);
  };

  const handleComment = async (postId) => {
    if (!loggedInUser) {
      alert('Please log in to comment.');
      return;
    }

    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comment`,
        { text: inputText },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));

      setInputText('');
    } catch (error) {
      console.error('Error commenting:', error);
    }
  };

  const handleReplyIconClick = (commentId) => {
    setActiveReply(commentId); // Open the reply input for the specific comment

    // Scroll to the reply input field
    if (replyInputRefs.current[commentId]) {
      replyInputRefs.current[commentId].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      replyInputRefs.current[commentId].focus(); // Focus on the input field
    }
  };

  const handleReply = async (postId, commentId) => {
    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/reply`,
        { text: replyText },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update posts and filteredPosts
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));

      setReplyText(''); // Clear input after reply is added
      setActiveReply(null); // Close the reply input
    } catch (error) {
      console.error('Error replying:', error);
    }
  };

  const loadMoreComments = () => {
    setCommentLimit((prev) => prev + 200); // Load 2 more comments each time
  };

  // Handle canceling the reply
  const cancelReply = () => {
    setActiveReply(null);
    setReplyText('');
    setInputText(''); // Reset the comment input field if needed
  };

  return (
    <Box sx={{ backgroundColor: '#1a1a1a', padding: '16px', minHeight: '100vh' }}>
      {filteredPosts.map((post) => (
        <Card key={post._id} sx={{ marginBottom: '16px', backgroundColor: '#2c2c2c', color: '#fff' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Avatar alt={post.createdBy.firstName} src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.createdBy.profilePicture}`} />
              <Link to={`/users/${post.createdBy._id}`} style={{ textDecoration: 'none' }}>
                <Box ml={2}>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    {post.createdBy.firstName} {post.createdBy.lastName}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#b3b3b3' }}>
                    {new Date(post.date).toLocaleString()}
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Typography sx={{ marginTop: '12px', color: '#fff' }}>{post.caption}</Typography>

            {/* Render Media */}
            {post.mediaType === 'image' && (
              <img
                src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
                alt={post.caption}
                style={{ width: '100%', marginTop: '12px', borderRadius: '8px' }}
              />
            )}

            {/* Render Video */}
            {post.mediaType === 'video' && (
              <video
                controls
                controlsList="nodownload"
                style={{
                  width: '100%',
                  marginTop: '12px',
                  borderRadius: '8px',
                }}
              >
                <source
                  src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}


            {/* Render categories */}
            {post.categories?.length > 0 && (
              <Box sx={{ marginTop: '8px' }}>
                {post.categories.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    sx={{ marginRight: '8px', backgroundColor: '#3c3c3c', color: '#fff' }}
                  />
                ))}
              </Box>
            )}
          </CardContent>

          <CardActions>
            <IconButton onClick={() => handleLike(post._id)}>
              <Favorite
                sx={{
                  color: post.likes?.includes(loggedInUser?.id) ? '#ff1744' : '#fff' // If user liked, color red, else white
                }}
              />
            </IconButton>
            <Typography sx={{ color: '#fff' }}>{post.likes?.length || 0}</Typography>

            <IconButton onClick={() => toggleCommentsVisibility(post._id)}>
              <Comment sx={{ color: '#fff' }} />
            </IconButton>

            <IconButton onClick={() => handleShare(post._id)}>
              <Share sx={{ color: '#fff' }} />
            </IconButton>

            {loggedInUser?.role === 'admin' && (
              <IconButton>
                <Delete sx={{ color: '#fff' }} />
              </IconButton>
            )}
          </CardActions>
          {/* Comments Section */}
          {showComments[post._id] && (
            <Box sx={{ padding: '16px', backgroundColor: '#1e1e1e', borderRadius: '12px', marginTop: '16px' }}>
              <Divider sx={{ backgroundColor: '#4f4f4f', marginBottom: '16px' }} />

              <List sx={{ maxHeight: '250px', overflowY: 'auto', padding: 0 }}>
                {Array.isArray(post.comments) && post.comments.length > 0 ? (
                  post.comments
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, commentLimit)
                    .map((comment) => (
                      <Box key={comment._id} sx={{ backgroundColor: '#333', borderRadius: '8px', marginBottom: '8px', padding: '8px' }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              src={
                                comment.user?.profilePicture
                                  ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${comment.user.profilePicture}`
                                  : ''
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={comment.user ? `${comment.user.firstName} ${comment.user.lastName}` : 'Unknown User'}
                            secondary={<Typography sx={{ color: '#fff' }}>{comment.text}</Typography>}
                            sx={{ color: '#fff' }}
                          />
                          <Box>
                            <IconButton onClick={() => handleCommentLike(post._id, comment._id)}>
                              <Favorite sx={{ color: comment.likes?.includes(loggedInUser?.id) ? '#ff1744' : '#fff' }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setActiveReply(comment._id);
                                handleReplyIconClick(comment._id); // Call the second function
                              }}
                            >
                              <Reply sx={{ color: activeReply === comment._id ? '#1976d2' : '#fff' }} />
                            </IconButton>
                          </Box>
                        </ListItem>

                        {/* Toggle Replies */}
                        {comment.replies?.length > 0 && (
                          <Button
                            onClick={() => toggleRepliesVisibility(comment._id)}
                            sx={{ color: '#fff', textTransform: 'none' }}
                          >
                            {showReplies[comment._id] ? 'Hide Replies' : 'Show Replies'}
                          </Button>
                        )}

                        {/* Render replies for the comment */}
                        {Array.isArray(comment.replies) && comment.replies.length > 0 && (
                          <div>
                            {showReplies[comment._id] && comment.replies?.length > 0 && (
                              <Box sx={{ marginLeft: '48px', marginTop: '8px' }}>
                                {comment.replies.map((reply) => (
                                  <ListItem key={reply._id} sx={{ paddingLeft: '16px', borderLeft: '2px solid #444', marginBottom: '8px' }}>
                                    <ListItemAvatar>
                                      <Avatar
                                        src={
                                          reply.user?.profilePicture
                                            ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${reply.user.profilePicture}`
                                            : ''
                                        }
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={reply.user ? `${reply.user.firstName} ${reply.user.lastName}` : 'Unknown User'}
                                      secondary={<Typography sx={{ color: '#fff' }}>{reply.text}</Typography>}
                                      sx={{ color: '#fff' }}
                                    />
                                  </ListItem>
                                ))}
                              </Box>
                            )}
                          </div>
                        )}




                        {/* Add reply input */}
                        {activeReply === comment._id && (
                          <Box sx={{ marginLeft: '48px', marginTop: '8px' }}>
                            {mentionSuggestions.length > 0 && (
                              <Paper
                                elevation={4}
                                style={{
                                  position: 'absolute',
                                  marginTop: '40px',
                                  zIndex: 10,
                                  backgroundColor: '#333',
                                  color: '#fff',
                                  padding: '8px',
                                  borderRadius: '4px',
                                }}
                              >
                                {mentionSuggestions.map((username, index) => (
                                  <Typography
                                    key={index}
                                    onClick={() => handleMentionSelect(username, activeReply !== null)}
                                    style={{
                                      padding: '4px',
                                      cursor: 'pointer',
                                      backgroundColor: index % 2 === 0 ? '#444' : 'transparent',
                                    }}
                                  >
                                    {username}
                                  </Typography>
                                ))}
                              </Paper>
                            )}
                            <InputBase
                              value={replyText}
                              onChange={(e) => {
                                setReplyText(e.target.value); // Update the replyText state
                                handleInputChange(e, comment._id) // Execute additional logic
                              }}
                              placeholder="Write a reply..."
                              sx={{
                                backgroundColor: '#444',
                                padding: '8px 16px',
                                borderRadius: '8px',
                                color: '#fff',
                                width: '100%',
                              }}
                              multiline
                            />
                            <Button
                              onClick={() => handleReply(post._id, comment._id)}
                              variant="contained"
                              sx={{ marginTop: '8px', float: 'right' }}
                            >
                              Post Reply
                            </Button>
                          </Box>
                        )}
                      </Box>
                    ))
                ) : (
                  <Typography sx={{ color: '#b3b3b3' }}>No comments yet.</Typography>
                )}
              </List>


              {/* Load more comments */}
              {post.comments?.length > commentLimit && (
                <Button onClick={loadMoreComments} sx={{ color: '#ff1744', textTransform: 'none' }}>
                  Load More Comments
                </Button>
              )}

              {/* Comment Input */}
              <Box sx={{ marginTop: '16px' }}>
                <InputBase
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Write a comment..."
                  sx={{
                    backgroundColor: '#333',
                    borderRadius: '8px',
                    padding: '8px',
                    width: '100%',
                    color: '#fff',
                  }}
                />
                <Button
                  onClick={() => handleComment(post._id)}
                  sx={{
                    backgroundColor: '#ff1744',
                    color: '#fff',
                    marginTop: '8px',
                    textTransform: 'none',
                  }}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          )}

        </Card>
      ))}
    </Box>
  );
};

export default Postcommunity;
