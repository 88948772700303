import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, CardMedia, IconButton, Box } from '@mui/material';

const RelatedArtists = () => {
    const { userId } = useParams(); // Get the user ID from the URL
    const [user, setUser] = useState(null);
    const [superuser, setSuperuser] = useState(null);
    const [relatedArtists, setRelatedArtists] = useState([]);

    // Fetch user data by ID from the URL
    const fetchUserData = async () => {
        try {
            const { data } = await axios.get(`https://thecameratales.com/api/admin/users/${userId}`);
            setUser(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // Fetch superuser data
    const fetchSuperuserData = async () => {
        try {
            const { data } = await axios.get('https://thecameratales.com/api/admin/superusers');
            setSuperuser(data);
        } catch (error) {
            console.error("Error fetching superuser data:", error);
        }
    };

    const getRelatedArtists = () => {
        if (user && superuser) {
            const userCategories = Array.isArray(user.categories) && user.categories.length > 0 ? user.categories : ['General'];
            const superuserArray = Array.isArray(superuser) ? superuser : [superuser];

            // Filter superusers based on category match
            const relatedArtistsData = superuserArray.filter(artist =>
                Array.isArray(artist.categories) &&
                artist.categories.some(artistCategory =>
                    userCategories.some(userCategory => userCategory.toLowerCase() === artistCategory.toLowerCase())
                ) && artist._id !== userId // Exclude the current user
            );

            // Select random artists if no direct matches
            if (relatedArtistsData.length === 0) {
                const filteredSuperusers = superuserArray.filter(artist => artist._id !== userId);
                const randomArtists = filteredSuperusers.sort(() => 0.5 - Math.random()).slice(0, 4);
                setRelatedArtists(randomArtists);
            } else {
                setRelatedArtists(relatedArtistsData.slice(0, 4)); // Limit to 4 artists
            }
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchUserData();
        fetchSuperuserData();
    }, [userId]);

    // Run category matching after data is fetched
    useEffect(() => {
        if (user && superuser) {
            getRelatedArtists();
        }
    }, [user, superuser]);

    return (
        <Box sx={{ width: '100%', padding: '2rem', backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '8px' }}>
            <Typography variant="h5" sx={{ color: 'white', mb: 4, textAlign: 'center' }}>
                People who search for this profile also search
            </Typography>

            {relatedArtists.length === 0 ? (
                <Typography sx={{ color: '#A9A9A9', textAlign: 'center' }}>
                    No related artists found.
                </Typography>
            ) : (
                <Grid container spacing={4} justifyContent="center">
                    {relatedArtists.map((artist, index) => {
                        const imageUrl = artist.profilePicture
                            ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${artist.profilePicture}`
                            : 'https://via.placeholder.com/200';

                        return (
                            <Grid item xs={6} sm={6} key={index}>
                                <Card sx={{ backgroundColor: '#2c2f33', borderRadius: 2, boxShadow: 3, maxHeight:'20vh' }}>
                                    <Link to={`/users/${artist._id}`} style={{ textDecoration: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={imageUrl}
                                        alt={`${artist.firstName} ${artist.lastName}`}
                                        sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, objectPosition: 'center', }}
                                    />
                                        </Link>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
};

export default RelatedArtists;
