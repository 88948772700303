import { Grid, List } from "@mui/material";
import MediaItemTwo from "./MediaItemtwo";
import PeopleItem from "./MediaPeopleItem"; // Create a new component for people

const MediaGrid = ({ medias, mediaType }) => {
  if (mediaType === "people") {
    // Display people in a single full-width list
    return (
      <List sx={{ width: "100%", padding: 0, backgroundColor: "white" }}>
        {medias.map((media, index) => (
          <PeopleItem key={index} media={media} />
        ))}
      </List>
    );
  }

  // Default grid layout for other media types
  return (
    <Grid
      container
      spacing={1}
      sx={{ marginRight: "-8px!important", overflow: "hidden", paddingLeft:'5vw', paddingRight:'5vw' }}
    >
      {medias.map((media, index) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          xl={2}
          key={index}
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <MediaItemTwo media={media} mediaType={mediaType} isMediaGrid />
        </Grid>
      ))}
    </Grid>
  );
};

export default MediaGrid;
