import { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import mediaApi from "../../api/modules/media.api";
import AutoSwiper from "./AutoSwiper";
import { toast } from "react-toastify";
import MediaItem from "./MediaItem";
import MediaItemTwo from "./MediaItemtwo";
import { Box, useTheme, useMediaQuery, Skeleton } from "@mui/material";
import { getAllFavorites } from '../../api/modules/favorite.api';
import { getViewedMedia } from '../../api/modules/viewed.api';

const MediaSlide = ({ mediaType, mediaCategory, userId }) => {
  const [medias, setMedias] = useState([]);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery('(max-width: 600px) and (max-height: 858px)');

  useEffect(() => {
    const getMedias = async () => {
      let response, err;

      if (mediaType === "favorites") {
        ({ response, err } = await getAllFavorites(userId));
      } else if (mediaType === "viewed") {
        try {
          response = await getViewedMedia(userId);
        } catch (error) {
          err = error;
        }
      } else {
        ({ response, err } = await mediaApi.getList({
          mediaType,
          mediaCategory,
          page: 1
        }));
      }

      if (response) {
        const data = response.data || response;
        setMedias(data); // Set the state with the data
      }

      if (err) {
        toast.error(err.message);
      }
    };

    getMedias();
  }, [mediaType, mediaCategory, userId]);

  return (
    <AutoSwiper>
      {medias.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Skeleton variant="rectangular" width="80vw" height="60vh" />
        </Box>
      ) : (
        medias
          .filter((media) => media) // Filter out null or undefined items
          .map((media, index) => (
            <SwiperSlide
              key={index}
              style={{
                zIndex: 5,
                transition: 'transform 0.3s ease, z-index 0.3s ease',
                [theme.breakpoints.down('md')]: {
                  transition: 'none',
                },
                [theme.breakpoints.down('sm')]: { 
                  width: '90vw',
                },
              }}
              onMouseEnter={(e) => {
                if (window.innerWidth >= 900) {
                  e.currentTarget.style.zIndex = 10;
                  e.currentTarget.style.transform = 'scale(1.1)';
                }
              }}
              onMouseLeave={(e) => {
                if (window.innerWidth >= 900) {
                  e.currentTarget.style.zIndex = 5;
                  e.currentTarget.style.transform = 'scale(1)';
                }
              }}
            >
              {isSmallScreen ? (
                <MediaItemTwo
                  media={media}
                  mediaType={mediaType === "favorites" || mediaType === "viewed" ? media?.type : mediaType}
                />
              ) : (
                <MediaItem
                  media={media}
                  mediaType={mediaType === "favorites" || mediaType === "viewed" ? media?.type : mediaType}
                />
              )}
            </SwiperSlide>
          ))
      )}
    </AutoSwiper>
  );
};

export default MediaSlide;
