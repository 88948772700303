import { Box, Button, Chip, Divider, Grid, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { toast } from "react-toastify";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { setGlobalLoading } from "../../redux/features/globalLoadingSlice";
import { routesGen } from "../../routes/routes";

import uiConfigs from "../../configs/ui.configs";

import CircularRate from "./CircularRate";

import tmdbConfigs from "../../api/configs/tmdb.configs";
import mediaApi from "../../api/modules/media.api";

const HeroSlide = ({ mediaType, mediaCategory, sx }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [movies, setMovies] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const [overviewPage, setOverviewPage] = useState(0); // Track pagination for the overview

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setGlobalLoading(true));
        const { response: mediaResponse, err: mediaError } = await mediaApi.getList({ mediaType, mediaCategory, page: 1 });
        if (mediaError) throw new Error(mediaError.message);
        setMovies(mediaResponse.data);
      } catch (error) {
        toast.error(error.message);
      } finally {
        dispatch(setGlobalLoading(false));
      }
    };

    fetchData();
  }, [mediaType, mediaCategory, dispatch]);

  const handleOverviewChange = (direction) => {
    setOverviewPage((prev) => Math.max(0, prev + direction));
  };

  return (
    <Box sx={{ position: "relative", color: "primary.contrastText", marginBottom:"5vh", borderBottom:"0.5px solid white", borderTop:"0.5px solid white", ...sx }}>
      <Swiper
        modules={[Autoplay]}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={{ width: "100%", height: "max-content" }}
      >
        {movies.map((movie, index) => {
          const overviewChunks = movie.overview
            ? movie.overview.match(/(.|[\r\n]){1,300}/g) // Split overview into chunks
            : ["No description available."];

          const movieBackdropUrl = tmdbConfigs.backdropPath(
            isSmallScreen && movie.backdropsmall_path
              ? movie.backdropsmall_path
              : movie.backdrop_path || movie.poster_path
          );

          return (
            <SwiperSlide key={index}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    zIndex: 3,
                    pointerEvents: "none",
                  }}
                />
                <Box
                  sx={{
                    paddingTop: { xs: "130%", sm: "80%", md: "60%", lg: "45%" },
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundImage: `url(${movieBackdropUrl})`,
                    zIndex: 2,
                    // Lazy load background image using 'loading' attribute
                    loading: "lazy",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    paddingX: { xs: "10px", sm: "20px", md: "5rem", lg: "10rem" },
                    zIndex: 6,
                    ...uiConfigs.style.horizontalGradientBgImage[theme.palette.mode],
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <Stack
                        spacing={4}
                        direction="column"
                        sx={{
                          border: "0.5px solid white",
                          borderTopLeftRadius:"20px",
                          borderBottomRightRadius:"20px",
                          padding: "10px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          width: { xs: "80%", sm: "80%", md: "80%", lg: "80%" },
                          margin: "auto",
                        }}
                      >
                        <Typography
                          variant="h4"
                          fontSize={{
                            xs: "1.2rem",
                            sm: "1.5rem",
                            md: "2rem",
                            lg: "3rem",
                          }}
                          fontWeight="700"
                          sx={{
                            ...uiConfigs.style.typoLines(2, "left"),
                            color: "transparent",
                            backgroundImage:
                              "linear-gradient(to right, #F7971E 0%, #FFD200  51%, #F7971E  100%)",
                            WebkitBackgroundClip: "text",
                          }}
                        >
                          {movie.title || movie.name}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <CircularRate value={movie.vote_average} />
                          <Divider orientation="vertical" />
                          {movie.genre &&
                            movie.genre.map((genre, index) => (
                              <Chip
                                key={index}
                                label={genre}
                                sx={{
                                  backgroundColor: "purple",
                                  backgroundImage:
                                    "linear-gradient(to right, #780206, #061161)",
                                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                                  fontWeight: "bolder",
                                  color: "white",
                                }}
                              />
                            ))}
                        </Stack>
                        <Stack spacing={2} direction="column">
                          <Typography
                            variant="body1"
                            sx={{
                              color: "white",
                              position: "relative",
                              zIndex: 6,
                              display: "-webkit-box",
                              WebkitLineClamp: isSmallScreen ? 3 : "none",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <b>
                              <i>{overviewChunks[overviewPage]}</i>
                            </b>
                          </Typography>
                        </Stack>
                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<PlayArrowIcon />}
                          component={Link}
                          to={routesGen.mediaDetail(mediaType, movie._id)}
                          sx={{
                            width: "max-content",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                            borderRadius: "50px",
                            zIndex: 6,
                            backgroundColor: "purple",
                            backgroundImage:
                              "linear-gradient(to right, #780206, #061161)",
                          }}
                        >
                          Watch Now
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default HeroSlide;
