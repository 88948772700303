import { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase/firebaseconfig";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseconfig";
import { UserContext } from "../../../Context/UserContext";
import { getDoc} from "firebase/firestore";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const handleChange = ({ currentTarget: input }) => {
    console.log("Input Changed:", input.name, input.value);
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting login form with data:", data);
    try {
      const url = `${apiBaseURL}/api/auth`;
      const { data: res } = await axios.post(url, data);
      console.log("Login response:", res);
      login(res.user);
      localStorage.setItem("token", res.token);
      navigate("/"); 
      window.location.reload(); 
    } catch (error) {
      console.error("Error during login:", error);
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    }
  };

  const handleGoogleSignIn = async () => {
    console.log("Initiating Google Sign-In...");
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider); // Use popup instead of redirect
      const user = result.user;
      console.log("Google Sign-In successful:", user);
  
      const idToken = await user.getIdToken();
      console.log("User ID Token:", idToken);
  
      // Check if the user exists in MongoDB
      const response = await fetch(`https://thecameratales.com/api/auth/${user.uid}`); // Assuming you've set up a route in Express to get a user by Google UID
      const mongoUser = await response.json();
  
      let role = "default-role"; // Fallback role if none exists
      let id = user.uid;
  
      if (mongoUser && mongoUser.user) {
        // If user exists in MongoDB, get the role and id from there
        role = mongoUser.user.role || "default-role"; // Use the role from MongoDB if available
        id = mongoUser.user.id; // Get MongoDB ID
      } else {
        // If user doesn't exist in MongoDB, create a new user entry
        const userData = {
          id: user.uid,
          firstName: user.displayName.split(" ")[0],
          lastName: user.displayName.split(" ")[1] || "",
          email: user.email,
          role: role, // Dynamically assigned role
          subscription: { status: "inactive" }, // Default subscription status
        };
  
        // Save user data to MongoDB
        await fetch("https://thecameratales.com/api/auth/google", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        });
        console.log("New user data saved to MongoDB.");
      }
  
      // Map Google user data to your desired structure
      const userData = {
        id, // MongoDB ID or Google UID
        firstName: user.displayName.split(" ")[0],
        lastName: user.displayName.split(" ")[1] || "",
        email: user.email,
        role, // Role fetched from MongoDB
        subscription: { status: "inactive" }, // Default subscription status
      };
  
      console.log("Formatted User Data:", userData);
  
      // Save user data to Firestore if not already stored
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        await setDoc(userDocRef, userData);
        console.log("User data saved to Firestore.");
      }
  
      // Save the token in localStorage and the formatted user data
      localStorage.setItem("token", idToken);
      localStorage.setItem("user", JSON.stringify(userData));
  
      // Log in the user in context
      login(userData);
      navigate("/Main");
      window.location.reload(); // Optional: You may not need this if using the popup flow
  
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      setError("Error signing in with Google");
    }
  };
  

  return (
    <div className={styles.login_container}>
      <div className={styles.surround_login_container}>
        <div className={styles.login_form_container}>
          <div className={styles.left}>
            <form className={styles.form_container} onSubmit={handleSubmit}>
              <h1>Login to Your Account</h1>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={data.email}
                required
                className={styles.input}
              />
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                value={data.password}
                required
                className={styles.input}
              />
              {error && <div className={styles.error_msg}>{error}</div>}
              <button type="submit" className={styles.green_btn}>
                Sign In
              </button>
              <Link to="/forgetpassword" style={{ color: 'blue', textDecoration: 'underline' }}>
                Forgot Password?
              </Link>
            </form>
          </div>
          <div className={styles.right}>
            <h1 style={{ margin: '10px', textAlign: "center" }}>Create account</h1>
            <Link to="/signup">
              <button type="button" className={styles.white_btn}>
                Sign Up
              </button>
            </Link>
            {/* <div>
              <button onClick={handleGoogleSignIn} className={styles.green_btn}>
                Sign In with Google
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
