import React, { useEffect, useState, useCallback } from "react";
import { Modal, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import {
  LinkedIn,
  Instagram,
  Twitter,
  EmojiEvents,
  AccountCircle,
  School,
  Phone,
  Public,
  Home
} from "@mui/icons-material";
import {
  Favorite,
  Comment,
  Share,
  Visibility,
} from "@mui/icons-material"; // Import icons for likes, comments, and shares
// import { FaEye } from 'react-icons/fa';

const CastPortfolio = () => {
  const { castId } = useParams();
  const [isUserCast, setIsUserCast] = useState(false);
  const navigate = useNavigate();

  const [cast, setCast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { mediaType } = useParams();
  const [isFollowing, setIsFollowing] = useState(false); // To track following status
  const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;
  console.log('User from localStorage:', userId); // Check this log
  const token = localStorage.getItem('token');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [thumbnails, setThumbnails] = useState({});

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const placeholderImage = "https://dummyimage.com/300x450/cccccc/ffffff.png&text=No+Image";

  // useEffect(() => {
  //   // Redirect to login if user is not authenticated
  //   if (!userId) {
  //     alert("Please log in to view this page.");
  //     navigate('/login'); // Redirect to login page
  //     return;
  //   }
  // }, [userId, navigate]);

  useEffect(() => {
    const fetchCastDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const { data } = await axios.get(
          `${apiBaseURL}/api/admin/users/${castId}`,
          {
            headers: { 'x-auth-token': token },
          }
        );
        setCast(data);
        // Check if the logged-in user is already following this cast
        const isUserFollowing = data.followers.includes(userId);
        setIsFollowing(isUserFollowing);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCastDetails();
  }, [castId, userId, token]);

  const handleShowFollowers = async () => {
    try {
      const { data } = await axios.get(
        `${apiBaseURL}/api/users/${castId}/followers`,
        { headers: { 'x-auth-token': token } }
      );
      setUserList(data.followers);
      setShowFollowersModal(true);
    } catch (err) {
      console.error("Error fetching followers:", err);
    }
  };

  const handleShowFollowing = async () => {
    try {
      const { data } = await axios.get(
        `${apiBaseURL}/api/users/${castId}/following`,
        { headers: { 'x-auth-token': token } }
      );
      setUserList(data.following);
      setShowFollowingModal(true);
    } catch (err) {
      console.error("Error fetching following:", err);
    }
  };

  const handleRemoveUser = async (targetUserId) => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/unfollow`,
        { targetUserId },
        { headers: { 'x-auth-token': token } }
      );
      setUserList((prevList) => prevList.filter(user => user.id !== targetUserId));
      fetchFollowersAndFollowing();
    } catch (err) {
      console.error("Error removing user:", err);
    }
  };

  useEffect(() => {
    // Check if the logged-in user is the same as the castId
    if (userId === castId) {
      setIsUserCast(true);
    } else {
      setIsUserCast(false);
    }
  }, [castId, userId]);


  const handleFileChangeCustom = (e, mediaId, sectionId, mediaType) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('thumbnail', file);
      formData.append('sectionId', sectionId);  // Include sectionId in the request body

      // API call to upload the thumbnail
      axios.put(`${apiBaseURL}/api/users/${userId}/${mediaType}/${mediaId}/upload-thumbnail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          console.log('Thumbnail uploaded successfully:', response.data);
          // Update your UI or state here if necessary
        })
        .catch(error => {
          console.error('Error uploading thumbnail:', error);
        });
    }
  };


  const handleFileChange = async (e, mediaId, mediaType, sectionId) => {
    const userId = JSON.parse(localStorage.getItem('user')).id;
    console.log('User ID passed:', userId);

    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('thumbnail', file); // Append the thumbnail file

    // Check if mediaType is 'customSections' and append sectionId
    if (mediaType === 'customSections' && sectionId) {
      formData.append('sectionId', sectionId); // Append sectionId
      console.log('Appending sectionId:', sectionId); // Log for debugging
    } else {
      console.log('No sectionId provided'); // Log if no sectionId
    }

    // Log FormData contents for verification
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `${apiBaseURL}/api/users/${userId}/${mediaType}/${mediaId}/upload-thumbnail`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const updatedThumbnail = response.data.thumbnail; // Adjust based on response structure
      setThumbnails((prevThumbnails) => ({
        ...prevThumbnails,
        [mediaId]: updatedThumbnail, // Use mediaId as key for state update
      }));
    } catch (error) {
      console.error("Error uploading thumbnail:", error);
    } finally {
      setLoading(false);
    }
  };




  // Define fetchFollowersAndFollowing using useCallback
  const fetchFollowersAndFollowing = useCallback(async () => {
    try {
      const [followersRes, followingRes] = await Promise.all([
        axios.get(`${apiBaseURL}/api/users/${castId}/followers`, {
          headers: { 'x-auth-token': token },
        }),
        axios.get(`${apiBaseURL}/api/users/${castId}/following`, {
          headers: { 'x-auth-token': token },
        }),
      ]);

      setFollowers(followersRes.data.followers);
      setFollowing(followingRes.data.following);
    } catch (err) {
      console.error("Error fetching followers/following:", err);
    }
  }, [castId, token]);

  useEffect(() => {
    fetchFollowersAndFollowing();
  }, [fetchFollowersAndFollowing]);

  // Handle follow button click
  const handleFollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/follow`,
        { targetUserId: castId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(true); // Update UI after successful follow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error following user:", err);
    }
  };

  // Handle unfollow button click
  const handleUnfollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/unfollow`,
        { targetUserId: castId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(false); // Update UI after successful unfollow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error unfollowing user:", err);
    }
  };

  const handleCloseModal = () => {
    setShowFollowersModal(false);
    setShowFollowingModal(false);
  };

  if (!userId) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: '#121212',
          color: 'white',
          textAlign: 'center',
          padding: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          You need to log in to view this page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/login')}
          sx={{ mt: 2 }}
        >
          Go to Login
        </Button>
      </Box>
    );
  }


  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    cast && (
      <Box
        sx={{
          padding: 3,
          bgcolor: '#121212',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          animation: 'fadeIn 1s ease',
          color: 'white',
          marginTop: "5vh"
        }}
      >
        <Stack spacing={4} sx={{ width: '100%' }}>
          {/* Cast Header */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'center',
              borderBottom: '1px solid #333',
              pb: 2,
            }}
          >
            <Avatar
              src={`${apiBaseURL}/api/uploads/${cast.profilePicture}`}
              alt={`${cast.firstName} ${cast.lastName}`}
              sx={{
                width: 150,
                height: 150,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                border: '3px solid #FFD700',
              }}
            />
            <Box sx={{ textAlign: 'left', ml: 3, flexGrow: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                {`${cast.firstName} ${cast.lastName}`}
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>
                  <span onClick={handleShowFollowers} style={{ cursor: 'pointer' }}>Followers: {followers.length}</span>
                  <span onClick={handleShowFollowing} style={{ margin: '1vw', cursor: 'pointer', color: 'grey' }}>Following: {following.length}</span>
                </Typography>
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Occupation:</strong> {cast.occupation}
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Born:</strong> {new Date(cast.birthDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Address:</strong> {cast.contactInfo.address.city}, {cast.contactInfo.address.country}
              </Typography>
            </Box>
            {/* Modals */}
            <Modal open={showFollowersModal || showFollowingModal} onClose={handleCloseModal}>
              <Box sx={{ bgcolor: '#fff', width: '400px', margin: '10vh auto', padding: '20px', borderRadius: '10px' }}>
                <IconButton sx={{ float: 'right' }} onClick={handleCloseModal}><Close /></IconButton>
                <Typography variant="h6" sx={{ mb: 2 }}>{showFollowersModal ? "Followers" : "Following"}</Typography>
                <List>
                  {(showFollowersModal ? followers : following).map((user, index) => (
                    <ListItem key={index}>
                      <Avatar src={`${apiBaseURL}/api/uploads/${user.profilePicture}`} alt={user.name} sx={{ mr: 2 }} />
                      <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Modal>
            {/* Follow/Unfollow Button */}
            {!isUserCast && (
              <Box>
                {isFollowing ? (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleUnfollow}
                    sx={{ fontSize: "1rem", fontWeight: "bold" }}
                  >
                    Unfollow
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFollow}
                    sx={{ fontSize: "1rem", fontWeight: "bold" }}
                  >
                    Follow
                  </Button>
                )}
              </Box>
            )}
          </Box>

          {/* Biography */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Biography
            </Typography>
            <Typography variant="body1" sx={{ color: '#AAA' }}>
              {cast.biography}
            </Typography>
          </Box>

          {/* Social Links */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 3,
              mt: 2,
              mb: 4,
            }}
          >
            {cast.socialLinks?.map((link, index) => (
              <Tooltip title={link.type} key={index}>
                <IconButton
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#FFD700' },
                    backgroundColor: '#333',
                    borderRadius: '50%',
                    p: 1,
                  }}
                >
                  {link.type === 'LinkedIn' && <LinkedIn />}
                  {link.type === 'Instagram' && <Instagram />}
                  {link.type === 'Twitter' && <Twitter />}
                </IconButton>
              </Tooltip>
            ))}
            {cast.website && (
              <Tooltip title="Website">
                <IconButton
                  href={cast.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#FFD700' },
                    backgroundColor: '#333',
                    borderRadius: '50%',
                    p: 1,
                  }}
                >
                  <Public />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* Awards Section */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              Awards & Achievements
            </Typography>
            <Stack spacing={2} sx={{ mt: 2 }}>
              {cast.awards?.length > 0 ? (
                cast.awards.map((award, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmojiEvents sx={{ mr: 2, color: '#FFD700' }} />
                    <Typography>{`${award.name} - ${award.year}`}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No awards available</Typography>
              )}
            </Stack>
          </Box>

          <Stack>
            <Box>
              {/* <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Social Links:
              </Typography> */}



{/* 
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                {cast.socialLinks && cast.socialLinks.length > 0 ? (
                  cast.socialLinks.map((link, index) => (
                    <Tooltip title={`Visit ${link.type}`} key={index}>
                      <IconButton href={link.link} target="_blank" rel="noopener noreferrer" sx={{
                        transition: 'transform 0.3s',
                        '&:hover': { transform: 'scale(1.2)', color: '#1976d2' },
                        backgroundColor: "grey",
                        // borderRadius: '50%',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        p: 1
                      }}>
                        {link.type === 'LinkedIn' && <LinkedIn />}
                        {link.type === 'Instagram' && <Instagram />}
                        {link.type === 'Twitter' && <Twitter />}
                      </IconButton>
                    </Tooltip>
                  ))
                ) : (
                  <Typography>No social links available</Typography>
                )}
                {cast.website && (
                  <Tooltip title="Website">
                    <IconButton href={cast.website} target="_blank" rel="noopener noreferrer" sx={{
                      transition: 'transform 0.3s',
                      '&:hover': { transform: 'scale(1.2)', color: '#1976d2' },
                      backgroundColor: "grey",
                      borderRadius: '50%',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      p: 1
                    }}>
                      {/* <Public /> */}
                    {/* </IconButton>
                  </Tooltip>
                )}
              </Box> */}
              {cast.imdbProfile && (
                <Tooltip title="IMDB Profile">
                  <IconButton href={cast.imdbProfile} target="_blank" rel="noopener noreferrer" sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#1976d2' },
                    backgroundColor: "grey",
                    borderRadius: '50%',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    // margin:'20px',
                    p: 1
                  }}>
                    <Public />
                  </IconButton>
                </Tooltip>
              )}

              {cast.instagramHandle && (
                <Tooltip title="Instagram">
                  <IconButton href={cast.imdbProfile} target="_blank" rel="noopener noreferrer" sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#1976d2' },
                    backgroundColor: "grey",
                    borderRadius: '50%',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    margin:'20px',
                    p: 1
                  }}>
                    <Instagram />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Stack>

          {/* Movies Section */}
          {cast.movies?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Movies
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.movies?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography variant="body2" color="#FFD700">
                          {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                        </Typography>

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}

          {/* Webseries Section */}
          {cast.webseries?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Webseries
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.webseries?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        {/* <Typography variant="body2" color="#FFD700">
                        {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                      </Typography> */}

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}

          {/* WebShows */}

          {/* Webseries Section */}
          {cast.webshows?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Web Shows
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.webshows?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        {/* <Typography variant="body2" color="#FFD700">
                        {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                      </Typography> */}

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}

          {/* ShortFilms Section */}
          {cast.shortfilms?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Short Films
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.shortfilms?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography variant="body2" color="#FFD700">
                          {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                        </Typography>

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}

          {/* Shows Section */}
          {cast.shows?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Shows
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.shows?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography variant="body2" color="#FFD700">
                          {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                        </Typography>

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}

          {/* Tales Section */}
          {cast.tales?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Tales
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.tales?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography variant="body2" color="#FFD700">
                          {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                        </Typography>

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}


          {/* Upcoming Section */}
          {cast.upcoming?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Upcoming
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 }
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >

                {cast.upcoming?.map(movie => (
                  <SwiperSlide key={movie.mediaId}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={`${apiBaseURL}/api/uploads/${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography variant="body2" color="#FFD700">
                          {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                        </Typography>

                        {/* Display likes, comments, and shares */}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.likes}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.comments.length}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.shares}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                            <Typography variant="body2">{movie.views}</Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}





          {/* new */}

          {/* Copy Section */}
          {cast.moviesAsLead?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Other Movies
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 },
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >
                {cast.moviesAsLead?.map((movie) => (
                  <SwiperSlide key={movie._id}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={movie.thumbnail ? `${apiBaseURL}/api/uploads/thumbnailsusermovies/${movie.thumbnail}` : placeholderImage}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.role1}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          <a
                            href={movie.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#FFD700', textDecoration: 'none', fontWeight: 'bold' }}
                          >
                            Watch Now
                          </a>
                        </Typography>
                        {/* File Input for Thumbnail Upload */}
                        {isUserCast && (
                          <input
                            type="file"
                            accept="image/*"
                            style={{ marginTop: '10px', color: '#FFD700' }}
                            onChange={(e) => handleFileChange(e, movie._id, 'moviesAsLead')}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}










          {/* Music Video Other */}

          {/* Copy Section */}
          {cast.musicAlbums?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Other Music Albums
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 },
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >
                {cast.musicAlbums?.map((movie) => (
                  <SwiperSlide key={movie._id}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={movie.thumbnail ? `${apiBaseURL}/api/uploads/thumbnailsusermovies/${movie.thumbnail}` : placeholderImage}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.role1}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          <a
                            href={movie.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#FFD700', textDecoration: 'none', fontWeight: 'bold' }}
                          >
                            Watch Now
                          </a>
                        </Typography>
                        {/* File Input for Thumbnail Upload */}
                        {isUserCast && (
                          <input
                            type="file"
                            accept="image/*"
                            style={{ marginTop: '10px', color: '#FFD700' }}
                            onChange={(e) => handleFileChange(e, movie._id, 'musicAlbums')}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}





          {/* new documenatries other */}


          {/* Copy Section */}
          {cast.documentaries?.length > 0 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  borderBottom: '2px solid #FFD700',
                  display: 'inline-block',
                }}
              >
                Other Documentaries
              </Typography>
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 2 },
                  600: { slidesPerView: 3 },
                  960: { slidesPerView: 4 },
                  1280: { slidesPerView: 5 },
                }}
                navigation
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                style={{ width: '100%', paddingTop: '15px' }}
              >
                {cast.documentaries?.map((movie) => (
                  <SwiperSlide key={movie._id}>
                    <Card
                      sx={{
                        backgroundColor: '#1c1c1c',
                        borderRadius: 2,
                        boxShadow: 5,
                        '&:hover': {
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={movie.thumbnail ? `${apiBaseURL}/api/uploads/thumbnailsusermovies/${movie.thumbnail}` : placeholderImage}
                        alt={movie.title}
                        sx={{
                          objectFit: 'cover',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          cursor: 'pointer',
                        }}
                      />
                      <CardContent sx={{ color: 'white' }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          {movie.role1}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            mb: 1,
                          }}
                        >
                          <a
                            href={movie.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#FFD700', textDecoration: 'none', fontWeight: 'bold' }}
                          >
                            Watch Now
                          </a>
                        </Typography>
                        {/* File Input for Thumbnail Upload */}
                        {isUserCast && (
                          <input
                            type="file"
                            accept="image/*"
                            style={{ marginTop: '10px', color: '#FFD700' }}
                            onChange={(e) => handleFileChange(e, movie._id, 'documentaries')}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}


          {/* Custom Section */}
          {cast.customSections?.length > 0 && (
            <Box>
              {cast.customSections.map((section) => (
                <Box key={section._id} sx={{ mb: 4 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                      mb: 2,
                      borderBottom: '2px solid #FFD700',
                      display: 'inline-block',
                    }}
                  >
                    {section.sectionTitle}
                  </Typography>
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={4}
                    breakpoints={{
                      0: { slidesPerView: 2 },
                      600: { slidesPerView: 3 },
                      960: { slidesPerView: 4 },
                      1280: { slidesPerView: 5 },
                    }}
                    navigation
                    pagination={{ clickable: true }}
                    modules={[Navigation, Pagination]}
                    style={{ width: '100%', paddingTop: '15px' }}
                  >
                    {section.media?.map((media) => (
                      <SwiperSlide key={media._id}>
                        <Card
                          sx={{
                            backgroundColor: '#1c1c1c',
                            borderRadius: 2,
                            boxShadow: 5,
                            '&:hover': {
                              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="300"
                            image={media.thumbnail ? `${apiBaseURL}/api/uploads/thumbnailsusermovies/${media.thumbnail}` : placeholderImage}
                            alt={media.title}
                            sx={{
                              objectFit: 'cover',
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              cursor: 'pointer',
                            }}
                          />
                          <CardContent sx={{ color: 'white' }}>
                            <Typography variant="h6" component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '1rem', mb: 1 }}>
                              {media.title}
                            </Typography>
                            <Typography variant="h6" component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '1rem', mb: 1 }}>
                              {media.role1}
                            </Typography>
                            <Typography variant="h6" component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '1rem', mb: 1 }}>
                              <a href={media.link} target="_blank" rel="noopener noreferrer" style={{ color: '#FFD700', textDecoration: 'none', fontWeight: 'bold' }}>
                                Watch Now
                              </a>
                            </Typography>
                            {/* File Input for Thumbnail Upload */}
                            {isUserCast && (
                              <input
                                type="file"
                                accept="image/*"
                                style={{ marginTop: '10px', color: '#FFD700' }}
                                onChange={(e) => handleFileChangeCustom(e, media._id, section._id, 'customSections')} // Pass section._id here
                              />
                            )}
                          </CardContent>
                        </Card>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              ))}
            </Box>
          )}















          {/* Peers Section */}
          {cast.peers?.length > 0 && (
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Peers
            </Typography>
            <Swiper
              spaceBetween={15}
              slidesPerView={15}
              breakpoints={{
                0: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                960: { slidesPerView: 4 },
                1280: { slidesPerView: 5 }
              }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              style={{ width: '100%', paddingTop: '15px' }}
            >
              {cast.peers?.map(peer => (
                <SwiperSlide key={peer.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/cast/${castId}`)}
                  >
                    <Avatar
                      src={`${apiBaseURL}/api/uploads/${peer.profilePicture}`}
                      alt={peer.name}
                      sx={{
                        width: 100,
                        height: 100,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                        mb: 1,
                      }}
                    />
                    <Typography variant="body2" color="#FFD700">
                      {peer.name}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          )}

          {/* Family Section */}
          {/* <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Family
            </Typography>
            <Swiper
              spaceBetween={15}
              slidesPerView={15}
              breakpoints={{
                0: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                960: { slidesPerView: 4 },
                1280: { slidesPerView: 5 }
              }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              style={{ width: '100%', paddingTop: '15px' }}
            >
              {cast.family?.map(member => (
                <SwiperSlide key={member.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/cast/${castId}`)}
                  >
                    <Avatar
                      src={`${apiBaseURL}/uploads/${member.profilePicture}`}
                      alt={member.name}
                      sx={{
                        width: 100,
                        height: 100,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                        mb: 1,
                      }}
                    />
                    <Typography variant="body2" color="#FFD700">
                      {member.name}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box> */}

          {/* Profile Button */}
          <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              mt: 3,
              backgroundColor: '#FFD700',
              color: '#121212',
              '&:hover': { backgroundColor: '#FFC107' },
            }}
            onClick={() => navigate(`/users/${castId}`)}
          >
            View Full Profile
          </Button>
        </Stack>
      </Box>
    )
  );
};

export default CastPortfolio;
