import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PeopleItem = ({ media }) => {
  const navigate = useNavigate();

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  // Function to handle list item click
  const handleItemClick = () => {
    navigate(`/users/${media._id}`); // Adjust the route according to your routing setup
  };

  return (
    <ListItem
      onClick={handleItemClick}
      sx={{
        cursor: "pointer",
        borderBottom: "1px solid #e0e0e0",
        padding: "12px 16px",
        backgroundColor: "white",
        '&:hover': {
          backgroundColor: "#f9f9f9",
        },
        width: "100%", // Ensures the item stretches across the full container
        boxSizing: "border-box",
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={media.profilePicture ? `${apiBaseURL}/api/uploads/${media.profilePicture}` : "/default-avatar.png"}
          alt={`${media.firstName} ${media.lastName}`}
          sx={{ width: 56, height: 56, margin:'20px' }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h6" color="textPrimary">
            {media.firstName} {media.lastName}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary">
            {media.categories}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default PeopleItem;
