import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CmsNavbar from './CmsNavbar';
import ImageCropper from './ImageCropper';
import zIndex from '@mui/material/styles/zIndex';
// import { color, minWidth } from '@mui/system';

const CMSMediaForm = () => {
    const [showBackdropCropper, setShowBackdropCropper] = useState(false);
    const [selectedBackdropFile, setSelectedBackdropFile] = useState(null);
    const [showSmallBackdropCropper, setShowSmallBackdropCropper] = useState(false);
    const [selectedSmallBackdropFile, setSelectedSmallBackdropFile] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        overview: '',
        genre: '',
        release_date: '',
        adult: false,
        original_language: '',
        popularity: 5,
        vote_average: 5,
        vote_count: 5,
        type: 'movie', // default type
        video: false,
        poster_path: null,
        backdrop_path: null,
        backdropsmall_path: null,
        video_url: [],
        videoThumbnailUrl: [], // Add video thumbnail here
        userId: '', // Add userId here
        producers: [],
        directors: [],
        writers: [],
        coproducers: [],
        execuetiveproducers: [],
        actors: [],
        productionmanagers: [],
        assistantdirectors: [],
        associatedirectors: [],
        actiondirectors: [],
        ticketPrice: '' // Optional field for ticket price
        // Continue with other dynamic fields (actors, assistant directors, etc.)
    });

    const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
    const [isSubmitting, setIsSubmitting] = useState(false); // State to disable form during upload
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [logMessages, setLogMessages] = useState([]);

    // Retrieve the userId from local storage when the component mounts
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData && userData.id) {
            setFormData((prevData) => ({ ...prevData, userId: userData.id }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Add function to dynamically add more producers, directors, etc.
    const addProducer = () => {
        setFormData({ ...formData, producers: [...formData.producers, { name: '', email: '', profile_picture: null }] });
    };

    const removeProducer = (index) => {
        const updatedProducers = formData.producers.filter((_, i) => i !== index);
        setFormData({ ...formData, producers: updatedProducers });
    };

    // directorrs

    // Add function to dynamically add more producers, directors, etc.
    const addDirector = () => {
        setFormData({ ...formData, directors: [...formData.directors, { name: '', email: '', profile_picture: null }] });
    };

    const removeDirector = (index) => {
        const updatedDirectors = formData.directors.filter((_, i) => i !== index);
        setFormData({ ...formData, directors: updatedDirectors });
    };

    // writers

    // directorrs

    // Add function to dynamically add more producers, directors, etc.
    const addWriter = () => {
        setFormData({ ...formData, writers: [...formData.writers, { name: '', email: '', profile_picture: null }] });
    };

    const removeWriter = (index) => {
        const updatedWriters = formData.writers.filter((_, i) => i !== index);
        setFormData({ ...formData, writers: updatedWriters });
    };



    // Co-Producer

    // Add function to dynamically add more producers, directors, etc.
    const addCoproducer = () => {
        setFormData({ ...formData, coproducers: [...formData.coproducers, { name: '', email: '', profile_picture: null }] });
    };

    const removeCoproducer = (index) => {
        const updatedCoproducers = formData.coproducers.filter((_, i) => i !== index);
        setFormData({ ...formData, coproducers: updatedCoproducers });
    };

    // Execuetive-Producer

    // Add function to dynamically add more producers, directors, etc.
    const addExecuetiveproducer = () => {
        setFormData({ ...formData, execuetiveproducers: [...formData.execuetiveproducers, { name: '', email: '', profile_picture: null }] });
    };

    const removeExecuetiveproducer = (index) => {
        const updatedExecuetiveproducers = formData.execuetiveproducers.filter((_, i) => i !== index);
        setFormData({ ...formData, execuetiveproducers: updatedExecuetiveproducers });
    };

    // Actors

    // Add function to dynamically add more producers, directors, etc.
    const addActor = () => {
        setFormData({ ...formData, actors: [...formData.actors, { name: '', email: '', profile_picture: null }] });
    };

    const removeActor = (index) => {
        const updatedActors = formData.actors.filter((_, i) => i !== index);
        setFormData({ ...formData, actors: updatedActors });
    };

    // Production-Managers

    // Add function to dynamically add more producers, directors, etc.
    const addProductionmanager = () => {
        setFormData({ ...formData, productionmanagers: [...formData.productionmanagers, { name: '', email: '', profile_picture: null }] });
    };

    const removeProductionmanager = (index) => {
        const updatedProductionmanagers = formData.productionmanagers.filter((_, i) => i !== index);
        setFormData({ ...formData, productionmanagers: updatedProductionmanagers });
    };


    // Assistant-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addAssistantdirector = () => {
        setFormData({ ...formData, assistantdirectors: [...formData.assistantdirectors, { name: '', email: '', profile_picture: null }] });
    };

    const removeAssistantdirector = (index) => {
        const updatedAssistantdirectors = formData.assistantdirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, assistantdirectors: updatedAssistantdirectors });
    };


    // Associate-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addAssociatedirector = () => {
        setFormData({ ...formData, associatedirectors: [...formData.associatedirectors, { name: '', email: '', profile_picture: null }] });
    };

    const removeAssociatedirector = (index) => {
        const updatedAssociatedirectors = formData.associatedirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, associatedirectors: updatedAssociatedirectors });
    };

    // Action-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addActiondirector = () => {
        setFormData({ ...formData, actiondirectors: [...formData.actiondirectors, { name: '', email: '', profile_picture: null }] });
    };

    const removeActiondirector = (index) => {
        const updatedActiondirectors = formData.actiondirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, actiondirectors: updatedActiondirectors });
    };



    // Function to handle dynamic field updates like adding producers or directors
    const handleDynamicFieldChange = (e, index, field) => {
        const updatedFields = [...formData[field]];
        updatedFields[index] = {
            ...updatedFields[index],
            [e.target.name]: e.target.value,
        };
        setFormData({ ...formData, [field]: updatedFields });
    };


    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0], // For file uploads
        });
    };

    const handleImgFileChange = (e) => {
        const file = e.target.files[0];

        if (!file) return; // Exit if no file is selected

        // Check if the file size is greater than 400KB (409600 bytes)
        if (file.size > 409600) {
            alert('File size must be less than 400KB');
            e.target.value = null; // Clear the input to reset file selection
            return; // Prevent further processing if the file is too large
        }

        // Proceed if the file size is valid
        setSelectedFile(file); // Set file to show in cropper
        setShowCropper(true);   // Show cropper interface
    };

    // Function to handle the cropped image result
    const handleCropComplete = (croppedImageBlob) => {
        // After cropping, set the cropped image to formData
        setFormData({
            ...formData,
            poster_path: croppedImageBlob, // Assign the cropped image
        });
        setShowCropper(false); // Hide the cropper after cropping
    };

    // Handle Backdrop Image File Selection
    const handleBackdropFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Check if the file size is greater than 400KB (409600 bytes)
        if (file.size > 409600) {
            alert('Backdrop file size must be less than 400KB');
            e.target.value = null;
            return;
        }

        setSelectedBackdropFile(file); // Set file to show in cropper
        setShowBackdropCropper(true);  // Show cropper interface for the backdrop
    };

    // Handle cropped backdrop image
    const handleBackdropCropComplete = (croppedImageBlob) => {
        setFormData({
            ...formData,
            backdrop_path: croppedImageBlob,
        });
        setShowBackdropCropper(false);
    };

    // Handle small backdrop image file selection
    const handleSmallBackdropFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Check if the file size is greater than 400KB (409600 bytes)
        if (file.size > 409600) {
            alert('Backdrop file size must be less than 400KB');
            e.target.value = null;
            return;
        }

        setSelectedSmallBackdropFile(file);
        setShowSmallBackdropCropper(true);
    };

    // Handle cropped small backdrop image
    const handleSmallBackdropCropComplete = (croppedImageBlob) => {
        setFormData({
            ...formData,
            backdropsmall_path: croppedImageBlob,
        });
        setShowSmallBackdropCropper(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable form inputs
        setUploadProgress(0); // Reset upload progress

        const form = new FormData();
        form.append('title', formData.title);
        form.append('overview', formData.overview);
        form.append('genre', formData.genre);
        form.append('release_date', formData.release_date);
        form.append('adult', formData.adult);
        form.append('original_language', formData.original_language);
        form.append('popularity', formData.popularity);
        form.append('vote_average', formData.vote_average);
        form.append('vote_count', formData.vote_count);
        form.append('type', formData.type);
        form.append('video', formData.video);

        // Serialize nested fields as JSON
        form.append('producers', JSON.stringify(formData.producers));
        form.append('directors', JSON.stringify(formData.directors));
        form.append('writers', JSON.stringify(formData.directors));
        form.append('coproducers', JSON.stringify(formData.directors));
        form.append('execuetiveproducers', JSON.stringify(formData.directors));
        form.append('actors', JSON.stringify(formData.directors));
        form.append('productionmanagers', JSON.stringify(formData.directors));
        form.append('assistantdirectors', JSON.stringify(formData.directors));
        form.append('associatedirectors', JSON.stringify(formData.directors));
        form.append('actiondirectors', JSON.stringify(formData.directors));

        // Append thumbnail file if it exists
        // Append thumbnail file if it exists
        if (formData.videoThumbnailUrl) {
            form.append('videoThumbnailUrl', formData.videoThumbnailUrl);
        }

        // Only append ticketPrice if it's not empty
        if (formData.ticketPrice) {
            form.append('ticketPrice', formData.ticketPrice);
        }

        // Append profile pictures for producers
        // formData.producers.forEach((producer, index) => {
        //     if (producer.profile_picture) {
        //         form.append(`producer_profile_picture_${index}`, producer.profile_picture);
        //     }
        // });

        // Director
        // formData.directors.forEach((director, index) => {
        //     formData.append(`directors[${index}].name`, director.name);
        //     formData.append(`directors[${index}].email`, director.email);
        //     formData.append(`director_profile_picture_${index}`, director.profile_picture);
        // });

        // //  Writer
        // formData.writers.forEach((writers, index) => {
        //     formData.append(`writers[${index}].name`, writers.name);
        //     formData.append(`writers[${index}].email`, writers.email);
        //     formData.append(`writers_profile_picture_${index}`, writers.profile_picture);
        // });

        // // Coproducer

        // formData.coproducers.forEach((coproducers, index) => {
        //     formData.append(`coproducers[${index}].name`, coproducers.name);
        //     formData.append(`coproducers[${index}].email`, coproducers.email);
        //     formData.append(`coproducers_profile_picture_${index}`, coproducers.profile_picture);
        // });

        // // Execuetive-Producers

        // formData.execuetiveproducers.forEach((execuetiveproducers, index) => {
        //     formData.append(`execuetiveproducers[${index}].name`, execuetiveproducers.name);
        //     formData.append(`execuetiveproducers[${index}].email`, execuetiveproducers.email);
        //     formData.append(`execuetiveproducers_profile_picture_${index}`, execuetiveproducers.profile_picture);
        // });

        // // Actors

        // formData.actors.forEach((actors, index) => {
        //     formData.append(`actors[${index}].name`, actors.name);
        //     formData.append(`actors[${index}].email`, actors.email);
        //     formData.append(`actors_profile_picture_${index}`, actors.profile_picture);
        // });

        // // Production-mamagers

        // formData.productionmanagers.forEach((productionmanagers, index) => {
        //     formData.append(`productionmanagers[${index}].name`, productionmanagers.name);
        //     formData.append(`productionmanagers[${index}].email`, productionmanagers.email);
        //     formData.append(`productionmanagers_profile_picture_${index}`, productionmanagers.profile_picture);
        // });

        // // Assistant-Directors

        // formData.assistantdirectors.forEach((assistantdirectors, index) => {
        //     formData.append(`assistantdirectors[${index}].name`, assistantdirectors.name);
        //     formData.append(`assistantdirectors[${index}].email`, assistantdirectors.email);
        //     formData.append(`assistantdirectors_profile_picture_${index}`, assistantdirectors.profile_picture);
        // });

        // // Associate-Directors

        // formData.associatedirectors.forEach((associatedirectors, index) => {
        //     formData.append(`associatedirectors[${index}].name`, associatedirectors.name);
        //     formData.append(`associatedirectors[${index}].email`, associatedirectors.email);
        //     formData.append(`associatedirectors_profile_picture_${index}`, associatedirectors.profile_picture);
        // });

        // // Action-Directors

        // formData.actiondirectors.forEach((actiondirectors, index) => {
        //     formData.append(`actiondirectors[${index}].name`, actiondirectors.name);
        //     formData.append(`actiondirectors[${index}].email`, actiondirectors.email);
        //     formData.append(`actiondirectors_profile_picture_${index}`, actiondirectors.profile_picture);
        // });



        // Include userId
        form.append('userId', formData.userId);

        if (formData.poster_path) {
            form.append('poster_path', formData.poster_path);
        }

        if (formData.backdrop_path) {
            form.append('backdrop_path', formData.backdrop_path);
        }

        if (formData.backdropsmall_path) {
            form.append('backdropsmall_path', formData.backdropsmall_path);
        }

        if (formData.video_url) {
            form.append('video_url', formData.video_url);
        }

        const endpoint = `https://thecameratales.com/api/${formData.type}`;

        try {
            const response = await axios.post(endpoint, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') ||
                        progressEvent.target.getResponseHeader('x-decompressed-content-length');

                    if (totalLength !== null) {
                        const percentage = Math.round((progressEvent.loaded * 100) / totalLength);
                        setUploadProgress(percentage);

                        setLogMessages(prevLogs => [
                            ...prevLogs,
                            `Upload Progress: ${percentage}%`
                        ]);
                    }
                },
            });

            // Adding more logs during encoding process (simulated for now)
            setLogMessages(prevLogs => [
                ...prevLogs,
                'Video encoding started...',
                'Encoding progress: 10%',
                'Encoding progress: 50%',
                'Encoding progress: 90%',
                'Encoding completed.',
                'Saving movie data...'
            ]);

            console.log('Success:', response.data);

            // Log success message
            setLogMessages(prevLogs => [
                ...prevLogs,
                'Movie uploaded and processed successfully!'
            ]);

            setUploadProgress(0); // Reset progress bar

            // Reset the form after successful submission
            setFormData({
                title: '',
                overview: '',
                genre: '',
                release_date: '',
                adult: false,
                original_language: '',
                popularity: 5,
                vote_average: 5,
                vote_count: 5,
                type: 'movie', // default type
                video: false,
                poster_path: null,
                backdrop_path: null,
                backdropsmall_path: null,
                video_url: [],
                // userId: '', // Add userId here
                producers: [],
                directors: [],
                writers: [],
                coproducers: [],
                execuetiveproducers: [],
                actors: [],
                productionmanagers: [],
                assistantdirectors: [],
                associatedirectors: [],
                actiondirectors: [],
                userId: formData.userId, // Keep userId after reset
            });

            // Show thank you modal
            setShowModal(true);
        } catch (error) {
            console.error('Error posting data:', error.response?.data || error);
        } finally {
            setIsSubmitting(false); // Re-enable form inputs
            setUploadProgress(0); // Reset upload progress
        }
    };

    return (
        <>
            <CmsNavbar />
            <div style={styles.container}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <h2 style={styles.header}>Upload Media</h2>

                    <div style={styles.formGrid}>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Genre</label>
                            <input
                                type="text"
                                placeholder='comma seperated'
                                name="genre"
                                value={formData.genre}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>



                        <div style={styles.formGroup}>
                            <label style={styles.label}>Release Date</label>
                            <input
                                type="date"
                                name="release_date"
                                value={formData.release_date}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Original Language</label>
                            <input
                                type="text"
                                name="original_language"
                                value={formData.original_language}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Popularity</label>
                            <input
                                type="number"
                                name="popularity"
                                value={formData.popularity}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div> */}

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Vote Average</label>
                            <input
                                type="number"
                                step="0.1"
                                name="vote_average"
                                value={formData.vote_average}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </div> */}

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Vote Count</label>
                            <input
                                type="number"
                                name="vote_count"
                                value={formData.vote_count}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </div> */}

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Type</label>
                            <select
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                style={styles.select}
                            >
                                <option value="movie">Movie</option>
                                <option value="webseries">Web Series</option>
                                <option value="shortfilms">Short Films</option>
                                <option value="upcoming">Upcoming</option>
                                <option value="webshows">Webshows</option>
                                <option value="tales">Tales</option>
                                <option value="toptalesthisweek">Top Tales This Week</option>
                                <option value="musicvideos">Music Videos</option>
                                <option value="shows">Shows</option>
                                <option value="fridaytalkies">Friday Talkies</option>
                            </select>
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Poster</label>
                            <p style={{ fontSize: '0.7rem' }}>poster size should be ideally 421X562</p>
                            <input
                                type="file"
                                name="poster_path"
                                accept="image/*"
                                onChange={handleImgFileChange}
                                style={styles.fileInput}
                            />

                            {/* Modal-style ImageCropper */}
                            {showCropper && (
                                <div style={modalStyles.overlay}>
                                    <div style={modalStyles.container}>
                                        <button
                                            style={modalStyles.closeButton}
                                            onClick={() => setShowCropper(false)}
                                        >
                                            Close
                                        </button>
                                        <ImageCropper
                                            imageFile={selectedFile}
                                            aspectRatio={3 / 4}
                                            canvasWidth={4500}
                                            canvasHeight={6000}
                                            onCropComplete={handleCropComplete}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video Thumbnail</label>
                            <input
                                type="file"
                                name="videoThumbnailUrl"
                                accept="video/*"  // Accept only video files for the thumbnail
                                onChange={handleFileChange}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={styles.label}>Backdrop</label>
                            <p style={{ fontSize: '0.7rem' }}>backdrop size should be ideally 1000X500</p>
                            <input
                                type="file"
                                name="backdrop_path"
                                accept="image/*"
                                onChange={handleBackdropFileChange}
                            />

                            {showBackdropCropper && (
                                <div style={modalStyles.overlay}>
                                    <div style={modalStyles.container}>
                                        <button
                                            style={modalStyles.closeButton}
                                            onClick={() => setShowBackdropCropper(false)}
                                        >
                                            Close
                                        </button>
                                        <ImageCropper
                                            imageFile={selectedBackdropFile}
                                            aspectRatio={5334 / 2667}
                                            canvasWidth={5334}
                                            canvasHeight={2667}
                                            onCropComplete={handleBackdropCropComplete}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Backdrop For small screens</label>
                            <p style={{ fontSize: '0.7rem' }}>backdrop size should be ideally 468X447</p>
                            <input
                                type="file"
                                name="backdropsmall_path"
                                accept="image/*"
                                onChange={handleSmallBackdropFileChange}
                                style={styles.fileInput}
                            />

                            {showSmallBackdropCropper && (
                                <div style={modalStyles.overlay}>
                                    <div style={modalStyles.container}>
                                        <button
                                            style={modalStyles.closeButton}
                                            onClick={() => setShowSmallBackdropCropper(false)}
                                        >
                                            Close
                                        </button>
                                        <ImageCropper
                                            imageFile={selectedSmallBackdropFile}
                                            aspectRatio={3750 / 3576}
                                            canvasWidth={3750}
                                            canvasHeight={3576}
                                            onCropComplete={handleSmallBackdropCropComplete}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video File</label>
                            <input
                                type="file"
                                name="video_url"
                                accept="video/*"
                                onChange={handleFileChange}
                                style={styles.fileInput}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Log Line</label>
                            {/* Maximum characters */}
                            <textarea
                                name="overview"
                                value={formData.overview}
                                onChange={handleChange}
                                style={styles.textarea}
                                maxLength={250} // Set your desired character limit here
                                required
                            />
                            <small style={styles.charCounter}>
                                {formData.overview.length}/250 characters
                            </small>
                        </div>


                        <div style={styles.formGroup}>
                            <label style={styles.label}>
                                Adult Content
                            </label>
                            <input
                                type="checkbox"
                                name="adult"
                                checked={formData.adult}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video</label>
                            <input
                                type="checkbox"
                                name="video"
                                checked={formData.video}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Ticket Price (For Friday Talkies)</label>
                            <input
                                type="number" // number field for ticket price
                                name="ticketPrice"
                                value={formData.ticketPrice}
                                onChange={handleChange}
                                style={styles.input}
                                placeholder="Enter ticket price"
                            />
                        </div>
                    </div>

                    <div style={{ padding: '20px', borderTopRightRadius: '8px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Producers</label>

                        {formData.producers.map((producer, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Producer email"
                                    value={producer.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'producers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Producer name"
                                    value={producer.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'producers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeProducer(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Producer Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addProducer}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Producer
                        </button>
                    </div>


                    {/* Add similar sections for directors, writers, etc. */}


                    {/* Add fields for directors */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Directors</label>

                        {formData.directors.map((director, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Director email"
                                    value={director.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'directors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Director name"
                                    value={director.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'directors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeDirector(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Director Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addDirector}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Director
                        </button>
                    </div>

                    {/* Add similar sections for directors, writers, etc. */}

                    {/* Writers */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Writers</label>

                        {formData.writers.map((writer, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Writers' email"
                                    value={writer.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'writers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Writer name"
                                    value={writer.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'writers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeWriter(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Writer Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addWriter}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Writer
                        </button>
                    </div>

                    {/* Coproducer */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Co-Producers</label>

                        {formData.coproducers.map((coproducer, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Co-producer's email"
                                    value={coproducer.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'coproducers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Co-Producer name"
                                    value={coproducer.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'coproducers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeCoproducer(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Co-Producer Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addCoproducer}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Co-Producer
                        </button>
                    </div>

                    {/* Execuetive-Producers */}


                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Executive Producers</label>

                        {formData.execuetiveproducers.map((execuetiveproducer, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Executive producer's email"
                                    value={execuetiveproducer.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'execuetiveproducers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Execuitive-Producer Name"
                                    value={execuetiveproducer.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'execuetiveproducers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeExecuetiveproducer(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Executive Producer Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addExecuetiveproducer}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Executive Producer
                        </button>
                    </div>


                    {/* Actors */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Casts</label>

                        {formData.actors.map((actor, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Cast's email"
                                    value={actor.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Actor Name"
                                    value={actor.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeActor(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Cast Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addActor}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Casts
                        </button>
                    </div>


                    {/* Production-Managers */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Production Managers</label>

                        {formData.productionmanagers.map((productionmanager, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Production Manager's email"
                                    value={productionmanager.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'productionmanagers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Production Manager's Name"
                                    value={productionmanager.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'productionmanagers')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Profile Picture Input
                                <input
                                    type="file"
                                    name={`productionmanager_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedProductionManagers = [...formData.productionmanagers];
                                        updatedProductionManagers[index].profile_picture = file;
                                        setFormData({ ...formData, productionmanagers: updatedProductionManagers });
                                    })}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        fontSize: '14px',
                                        marginBottom: '10px',
                                    }}
                                /> */}

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeProductionmanager(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Production Manager Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addProductionmanager}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Production Managers
                        </button>
                    </div>


                    {/* Assistant-Directors */}


                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Assistant Directors</label>

                        {formData.assistantdirectors.map((assistantdirector, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Assistant Director's email"
                                    value={assistantdirector.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'assistantdirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Assistant Director's Name"
                                    value={assistantdirector.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'assistantdirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeAssistantdirector(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Assistant Director Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addAssistantdirector}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Assistant Director
                        </button>
                    </div>



                    {/* Associate-Directors */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Associate Directors</label>

                        {formData.associatedirectors.map((associatedirector, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Associate Director's email"
                                    value={associatedirector.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'associatedirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Associate Director's  Name"
                                    value={associatedirector.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'associatedirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeAssociatedirector(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Associate Director Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addAssociatedirector}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Associate Director
                        </button>
                    </div>



                    {/* Action- Directors */}

                    {/* Add fields for producers */}
                    <div style={{ padding: '20px', borderBottomLeftRadius: '8px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                        <label style={{ fontWeight: "bolder", fontSize: '18px', marginBottom: '10px', display: 'block', color: '#333' }}>Action Directors</label>

                        {formData.actiondirectors.map((actiondirector, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '15px',
                                padding: '15px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                flexWrap: 'wrap'
                            }}>
                                {/* Email Input */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Action Director's email"
                                    value={actiondirector.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actiondirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Name Input */}
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Action Director's  Name"
                                    value={actiondirector.name}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actiondirectors')}
                                    style={{
                                        flex: 1,
                                        minWidth: '100%',
                                        padding: '8px',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                        outline: 'none',
                                    }}
                                />

                                {/* Remove Button */}
                                <button
                                    type="button"
                                    onClick={() => removeActiondirector(index)}
                                    style={{
                                        backgroundColor: '#d9534f', // Red color for remove button
                                        color: 'white',
                                        padding: '8px 12px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                        minWidth: '100%',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#c9302c')} // Darker red on hover
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#d9534f')} // Original color on mouse out
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        {/* Add Action Director Button */}
                        <button
                            type="button"
                            style={{
                                display: 'block',
                                backgroundColor: '#1e88e5', // Primary blue color
                                color: 'white',
                                padding: '10px 20px',
                                margin: '20px auto 0', // Center the button
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // Remove border
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                width: '100%', // Full width on smaller screens
                                textAlign: 'center'
                            }}
                            onClick={addActiondirector}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#1565c0')} // Darker blue on hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#1e88e5')} // Back to normal
                        >
                            Add Action Director
                        </button>
                    </div>






                    <button type="submit" style={styles.submitButton} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </form>

                {uploadProgress > 0 && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}>
                        <div style={{
                            backgroundColor: '#333',
                            padding: '20px',
                            borderRadius: '8px',
                            width: '300px',
                            textAlign: 'center',
                            color: 'white'
                        }}>
                            <h3>Upload Progress</h3>
                            <progress
                                value={uploadProgress}
                                max="100"
                                style={{
                                    width: '100%',
                                    height: '10px',
                                    backgroundColor: '#ddd',
                                }}
                            />
                            <p style={{ margin: '10px 0 0', fontWeight: 'bold' }}>{uploadProgress}%</p>
                            <p style={{ margin: '5px 0 0', fontStyle: 'italic', color: 'lightcoral' }}>
                                Please DO-NOT refresh the page...
                            </p>
                        </div>
                    </div>
                )}

                {/* {logMessages.length > 0 && (
                    <div style={styles.logContainer}>
                        <h3>Process Log</h3>
                        <ul style={styles.logList}>
                            {logMessages.map((log, index) => (
                                <li key={index}>{log}</li>
                            ))}
                        </ul>
                    </div>
                )} */}

                {showModal && (
                    <div style={styles.modal}>
                        <div style={styles.modalContent}>
                            <h2>Thank you!</h2>
                            <p>Your media has been uploaded successfully.</p>
                            <button onClick={() => setShowModal(false)} style={styles.modalButton}>
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '20px',
        color: 'black',
        marginTop: '8vh'
    },
    form: {
        maxWidth: '800px',
        width: '100%',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    formGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '50px',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    fileInput: {
        padding: '5px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    textarea: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'vertical',
        minHeight: '80px',
    },
    select: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    submitButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px',
        alignSelf: 'center',
    },
    progressContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    progress: {
        width: '100%',
        height: '20px',
    },
    logContainer: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        width: '100%',
        maxWidth: '800px',
    },
    logList: {
        listStyleType: 'none',
        padding: '0',
    },
    modal: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
    },
    modalButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
    },
};

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    container: {
        position: 'relative',
        width: '80%',
        maxWidth: '600px',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        color: 'red',
        fontSize: '18px',
        cursor: 'pointer',
        fontWeight: 'bold',
        zIndex: '90',
    },
};

export default CMSMediaForm;
