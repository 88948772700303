import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import shaka from 'shaka-player';
import { Modal, Box, Button, TextField } from '@mui/material';
import styles from './UserMoviesPage.module.css'; // Import the CSS module
import CmsNavbar from './CmsNavbar';

const UserMoviesPage = () => {
    const [movies, setMovies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('movie');
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
    const [currentMovieTitle, setCurrentMovieTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddCastModalOpen, setIsAddCastModalOpen] = useState(false);
    const [currentMovieId, setCurrentMovieId] = useState(null);
    const [castInput, setCastInput] = useState(''); // For inputting cast member IDs
    const [isEpisodeModalOpen, setIsEpisodeModalOpen] = useState(false);
    const [newEpisode, setNewEpisode] = useState({
        title: '',
        overview: '',
        release_date: '',
        genre: '',
        video_url: null,
        poster_path: null,
        backdrop_path: null
    });
    const videoRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.id;

    useEffect(() => {
        const fetchUserMedia = async () => {
            try {
                const response = await axios.get(`https://thecameratales.com/api/${selectedCategory}/user/${userId}`);
                setMovies(Array.isArray(response.data) ? response.data : []);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUserMedia();
    }, [userId, selectedCategory]);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleVideoSelect = (videoUrls, title) => {
        setCurrentVideoUrl(`https://thecameratales.com/${videoUrls['master']}`);
        setCurrentMovieTitle(title);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentVideoUrl) {
            const initPlayer = async () => {
                try {
                    await shaka.polyfill.installAll();
                    const player = new shaka.Player(videoRef.current);
                    player.addEventListener('error', onErrorEvent);
                    await player.load(currentVideoUrl);
                } catch (e) {
                    onErrorEvent(e);
                }
            };

            initPlayer();
        }
    }, [isModalOpen, currentVideoUrl]);

    const onErrorEvent = (error) => {
        console.error('Error code', error.code, 'object', error);
    };

    const handleAddEpisode = async (movieId) => {
        const formData = new FormData();
        formData.append('title', newEpisode.title);
        formData.append('overview', newEpisode.overview);
        formData.append('release_date', newEpisode.release_date);
        formData.append('genre', newEpisode.genre);
        formData.append('video_url', newEpisode.video_url);
        formData.append('poster_path', newEpisode.poster_path);
        formData.append('backdrop_path', newEpisode.backdrop_path);

        try {
            await axios.post(`https://thecameratales.com/api/${selectedCategory}/${movieId}/episodes`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Episode added successfully!');
            setIsEpisodeModalOpen(false);
            setNewEpisode({
                title: '',
                overview: '',
                release_date: '',
                genre: '',
                video_url: null,
                poster_path: null,
                backdrop_path: null
            });
        } catch (error) {
            console.error('Error adding episode:', error.message);
        }
    };

    // Function to open the add cast modal and set current movie ID
    // Function to open the add cast modal and set current movie ID
    const handleOpenAddCastModal = (movieId) => {
        setCurrentMovieId(movieId);
        setIsAddCastModalOpen(true);
    };

    // Function to add cast members
    const handleAddCast = async () => {
        try {
            await axios.put(`https://thecameratales.com/api/${selectedCategory}/detail/${currentMovieId}/add-cast`, {
                userId: castInput, // Comma-separated string of user IDs
            });
            alert('Cast added successfully!');
            setIsAddCastModalOpen(false);
            setCastInput(''); // Clear the input after submission
        } catch (error) {
            console.error('Error adding cast:', error.message);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
        <CmsNavbar/>
        <div className={styles.container}>
            <button onClick={toggleSidebar} className={styles.toggleButton}>
                {isSidebarVisible ? 'Hide Navigation' : 'Show Navigation'}
            </button>

            {/* Horizontal Sidebar (NavBar) */}
            {isSidebarVisible && (
                <div className={styles.navbar}>
                    <ul className={styles.navList}>
                        {['movie', 'webseries', 'webshows', 'upcoming', 'tales', 'musicvideos', 'shows', 'shortfilms'].map(category => (
                            <li key={category} onClick={() => setSelectedCategory(category)} className={styles.categoryItem}>
                                {category.charAt(0).toUpperCase() + category.slice(1)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className={isSidebarVisible ? styles.contentWithSidebar : styles.contentFullWidth}>
                <h1>{selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Uploaded by User</h1>
                {movies.length === 0 ? (
                    <p>No {selectedCategory} uploaded by this user yet.</p>
                ) : (
                    <div className={styles.moviesContainer}>
                        {movies.map((media) => (
                            <div key={media._id} className={styles.card}>
                                <h2>{media.title}</h2>
                                <img
                                    src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${media.backdrop_path}`}
                                    alt={media.title}
                                    className={styles.backdropImage}
                                />
                                <p><strong>Overview:</strong> {media.overview}</p>
                                <p><strong>Release Date:</strong> {new Date(media.release_date).toLocaleDateString()}</p>
                                <p><strong>Genre:</strong> {media.genre.join(', ')}</p>
                                <p><strong>Vote Average:</strong> {media.vote_average} (Votes: {media.vote_count})</p>
                                <p><strong>Views:</strong> {media.views}</p>
                                <p><strong>Likes:</strong> {media.likes}</p>
                                <p><strong>Status:</strong> {media.isApproved ? 'Approved' : 'Pending'}</p>
                                <div className={styles.buttonContainer}>
                                    <button className={styles.watchButton} onClick={() => handleVideoSelect(media.video_urls, media.title)}>
                                        Watch
                                    </button>
                                    <button className={styles.addEpisodeButton} onClick={() => setIsEpisodeModalOpen(true)}>
                                        Add Episode
                                    </button>
                                    {console.log(`Logged-in user ID: ${userId}, Media user ID: ${media.uploadedBy}`)}

                                    {media.uploadedBy === userId && (
                                        <>
                                            <button className={styles.addCastButton} onClick={() => handleOpenAddCastModal(media._id)}>
                                                Add Cast
                                            </button>
                                        </>
                                    )}
                                </div>

                                {/* Modal for Adding Cast */}
                                <Modal open={isAddCastModalOpen} onClose={() => setIsAddCastModalOpen(false)}>
                                    <Box className={styles.modalBox}>
                                        <h2>Add Cast Members</h2>
                                        <TextField
                                            label="Cast Member IDs (comma-separated)"
                                            value={castInput}
                                            onChange={(e) => setCastInput(e.target.value)}
                                            fullWidth
                                        />
                                        <Button onClick={handleAddCast} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                                            Add Cast
                                        </Button>
                                    </Box>
                                </Modal>

                                {/* Episode Modal */}
                                <Modal open={isEpisodeModalOpen} onClose={() => setIsEpisodeModalOpen(false)}>
                                    <Box className={styles.modalBox}>
                                        <h2>Add New Episode</h2>

                                        <TextField
                                            label="Title"
                                            value={newEpisode.title}
                                            onChange={(e) => setNewEpisode({ ...newEpisode, title: e.target.value })}
                                            fullWidth
                                            helperText="Enter the title of the episode."
                                        />

                                        <TextField
                                            label="Overview"
                                            value={newEpisode.overview}
                                            onChange={(e) => setNewEpisode({ ...newEpisode, overview: e.target.value })}
                                            fullWidth
                                            multiline
                                            rows={3}
                                            helperText="Provide a brief summary of the episode."
                                        />

                                        <TextField
                                            label="Release Date"
                                            type="date"
                                            value={newEpisode.release_date}
                                            onChange={(e) => setNewEpisode({ ...newEpisode, release_date: e.target.value })}
                                            fullWidth
                                            helperText="Select the release date of the episode."
                                        />

                                        <TextField
                                            label="Genre"
                                            value={newEpisode.genre}
                                            onChange={(e) => setNewEpisode({ ...newEpisode, genre: e.target.value })}
                                            fullWidth
                                            helperText="Enter the genre of the episode (e.g., Action, Comedy)."
                                        />

                                        <div>
                                            <input
                                                type="file"
                                                onChange={(e) => setNewEpisode({ ...newEpisode, video_url: e.target.files[0] })}
                                                accept="video/*"
                                            />
                                            <p>Upload the video file for the episode.</p>
                                        </div>

                                        <div>
                                            <input
                                                type="file"
                                                onChange={(e) => setNewEpisode({ ...newEpisode, poster_path: e.target.files[0] })}
                                                accept="image/*"
                                            />
                                            <p>Upload the poster image for the episode.</p>
                                        </div>

                                        <div>
                                            <input
                                                type="file"
                                                onChange={(e) => setNewEpisode({ ...newEpisode, backdrop_path: e.target.files[0] })}
                                                accept="image/*"
                                            />
                                            <p>Upload the backdrop image for the episode.</p>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleAddEpisode(media._id)}
                                            style={{ marginTop: '10px' }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Modal>

                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Modal for Video Player */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box className={styles.modalBox}>
                    <h2>{currentMovieTitle}</h2>
                    {currentVideoUrl && (
                        <video ref={videoRef} controls className={styles.videoPlayer} />
                    )}
                    <Button onClick={() => setIsModalOpen(false)} variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                        Close Video
                    </Button>
                </Box>
            </Modal>
        </div>
        </>
    );
};


export default UserMoviesPage;
