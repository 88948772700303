import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Link, Divider, CircularProgress, Button, Switch, FormControlLabel, Modal, Grid } from '@mui/material';
import { Email, Phone, LocationOn, Language, Twitter, Instagram, Movie, Star, LinkedIn } from '@mui/icons-material';

const UserProfile = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const [privacySettings, setPrivacySettings] = useState(null);
    const [showMoreBiography, setShowMoreBiography] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);

    // Fetch logged-in user data from localStorage
    useEffect(() => {
        const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));  // Assuming the user data is saved here
        setLoggedInUser(userFromLocalStorage);
    }, []);

    // Fetch user data and privacy settings
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://thecameratales.com/api/admin/users/${userId}`);
                setUserData(response.data);
                setPrivacySettings(response.data.privacySettings || {});
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        if (userId) fetchData();
    }, [userId]);

    const handlePrivacyChange = async (field) => {
        if (loggedInUser && (loggedInUser.id === userId)) {
            const updatedPrivacy = { ...privacySettings, [field]: !privacySettings[field] };
            console.log("Updated Privacy Settings: ", updatedPrivacy);
            setPrivacySettings(updatedPrivacy);
            try {
                const response = await axios.put(`https://thecameratales.com/api/admin/users/${userId}/privacy`, { privacySettings: updatedPrivacy });
                console.log("Updated Privacy in Server:", response.data);
            } catch (error) {
                console.error("Error updating privacy settings:", error);
            }
        }
    };


    if (!userData) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;

    const {
        name, email, contactInfo, biography, awards = [],
        nationality, gender, categories = [], website, imdbProfile, twitterHandle, instagramHandle,
        socialLinks = []
    } = userData;

    const toggleBiography = () => setShowMoreBiography(!showMoreBiography);

    // Map social media types to icons
    const socialIcons = {
        Instagram: "Instagram",
        LinkedIn: "LinkedIn",
        Twitter: "Twitter",
    };

    const handleShowMoreClick = () => {
        setIsOverflow(!isOverflow); // Toggle overflow on "Show More"
    };

    return (
        <Box sx={{ minWidth: '300px', mt: 4, p: 0, maxHeight: '55vh' }}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: '#15171a',
                    color: 'white',
                    maxHeight: '50vh',
                    p: 2,
                    overflow: isOverflow ? 'auto' : 'hidden',
                    '&::-webkit-scrollbar': { width: '8px' },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#2c2c3c', borderRadius: '10px' },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5c6bc0',
                        borderRadius: '10px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#5c6bc0 #2c2c3c',
                }}
            >
                <div
                    style={{
                        overflow: isOverflow ? 'auto' : 'hidden', // Switch overflow based on button click
                        maxHeight: isOverflow ? 'none' : '40vh', // Control height dynamically
                        transition: 'max-height 0.3s ease',
                    }}
                >
                    <CardContent>
                        <Grid container spacing={2}>
                            {/* Show More button
                            <Button
                                variant="outlined"
                                onClick={() => setShowModal(true)}
                                sx={{ color: 'white', borderColor: 'white' }}
                            >
                                Show More
                            </Button> */}
                            <Grid item xs={9}>
                                <Typography variant="h4" gutterBottom>{name}</Typography>
                                {categories.length > 0 && (
                                    <Typography variant="subtitle1">{categories.join(", ")}</Typography>
                                )}
                                <Divider sx={{ my: 2, bgcolor: 'primary.main' }} />
                            </Grid>
                        </Grid>

                        {/* Contact Information */}
                        <Typography variant="h6" gutterBottom>Contact Information</Typography>

                        {/* Email */}
                        {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.email)) && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Email /> <Typography>{email}</Typography>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.email} onChange={() => handlePrivacyChange("email")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.email ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.email ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )} */}
                            </Box>
                        )}

                        {/* Phone */}
                        {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.phone && contactInfo?.phone)) && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Phone /> <Typography>{contactInfo?.phone}</Typography>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.phone} onChange={() => handlePrivacyChange("phone")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.phone ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.phone ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )} */}
                            </Box>
                        )}

                        {/* Address */}
                        {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.address && contactInfo?.address)) && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <LocationOn />
                                <Typography>{`${contactInfo?.address?.street}, ${contactInfo?.address?.city}, ${contactInfo?.address?.state}`}</Typography>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.address} onChange={() => handlePrivacyChange("address")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.address ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.address ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )} */}
                            </Box>
                        )}

                        {/* Biography */}
                        {biography && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.biography) && (
                            <>
                                <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                                <Typography variant="h6" gutterBottom>Biography</Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        whiteSpace: 'pre-wrap', // This ensures newlines are respected
                                        wordWrap: 'break-word', // This ensures text wraps correctly within the container
                                    }}
                                >
                                    {showMoreBiography ? biography : `${biography.slice(0, 100)}...`}
                                </Typography>
                                <Button onClick={toggleBiography} color="secondary">
                                    {showMoreBiography ? 'Read Less' : 'Read More'}
                                </Button>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch
                                        checked={privacySettings.biography}
                                        onChange={() => handlePrivacyChange("biography")}
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: privacySettings.biography ? 'white' : 'gray',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: privacySettings.biography ? '#ccc' : '#ccc',
                                            },
                                        }}
                                    />}
                                    label="Public"
                                />
                            )} */}
                            </>
                        )}

                        {/* Professional Information */}
                        <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                        <Typography variant="h6" gutterBottom>Professional Information</Typography>
                        {nationality && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.nationality) && (
                            <Box display="flex" alignItems="center">
                                <Typography><strong>Nationality:</strong> {nationality}</Typography>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.nationality} onChange={() => handlePrivacyChange("nationality")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.nationality ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.nationality ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )} */}
                            </Box>
                        )}
                        {gender && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.gender) && (
                            <Box display="flex" alignItems="center">
                                <Typography><strong>Gender:</strong> {gender}</Typography>
                                {/* {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.gender} onChange={() => handlePrivacyChange("gender")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.gender ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.gender ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )} */}
                            </Box>
                        )}

                        {/* Social Links */}
                        <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                        <Typography variant="h6" gutterBottom>Social Links</Typography>
                        {website && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Language />
                                <Link href={website} style={{ color: 'cornflowerblue' }} underline="hover">Website</Link>
                            </Box>
                        )}
                        {imdbProfile && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Movie />
                                <Link href={imdbProfile} style={{ color: 'cornflowerblue' }} underline="hover">IMDb Profile</Link>
                            </Box>
                        )}
                        {twitterHandle && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Twitter />
                                <Link href={`https://twitter.com/${twitterHandle}`} style={{ color: 'cornflowerblue' }} underline="hover">@{twitterHandle}</Link>
                            </Box>
                        )}
                        {instagramHandle && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Instagram />
                                <Link href={`https://instagram.com/${instagramHandle}`} style={{ color: 'cornflowerblue' }} underline="hover">@{instagramHandle}</Link>
                            </Box>
                        )}

                        {/* Social Links */}
                        <Divider sx={{ my: 3, bgcolor: 'white' }} />
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                color: 'white',
                                mb: 2
                            }}
                        >
                            Work Links
                        </Typography>
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                                alignItems: 'center'
                            }}
                        >
                            {socialLinks.map((link) => (
                                <Box
                                    key={link._id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        p: 1,
                                        border: '1px solid',
                                        borderColor: 'grey.300',
                                        borderRadius: 1,
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: 'primary.light',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <Link
                                        href={link.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            color: 'white',
                                            fontWeight: 'medium',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        {link.type}
                                    </Link>
                                </Box>
                            ))}
                        </Box>


                        {/* Awards */}
                        {awards.length > 0 && (
                            <>
                                <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                                <Typography variant="h6" gutterBottom>Awards</Typography>
                                <ul>
                                    {awards.map((award, index) => (
                                        <li key={index}>
                                            {award.name ? award.name : 'No Award Added'} ({award.year ? award.year : 'No Year Added'})
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </CardContent>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => setShowModal(true)}
                        sx={{
                            color: 'white',
                            borderColor: 'white',
                        }}
                    >
                        {isOverflow ? 'Show Less' : 'Show More'}
                    </Button>
                </div>
            </Card>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowY: 'auto', // Allows vertical scrolling if content exceeds the viewport
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 24,
                        width: '90%', // Set to occupy 90% of the viewport width for smaller screens
                        maxWidth: 600, // Restrict to a maximum width on larger screens
                        minWidth: '300px', // Ensure a minimum width for usability
                        overflowY: 'auto', // Add vertical scrolling for long content
                        maxHeight: '90vh', // Prevent it from exceeding viewport height
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        {name}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', // Ensures vertical alignment if needed
                            mt: 3,
                            mb: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </Button>
                    </Box>

                    {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.email)) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            sx={{
                                flexWrap: 'wrap', // Allows wrapping for small screens
                                wordBreak: 'break-word', // Ensures long words break into the next line
                                border: '1px solid #ccc', // Optional: Adds a border around the email section
                                borderRadius: '4px', // Optional: Rounds the border corners
                                padding: '8px', // Optional: Adds padding inside the box
                                backgroundColor: '#f9f9f9', // Optional: Light background for better visibility
                                maxWidth: '100%', // Ensures responsiveness
                            }}
                        >
                            <Email />
                            <Typography sx={{ wordBreak: 'break-word' }}>{email}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.email} onChange={() => handlePrivacyChange("email")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.email ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.email ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    {/* Phone */}
                    {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.phone && contactInfo?.phone)) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            sx={{
                                flexWrap: 'wrap', // Allows wrapping for small screens
                                wordBreak: 'break-word', // Ensures long words break into the next line
                                border: '1px solid #ccc', // Optional: Adds a border around the email section
                                borderRadius: '4px', // Optional: Rounds the border corners
                                padding: '8px', // Optional: Adds padding inside the box
                                backgroundColor: '#f9f9f9', // Optional: Light background for better visibility
                                maxWidth: '100%', // Ensures responsiveness
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <Phone /> <Typography>{contactInfo?.phone}</Typography>
                                {(loggedInUser && (loggedInUser.id === userId)) && (
                                    <FormControlLabel
                                        control={<Switch checked={privacySettings.phone} onChange={() => handlePrivacyChange("phone")} />}
                                        label="Public"
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: privacySettings.phone ? 'white' : 'gray',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: privacySettings.phone ? '#ccc' : '#ccc',
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Address */}
                    {((loggedInUser && (loggedInUser.id === userId)) || (privacySettings.address && contactInfo?.address)) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            sx={{
                                flexWrap: 'wrap', // Allows wrapping for small screens
                                wordBreak: 'break-word', // Ensures long words break into the next line
                                border: '1px solid #ccc', // Optional: Adds a border around the email section
                                borderRadius: '4px', // Optional: Rounds the border corners
                                padding: '8px', // Optional: Adds padding inside the box
                                backgroundColor: '#f9f9f9', // Optional: Light background for better visibility
                                maxWidth: '100%', // Ensures responsiveness
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <LocationOn />
                                <Typography>{`${contactInfo?.address?.street}, ${contactInfo?.address?.city}, ${contactInfo?.address?.state}`}</Typography>
                                {(loggedInUser && (loggedInUser.id === userId)) && (
                                    <FormControlLabel
                                        control={<Switch checked={privacySettings.address} onChange={() => handlePrivacyChange("address")} />}
                                        label="Public"
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: privacySettings.address ? 'white' : 'gray',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: privacySettings.address ? '#ccc' : '#ccc',
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Biography */}
                    {biography && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.biography) && (
                        <>
                            <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                            <Typography variant="h6" gutterBottom>Biography</Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    whiteSpace: 'pre-wrap', // This ensures newlines are respected
                                    wordWrap: 'break-word', // This ensures text wraps correctly within the container
                                }}
                            >
                                {showMoreBiography ? biography : `${biography.slice(0, 100)}...`}
                            </Typography>
                            <Button onClick={toggleBiography} color="secondary">
                                {showMoreBiography ? 'Read Less' : 'Read More'}
                            </Button>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch
                                        checked={privacySettings.biography}
                                        onChange={() => handlePrivacyChange("biography")}
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: privacySettings.biography ? 'white' : 'gray',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: privacySettings.biography ? '#ccc' : '#ccc',
                                            },
                                        }}
                                    />}
                                    label="Public"
                                />
                            )}
                        </>
                    )}

                    <Divider sx={{ my: 2 }} />
                    {nationality && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.nationality) && (
                        <Box display="flex" alignItems="center">
                            <Typography><strong>Nationality:</strong> {nationality}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.nationality} onChange={() => handlePrivacyChange("nationality")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.nationality ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.nationality ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}
                    {gender && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.gender) && (
                        <Box display="flex" alignItems="center">
                            <Typography><strong>Gender:</strong> {gender}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.gender} onChange={() => handlePrivacyChange("gender")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.gender ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.gender ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}
                    {website && (
                        <Typography variant="body1" gutterBottom>
                            <strong>Website:</strong>{' '}
                            <a href={website} target="_blank" rel="noopener noreferrer">
                                {website}
                            </a>
                        </Typography>
                    )}
                    {twitterHandle && (
                        <Typography variant="body1" gutterBottom>
                            <strong>Twitter:</strong>{' '}
                            <a href={`https://twitter.com/${twitterHandle}`} target="_blank" rel="noopener noreferrer">
                                @{twitterHandle}
                            </a>
                        </Typography>
                    )}
                    {instagramHandle && (
                        <Typography variant="body1" gutterBottom>
                            <strong>Instagram:</strong>{' '}
                            <a href={`https://instagram.com/${instagramHandle}`} target="_blank" rel="noopener noreferrer">
                                @{instagramHandle}
                            </a>
                        </Typography>
                    )}

                    {/* Work Links */}
                    {socialLinks.length > 0 && (
                        <>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Work Links
                            </Typography>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gap: 2,
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                                    alignItems: 'center',
                                }}
                            >
                                {socialLinks.map((link) => (
                                    <Box
                                        key={link._id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            p: 1,
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            borderRadius: 1,
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: 'primary.light',
                                                boxShadow: 3,
                                            },
                                        }}
                                    >
                                        <a
                                            href={link.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: 'black',
                                                fontWeight: 'medium',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            {link.type}
                                        </a>
                                    </Box>
                                ))}
                            </Box>
                        </>
                    )}

                    {/* Awards */}
                    {awards.length > 0 && (
                        <>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Awards
                            </Typography>
                            <ul>
                                {awards.map((award, index) => (
                                    <li key={index}>
                                        {award.name ? award.name : 'No Award Added'} ({award.year ? award.year : 'No Year Added'})
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default UserProfile;