import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  TextField,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplyIcon from '@mui/icons-material/Reply';
import CommentIcon from '@mui/icons-material/Comment';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const PostDetail = (setPosts, onPostClick) => {
  const [loggedInUser, setLoggedInUser] = useState(() => JSON.parse(localStorage.getItem('user') || 'null'));
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [activeReply, setActiveReply] = useState(null);
  const [inputText, setInputText] = useState('');
  const [showComments, setShowComments] = useState({});

  useEffect(() => {
    if (!loggedInUser) {
      setShowLoginModal(true);
    }

    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/posts/${postId}`);
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching the post:', error);
      } finally {
        setLoading(false);
      }
    };

    if (postId) {
      fetchPost();
    }
  }, [postId, loggedInUser]); // Dependency array is stable


  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return; // Avoid fetching if userId is not yet set
      try {
        const response = await axios.get(`https://thecameratales.com/api/admin/users/${userId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleLike = async (postId) => {
    try {
      const response = await axios.put(`https://thecameratales.com/api/admin/posts/${postId}/like`, {}, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      // Update the post state directly with the new like status and updated likes array
      setPost((prevPost) => ({
        ...prevPost,
        likes: response.data.likes, // Update the likes array
      }));

      setIsLiked(response.data.likes.includes(loggedInUser.id)); // Update local isLiked state if needed
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleShare = (postId) => {
    const shareLink = `https://thecameratales.com/${postId}`; // Change this to your actual post URL structure
    navigator.clipboard.writeText(shareLink)
      .then(() => {
        alert('Post link copied to clipboard!'); // Provide feedback
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://thecameratales.com/api/admin/posts/${postId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleComment = async () => {
    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comment`,
        { text: inputText },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setPost((prevPost) => ({ ...prevPost, comments: response.data.comments }));
      setInputText('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleReply = async (postId, commentId) => {
    try {
      const response = await axios.post(
        `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/reply`,
        { text: inputText },
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          }
        }
      );
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? response.data : post
        )
      );
      setInputText('');
      setActiveReply(null);
    } catch (error) {
      console.error('Error replying to comment:', error);
    }
  };

  const handleLikeComment = async (postId, commentId, liked) => {
    try {
      const url = `https://thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/${liked ? 'unlike' : 'like'}`;
      const response = await axios.put(url, null, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      // Update the posts state with the new likes count and liked status
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
              ...post,
              comments: post.comments.map((comment) =>
                comment._id === commentId
                  ? { ...comment, likes: response.data.likes, liked: !liked } // Update the likes and toggle the liked status
                  : comment
              ),
            }
            : post
        )
      );
    } catch (error) {
      console.error('Error liking/unliking comment:', error);
    }
  };

  const toggleCommentsVisibility = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const cancelReply = () => {
    setActiveReply(null);
    setInputText('');
  };

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  if (loading) return <div>Loading...</div>;

  if (!post) return <div>Post not found.</div>;

  return (
    <Box sx={{ mt: 8 }}>
      <Card key={post._id} sx={{ mb: 3, background: "linear-gradient(to right, #15171a, #15171a, #15171a)" }}>
        <CardContent onClick={() => onPostClick(post)}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              src={post.createdBy?.profilePicture ? `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.createdBy.profilePicture}` : ''}
              alt="User"
              sx={{ width: 30, height: 30, mr: 2 }}
            />
            <Typography variant="h6" sx={{ color: "#D3D3D3" }}>
              {post.createdBy ? `${post.createdBy.firstName} ${post.createdBy.lastName}` : 'Unknown'}
            </Typography>
          </Box>
          {post.mediaType === 'image' ? (
            <img
              src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
              alt={post.caption}
              style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
            />
          ) : (
            <video
              src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${post.mediaPath}`}
              controls
              controlsList="nodownload"
              style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
            />
          )}
          <Typography variant="body2" sx={{ mb: 1, color: "#D3D3D3" }}>{post.caption}</Typography>
          <Typography variant="body2" color="#D3D3D3">{post.likes?.length || 0} likes</Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton onClick={() => handleLike(post._id)} aria-label="like">
            <FavoriteIcon sx={{ color: post.likes.includes(loggedInUser?.id) ? 'red' : 'white' }} />
          </IconButton>
          <IconButton onClick={() => handleShare(post._id)} aria-label="share">
            <ShareIcon sx={{ color: 'white !important' }} />
          </IconButton>
          {loggedInUser && loggedInUser.id === post.createdBy?._id && (
            <IconButton onClick={() => handleDelete(post._id)} aria-label="delete">
              <DeleteIcon sx={{ color: '#D3D3D3' }} />
            </IconButton>
          )}
          <IconButton onClick={() => toggleCommentsVisibility(post._id)} aria-label="comments">
            <CommentIcon sx={{ color: '#D3D3D3' }} />
            <Typography sx={{ color: '#D3D3D3', ml: 1 }}>{post.comments?.length || 0}</Typography>
          </IconButton>
        </CardActions>
        {showComments[post._id] && (
          <Box sx={{ px: 2, pb: 2 }}>
            <Divider />
            <List sx={{ maxHeight: 200, overflow: 'auto', paddingTop: 0 }}>
              {(post.comments || []).map((comment, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{ backgroundColor: '#2c2f33', borderRadius: 2, mb: 1, p: 2 }}>
                  <ListItemAvatar>
                    <Avatar alt={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`} src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${comment.user?.profilePicture || ''}`} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`}
                    secondary={comment.text}
                    primaryTypographyProps={{ sx: { color: "white" } }}
                    secondaryTypographyProps={{ sx: { color: "#A9A9A9" } }}
                  />
                  {/* <IconButton onClick={() => handleLikeComment(post._id, comment._id, comment.liked)}>
                    <ThumbUpIcon sx={{ color: comment.liked ? 'blue' : '#D3D3D3' }} />
                  </IconButton> */}
                  <Typography sx={{ ml: 1, color: '#A9A9A9' }}>
                    {comment.likes?.length || 0} likes
                  </Typography>
                  {/* <IconButton onClick={() => setActiveReply(comment)}>
                    <ReplyIcon sx={{ color: '#D3D3D3' }} />
                  </IconButton> */}
                  {comment.replies && comment.replies.length > 0 && (
                    <List sx={{ paddingLeft: 4 }}>
                      {comment.replies.map((reply, replyIndex) => (
                        <ListItem key={replyIndex} alignItems="flex-start" sx={{ backgroundColor: '#3b3f44', borderRadius: 2, mb: 1, p: 1 }}>
                          <ListItemAvatar>
                            <Avatar alt={`${reply.user?.firstName || 'Unknown'} ${reply.user?.lastName || ''}`} src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${reply.user?.profilePicture || ''}`} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${reply.user?.firstName || 'Unknown'} ${reply.user?.lastName || ''}`}
                            secondary={reply.text}
                            primaryTypographyProps={{ sx: { color: "white" } }}
                            secondaryTypographyProps={{ sx: { color: "#A9A9A9" } }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
            {activeReply && (
              <Button variant="outlined" color="secondary" onClick={cancelReply}>
                Cancel Reply
              </Button>
            )}
          </Box>
        )}
      </Card>
      <Box display="flex" alignItems="center" mt={2}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Add a comment"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          InputProps={{
            style: {
              color: 'white', // Text color
              backgroundColor: '#2c2f33', // Background color for the input
            },
          }}
          InputLabelProps={{
            style: {
              color: 'white', // Label color
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Border color
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
            },
          }}
        />
        <Button
          onClick={handleComment}
          variant="contained"
          color="primary"
          sx={{ ml: 2, backgroundColor: 'white', color: '#000', '&:hover': { backgroundColor: '#f0f0f0' } }} // Button styles
        >
          Comment
        </Button>
      </Box>
      <Dialog
        open={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        aria-labelledby="login-dialog-title"
        aria-describedby="login-dialog-description"
      >
        <DialogTitle id="login-dialog-title">Login Required</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-dialog-description">
            You need to log in to access this content.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginRedirect} color="primary" variant="contained">
            Go to Login
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PostDetail;
