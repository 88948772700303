import { Box } from "@mui/material";
import { Swiper } from "swiper/react";
import "swiper/swiper.min.css"; // Ensure Swiper styles are imported

const AutoSwiper = ({ children }) => {
  return (
    <Box
      sx={{
        "& .swiper-slide": {
          width: {
            xs: "50%",
            sm: "35%",
            md: "25%",
            lg: "15%",
          },
        },
      }}
    >
      <Swiper
        slidesPerView="auto"
        grabCursor={true}
        spaceBetween={10} // Adjust the space between slides
        centeredSlides={false}
        // loop={true}
        style={{
          width: "100%",
          height: "max-content",
          margin: 0,
          padding: "0 20px", // Add padding to ensure no cards are cut off
        }}
        breakpoints={{
          // When the screen width is less than 600px
          0: {
            slidesPerView: 3, // Show 3 slides
            spaceBetween: 10,
          },
          // When the screen width is 600px or more
          600: {
            slidesPerView: "auto", // Return to auto for larger screens
            spaceBetween: 10,
          },
        }}
      >
        {children}
      </Swiper>
    </Box>
  );
};

export default AutoSwiper;
