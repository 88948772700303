import React, { useEffect, useState } from "react";
import axios from "axios";
import Videos from "./component/Videos";
import { jwtDecode } from "jwt-decode";
import { useSwipeable } from "react-swipeable";
import { useParams } from "react-router-dom";
import "./SizzlersVid.css";

function SizzlersVid({ selectedMedia }) {
  const { videoId } = useParams();
  const [ytVideo, setYtVideo] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userId, setUserId] = useState(null);
  const [commenterName, setCommenterName] = useState("");

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setUserId(decoded._id);
    }
  }, []);

  useEffect(() => {
    async function fetchCommenterName() {
      try {
        if (userId) {
          const userDetailsResponse = await axios.get(`${apiBaseURL}/api/users/${userId}`, {
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          });
          setCommenterName(userDetailsResponse.data.firstName);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchCommenterName();
  }, [userId]);

  useEffect(() => {
    async function fetchVideos() {
      try {
        const response = await axios.get(`${apiBaseURL}/api/video/posts`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
  
        // Map through response data to include necessary user info
        const videosWithUserInfo = response.data.map((video) => {
          if (video.userId && typeof video.userId === 'object') {
            const {
              _id: userId, // Extract userId
              firstName,
              lastName,
              profilePicture,
              followers,
              following,
            } = video.userId; // Deconstruct the fields from video.userId
  
            return {
              ...video,
              userFirstName: firstName,
              userLastName: lastName,
              userProfilePicture: profilePicture, // Add profile picture
              userFollowers: followers,           // Add followers
              userFollowing: following,           // Add following
              userId, // Assign the deconstructed userId directly
            };
          } else {
            return video; // If no userId object is found, return video as is
          }
        });
  
        setYtVideo(videosWithUserInfo);
        
        if (selectedMedia) {
          const initialIndex = videosWithUserInfo.findIndex(
            (video) => video._id === selectedMedia._id
          );
          setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
        }
      } catch (err) {
        console.log(err);
      }
    }
    
    fetchVideos();
  }, [selectedMedia]);
  
  const handleSubscribe = async (targetUserId) => {
    try {
        const response = await axios.put(`https://thecameratales.com/api/users/${userId}/follow`, 
        { targetUserId },  // Pass targetUserId in the request body
        {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        });
        console.log("Followed:", response.data);
    } catch (err) {
        console.error("Error following:", err);
    }
};

const handleUnsubscribe = async (targetUserId) => {
    try {
        const response = await axios.put(`https://thecameratales.com/api/users/${userId}/unfollow`,
        { targetUserId },  // Pass targetUserId in the request body
        {
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        });
        console.log("Unfollowed:", response.data);
    } catch (err) {
        console.error("Error unfollowing:", err);
    }
};

const handleLike = async (id) => {
  try {
    if (!userId) {
      console.log('User ID is not available.');
      return;
    }
    const response = await axios.patch(`${apiBaseURL}/api/video/${id}/like`, {}, {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    setYtVideo((prevVideos) =>
      prevVideos.map((video) => {
        if (video._id === id) {
          // Merge the original user details with the updated video data
          return {
            ...response.data,
            userId: {
              ...video.userId, // Preserve user details like firstName, lastName, and profilePicture
              ...response.data.userId // Overwrite with any new details if available
            },
            userFirstName: video.userFirstName,
            userLastName: video.userLastName,
            userProfilePicture: video.userProfilePicture,
          };
        }
        return video;
      })
    );
  } catch (err) {
    console.log(err);
    if (err.response && err.response.status === 400) {
      alert("You've already liked this video.");
    } else {
      alert("An error occurred while liking the video.");
    }
  }
};


const handleDislike = async (id) => {
  try {
    const response = await axios.patch(`${apiBaseURL}/api/video/${id}/dislike`, { userId }, {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    setYtVideo((prevVideos) =>
      prevVideos.map((video) => {
        if (video._id === id) {
          // Merge the original user details with the updated video data
          return {
            ...response.data,
            userId: {
              ...video.userId, // Preserve user details like firstName, lastName, and profilePicture
              ...response.data.userId // Overwrite with any new details if available
            },
            userFirstName: video.userFirstName,
            userLastName: video.userLastName,
            userProfilePicture: video.userProfilePicture,
          };
        }
        return video;
      })
    );
  } catch (err) {
    console.log(err);
  }
};

  const handleShare = async (id) => {
    try {
      await axios.patch(`${apiBaseURL}/api/video/${id}/share`, { userId }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      const videoLink = `http://backend.thecameratales.com:3000/video/${id}`;
      if (navigator.share) {
        await navigator.share({
          title: "Check out this video!",
          url: videoLink,
        });
      } else {
        navigator.clipboard.writeText(videoLink);
        alert("Link copied to clipboard!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleComment = async (id, comment) => {
    try {
      if (!userId) {
        console.log('User ID is not available.');
        return;
      }
      const response = await axios.patch(`${apiBaseURL}/api/video/${id}/comment`, { comment, commenterName }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
  
      setYtVideo((prevVideos) =>
        prevVideos.map((video) => {
          if (video._id === id) {
            // Merge original user details with updated video data
            return {
              ...response.data,
              userId: {
                ...video.userId, // Keep the existing user details
                ...response.data.userId // Update with any new user data, if present
              },
              userFirstName: video.userFirstName,
              userLastName: video.userLastName,
              userProfilePicture: video.userProfilePicture,
              comments: response.data.comments.map((comment) => ({
                ...comment,
                commenterId: {
                  ...comment.commenterId, // Retain existing commenter details
                  profilePicture: comment.commenterId?.profilePicture || '', // Add profile picture if it exists
                  firstName: comment.commenterFirstName || commenterName, // Use the stored or fetched name
                  lastName: comment.commenterLastName || '', // Add last name if available
                },
              })),
            };
          }
          return video;
        })
      );
    } catch (err) {
      console.log('Error posting comment:', err); // Log the error
      alert("An error occurred while posting the comment.");
    }
  };
  

  const handleSwipe = (direction) => {
    if (direction === "UP" && currentIndex < ytVideo.length - 1) {
      setCurrentIndex(currentIndex + 1); // Swipe up to go to the next video
    } else if (direction === "DOWN" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); // Swipe down to go to the previous video
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: () => handleSwipe("UP"),
    onSwipedDown: () => handleSwipe("DOWN"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="app" style={{ border: 'none' }}>
      <div className="app__videos" style={{ border: 'none' }} {...handlers}>
        {ytVideo.length > 0 && (
          <Videos
            key={ytVideo[currentIndex]._id}
            id={ytVideo[currentIndex]._id}
            src={`${apiBaseURL}/api/uploads/${ytVideo[currentIndex].videoPath}`}
            channel={ytVideo[currentIndex].channel}
            channelLogo={ytVideo[currentIndex].channelLogo}
            description={ytVideo[currentIndex].description}
            initialLikes={ytVideo[currentIndex].likes.length}
            initialDislikes={ytVideo[currentIndex].dislikes.length}
            initialShares={ytVideo[currentIndex].shares}
            initialComments={ytVideo[currentIndex].comments.map(comment => ({
              commenterName: comment.commenterName || commenterName,
              commentText: comment.commentText
            }))}
            onLike={() => handleLike(ytVideo[currentIndex]._id)}
            onDislike={() => handleDislike(ytVideo[currentIndex]._id)}
            onShare={() => handleShare(ytVideo[currentIndex]._id)}
            onComment={(comment) => handleComment(ytVideo[currentIndex]._id, comment)}
            onSubscribe={() => handleSubscribe(ytVideo[currentIndex].userId)} // Pass userId for subscribing
            onUnsubscribe={() => handleUnsubscribe(ytVideo[currentIndex].userId)} // Pass userId for unsubscribing
            isPlaying={true}
            userFirstName={ytVideo[currentIndex].userFirstName} // Pass user first name
            userLastName={ytVideo[currentIndex].userLastName}   // Pass user last name
            userProfilePicture={ytVideo[currentIndex].userProfilePicture}
            userFollowers={ytVideo[currentIndex].userFollowers}
            userFollowing={ytVideo[currentIndex].userFollowing}
            targetUserId={ytVideo[currentIndex].userId} // Pass the target user ID to the child component
          />
        )}
      </div>
    </div>
  );
}

export default SizzlersVid;
