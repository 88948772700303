import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import themeConfigs from "./configs/theme.configs";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// import MainLayout from "./components/layout/MainLayout";
import routes from "./routes/routes";
import PageWrapper from "./components/common/PageWrapper";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { VideoProvider } from './Context/VideoContext';
import { Suspense, lazy, useEffect } from "react";
// import NotificationListener from "./components/common/NotificationListener";

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Lazy load components
const Chatbot = lazy(() => import("./components/common/Chatbot"));
const MainLayoutLazy = lazy(() => import("./components/layout/MainLayout"));

const App = () => {
  const { themeMode } = useSelector((state) => state.themeMode);

  // This component must be used within the BrowserRouter
  const LayoutWithChatbot = () => {
    const location = useLocation();
    
    // Conditionally render the chatbot if the path matches '/sizzler/:id'
    const showChatbot = !/^\/sizzler\/[a-zA-Z0-9]+$/.test(location.pathname);

    // useEffect(() => {
    //   // Disable right-click globally
    //   const disableRightClick = (e) => {
    //     e.preventDefault();
    //   };

    //   document.addEventListener("contextmenu", disableRightClick);

    //   return () => {
    //     // Cleanup the event listener on unmount
    //     document.removeEventListener("contextmenu", disableRightClick);
    //   };
    // }, []);

    return (
      <>
        <Routes>
          <Route path="/" element={<ThemeProvider theme={themeConfigs.custom({ mode: themeMode })}><Suspense fallback={<div>Loading...</div>}><MainLayoutLazy /></Suspense></ThemeProvider>}>
            {routes.map((route, index) => (
              route.index ? (
                <Route
                  index
                  key={index}
                  element={route.state ? (
                    <PageWrapper state={route.state}><Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense></PageWrapper>
                  ) : <Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>}
                />
              ) : (
                <Route
                  path={route.path}
                  key={index}
                  element={route.state ? (
                    <PageWrapper state={route.state}><Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense></PageWrapper>
                  ) : <Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>}
                />
              )
            ))}
          </Route>
        </Routes>
        {/* Conditionally render the chatbot */}
        {showChatbot && <Suspense fallback={<div>Loading...</div>}><Chatbot /></Suspense>}
      </>
    );
  };

  return (
    <>
      {/* config toastify */}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme={themeMode}
      />
      {/* mui reset css */}
      <CssBaseline />

      {/* app routes */}
      <BrowserRouter>
        <VideoProvider>
          <LayoutWithChatbot />
        </VideoProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
