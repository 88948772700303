const mediaType = {
  movie: "movie",
  tv: "tv",
  webseries: "webseries",
  webshows: "webshows",
  shows: "shows",
  musicvideos: "musicvideos",
  toptalesthisweek: "toptalesthisweek",
  shortfilms: "shortfilms",
  tales: "tales",
  upcoming: "upcoming",
  fridaytalkies: "firdaytalkies",
  favorites:"favorites",
  viewed:"viewed",
};

const mediaCategory = {
  popular: "popular",
  top_rated: "top_rated"
};

const backdropPath = (imgEndpoint) => `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${imgEndpoint}`;

const posterPath = (imgEndpoint) => {
  // Check if imgEndpoint is defined and is a string
  if (typeof imgEndpoint === 'string') {
    // Check if the imgEndpoint starts with the specified URL
    if (imgEndpoint.startsWith('https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/')) {
      return imgEndpoint;
    }
    // Otherwise, prepend the base URL to imgEndpoint
    return `https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${imgEndpoint}`;
  }
  
  // Return a default image or handle the error case
  return 'https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/default-poster.jpg'; // Change this to your default image path
};


const youtubePath = (videoId) => `https://www.youtube.com/embed/${videoId}?controls=0`;

const tmdbConfigs = {
  mediaType,
  mediaCategory,
  backdropPath,
  posterPath,
  youtubePath
};

export default tmdbConfigs;
