import axios from 'axios';

const getMediaTypeAndIdFromUrl = () => {
  const pathArray = window.location.pathname.split('/');
  return {
    mediaType: pathArray[1], // e.g., 'movie', 'webseries'
    mediaId: pathArray[2]     // e.g., the media ID
  };
};

const getUserIdFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.id || null;
};

const addFavorite = async () => {
  const userId = getUserIdFromLocalStorage();
  const { mediaType, mediaId } = getMediaTypeAndIdFromUrl();

  if (!userId || !mediaType || !mediaId) {
    return { response: null, err: "Missing required parameters" };
  }

  try {
    const response = await axios.post(`https://thecameratales.com/api/${mediaType}/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const removeFavorite = async () => {
  const userId = getUserIdFromLocalStorage();
  const { mediaType, mediaId } = getMediaTypeAndIdFromUrl();

  if (!userId || !mediaType || !mediaId) {
    return { response: null, err: "Missing required parameters" };
  }

  try {
    const response = await axios.delete(`https://thecameratales.com/api/${mediaType}/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const getFavoriteStatus = async () => {
  const userId = getUserIdFromLocalStorage();
  const { mediaType, mediaId } = getMediaTypeAndIdFromUrl();

  if (!userId || !mediaType || !mediaId) {
    return { response: null, err: "Missing required parameters" };
  }

  try {
    const response = await axios.get(`https://thecameratales.com/api/${mediaType}/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const getAllFavorites = async () => {
  const userId = getUserIdFromLocalStorage();
  const { mediaType } = getMediaTypeAndIdFromUrl();

  if (!userId || !mediaType) {
    return { response: null, err: "Missing required parameters" };
  }

  try {
    const response = await axios.get(`https://thecameratales.com/api/users/${userId}/favorites`, {
      params: { mediaType }
    });
    console.log(response);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

export { addFavorite, removeFavorite, getFavoriteStatus, getAllFavorites };
