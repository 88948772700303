import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CmsSizzlers.module.css';
import CmsNavbar from './CmsNavbar';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const CmsSizzlers = () => {
  const [sizzlers, setSizzlers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSizzlerComments, setSelectedSizzlerComments] = useState([]);

  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user ? user.id : null;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || !userId) {
      return;
    }
    
    const fetchUserSizzlers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://thecameratales.com/api/video/posts/user/${userId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setSizzlers(response.data);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate('/login');
        }
        setError(error.response?.data?.message || 'Failed to load user-specific posts');
      } finally {
        setLoading(false);
      }
    };

    fetchUserSizzlers();
  }, [token, userId, navigate]);

  // Redirect to login if no token or user ID is present
  if (!token || !userId) {
    return (
      <div className={styles.blankPage}>
        {!user ? ( // Render the button only if the user is not logged in
          <button 
            onClick={() => navigate('/login')} 
            className={styles.loginButton}
          >
            Login
          </button>
        ) : (
          <p className={styles.welcomeMessage}>Welcome back, {user.firstName}!</p>
        )}
      </div>
    );
  }

  // if (loading) {
  //   return <p className={styles.loadingMsg}>Loading sizzlers...</p>;
  // }

  // if (error) {
  //   return (
  //     <div className={styles.errorContainer}>
  //       <p className={styles.errorMsg}>{error}</p>
  //     </div>
  //   );
  // }

  const filteredSizzlers = sizzlers.filter(sizzler => {
    switch (filter) {
      case 'approved': return sizzler.approved === true;
      case 'rejected': return sizzler.rejected === true;
      case 'pending': return sizzler.pending === true;
      default: return true; // 'all' filter
    }
  });

  const handleShowMoreComments = (comments) => {
    setSelectedSizzlerComments(comments);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSizzlerComments([]);
  };

  return (
    <>
    <CmsNavbar />
       {loading ? (
        <p className={styles.loadingMsg}>Loading sizzlers...</p>
      ) : error ? (
        <div className={styles.errorContainer}>
          <p className={styles.errorMsg}>{error}</p>
        </div>
      ) : (
      <div className={styles.sizzlersContainer}>
        <div className={styles.sidebar}>
          <h2>Sizzlers-Posts</h2>
          <h3>Filters</h3>
          <button className={styles.filterButton} onClick={() => setFilter('all')}>All</button>
          <button className={styles.filterButton} onClick={() => setFilter('approved')}>Approved</button>
          <button className={styles.filterButton} onClick={() => setFilter('pending')}>Pending</button>
          <button className={styles.filterButton} onClick={() => setFilter('rejected')}>Rejected</button>
        </div>

        <div className={styles.sizzlersGrid} style={{marginTop:'7.5vh'}}>
          {filteredSizzlers.length === 0 ? (
            <p className={styles.noSizzlersMsg}>No sizzlers found.</p>
          ) : (
            filteredSizzlers.map((sizzler) => (
              <div key={sizzler._id} className={styles.sizzlerCard}>
                <h3 className={styles.sizzlerChannel}>{sizzler.channel}</h3>
                {sizzler.channelLogo && (
                  <img
                    src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${sizzler.channelLogo}`}
                    alt="Channel Logo"
                    className={styles.channelLogo}
                  />
                )}
                {sizzler.videoPath && (
                  <video controls className={styles.sizzlerVideo}>
                    <source src={`https://ottplatformthecameratales.s3.ap-south-1.amazonaws.com/${sizzler.videoPath}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <p className={styles.sizzlerDescription}>{sizzler.description}</p>
                <p className={styles.sizzlerInfo}>
                  Likes: <span>{sizzler.likes.length}</span> | Dislikes: <span>{sizzler.dislikes.length}</span> | Shares: <span>{sizzler.shares}</span>
                </p>
                <p className={styles.sizzlerInfo}>
                  Posted by: {sizzler.userId?.firstName || 'Unknown'} {sizzler.userId?.lastName || 'User'}
                </p>
                <p className={styles.sizzlerStatus}>
                  Status: {sizzler.approved ? 'Approved' : sizzler.rejected ? 'Rejected' : 'Pending'}
                </p>
                <h4 className={styles.commentsHeading}>Comments:</h4>
                {sizzler.comments.length > 0 ? (
                  <ul className={styles.commentsList}>
                    {sizzler.comments.slice(0, 2).map((comment, idx) => (
                      <li key={idx} className={styles.commentItem}>
                        <p className={styles.commentText}>{comment.commentText}</p>
                        <p className={styles.commentAuthor}>
                          By: {comment.commenterFirstName || 'Unknown'} {comment.commenterLastName || 'User'}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noCommentsMsg}>No comments yet.</p>
                )}
                {sizzler.comments.length > 2 && (
                  <p className={styles.showMore} onClick={() => handleShowMoreComments(sizzler.comments)}>
                    Show more comments
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      )}

      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={closeModal} 
        contentLabel="All Comments"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <h2>All Comments</h2>
        <button onClick={closeModal} className={styles.closeModalBtn}>Close</button>
        <ul className={styles.modalCommentsList}>
          {selectedSizzlerComments.map((comment, idx) => (
            <li key={idx} className={styles.modalCommentItem}>
              <p className={styles.modalCommentText}>{comment.commentText}</p>
              <p className={styles.modalCommentAuthor}>
                By: {comment.commenterFirstName || 'Unknown'} {comment.commenterLastName || 'User'}
              </p>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default CmsSizzlers;
